import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { messageBoxForError, sendMessengerData } from "../service";
import { LIST_CODE_CELL } from "../../globals";

const { Option } = Select;
export const useWhatsapp = ({
	refInputPhone,
	menssengerTxt,
	activate,
	onchange,
}) => {
	const [visible, setVisible] = useState(activate);
	const [numberPhoneForSend, setNumberPhoneForSend] = useState("");
	const [validNumberPhone, setValidNumberPhone] = useState(true);
	const closeModal = () => {
		onchange(false);
		setVisible(false);
	};

	useEffect(() => {
		setVisible(activate);
        setValidNumberPhone(true);
	}, [activate]);

	const selectBefore = (
		<Select
			className={`ws-select ${
				validNumberPhone ? "ws-border-allow-before" : "ws-border-deny-before"
			}`}
			defaultValue="Venezuela|58"
			style={{ width: 90 }}
		>
			<Option value="Venezuela|58">
				<span role="img" aria-label="ve">
					🇻🇪
				</span>
			</Option>
			<Option value="Colombia|57">
				<span role="img" aria-label="co">
					🇨🇴
				</span>
			</Option>
			<Option value="Peru|51">
				<span role="img" aria-label="pe">
					🇵🇪
				</span>
			</Option>
			<Option value="EEUU|1">
				<span role="img" aria-label="eua">
					🇺🇸
				</span>
			</Option>
			<Option value="España|34">
				<span role="img" aria-label="es">
					🇪🇸
				</span>
			</Option>
		</Select>
	);

	const onSend = async () => {
		if (!validNumberPhone) {
			messageBoxForError({
				title: "Numerico Telefonico",
				content: "Este numero telefonico es errado! ",
				refInputPhone,
			});
			return false;
		}

		const sendResponseMsg = await sendMessengerData({
			numberPhoneForSend,
			menssengerTxt,
			refInputPhone,
		});
		if (sendResponseMsg) {
			closeModal();
			return true;
		}
	};

	const setNumberPhone = ({ target: { value } }) => {
		const patterPhone = /^\d{10}$/;
		const listCell = LIST_CODE_CELL;
		if (!patterPhone.test(value)) {
			setValidNumberPhone(false);
			return false;
		}
		const code = value.substr(0, 3);
		if (!listCell.some((o) => o === code)) {
			setValidNumberPhone(false);
			return false;
		}
		setValidNumberPhone(true);
		setNumberPhoneForSend(value);
		return true;
	};

	return {
		onSend,
		setNumberPhone,
		closeModal,
        listaAuthCode:LIST_CODE_CELL.join(' , '),
		validNumberPhone,
		selectBefore,
		visible,
	};
};
