import client from './conex'
import {gql} from '@apollo/client'
import message from 'antd/es/message';
// import global from './globals.js';
var _ = require('lodash');
// var nJwt = require('njwt');
// var bcrypt = require('bcryptjs');
// var ip = require('ip');
// const encode = require('nodejs-base64-encode');

const queryviewDataReport= gql`
query viewDataReport($desde:String,$hasta:String,$iu:Int){
    viewDataReport(desde:$desde,hasta:$hasta,iu:$iu) {
        Deportes {
            fecha
            IDJ
            suma
            procentaje
            premios
            parti
        }
        Americanas {
            fecha
            suma
            procentaje
            premios
            parti
        }
        Nacionales {
            fecha
            suma
            procentaje
            premios
            parti
        }
    }
}
`;

const queryviewDataReporTk= gql`
query viewDataReporTk($desde:String,$hasta:String,$iu:Int,$Tipo:Int){
    viewDataReporTk(desde:$desde,hasta:$hasta,iu:$iu,Tipo:$Tipo) {
        Deportes {
            fecha
            serial
            monto
            premio
        } 
        Americanas {
            fecha
            serial
            monto
            premio
        }
        Nacionales {
            fecha
            serial
            monto
            premio
        } 
    }
}
`;
export async function queryviewDataReporTkFn(tthis,desde,hasta,iu,tipo){
    message.loading('Espere un momento, por favor..', 100000)
    await client.query({
            query: queryviewDataReporTk,
            variables:{ desde:desde,hasta:hasta,iu:iu,Tipo:tipo },
    })
    .then(data =>{
        message.destroy()
        var resp=data.data.viewDataReporTk;
        if (!_.isNull(resp)){
            tthis.SetActuazz1(resp.Deportes,resp.Americanas,resp.Nacionales)
        }
    })
} 
export async function queryviewDataReportFn(tthis,desde,hasta,iu,tipo){
    message.loading('Espere un momento, por favor..', 100000)
    await client.query({
            query: queryviewDataReport,
            variables:{ desde:desde,hasta:hasta,iu:iu },
    })
    .then(data =>{
        message.destroy()
        var resp=data.data.viewDataReport;
        if (!_.isNull(resp)){
            var SummDeport=[],SummAmeri=[],SummNacio=[];
            if (resp.Deportes.length!==0){
                let Deportes=resp.Deportes;
                let ventas=0;
                let porcentaje=0;
                let premios=0;
                let participacion=0;
                if (tipo===1){
                    for (let rows in Deportes) {
                        SummDeport.push({
                            fecha:Deportes[rows].fecha,
                            ventas:Deportes[rows].suma,
                            porcentaje:Deportes[rows].procentaje,
                            premios:Deportes[rows].premios,
                            participacion:Deportes[rows].parti
                        })
                    }
                }
                if (tipo===2){
                    for (let rows in Deportes) {
                        ventas+=Deportes[rows].suma;
                        porcentaje+=Deportes[rows].procentaje;
                        premios+=Deportes[rows].premios;
                        participacion+=Deportes[rows].parti;
                    }
                    SummDeport=[{
                        ventas:ventas,
                        porcentaje:porcentaje,
                        premios:premios,
                        participacion:participacion
                    }]
                }
            }
            if (resp.Americanas.length!==0){
                let Americanas=resp.Americanas;
                let ventas=0;
                let porcentaje=0;
                let premios=0;
                let participacion=0;
                if (tipo===1){
                    for (let rows in Americanas) {
                        SummAmeri.push({
                            fecha:Americanas[rows].fecha,
                            ventas:Americanas[rows].suma,
                            porcentaje:Americanas[rows].procentaje,
                            premios:Americanas[rows].premios,
                            participacion:Americanas[rows].parti
                        })
                    }
                }
                if (tipo===2){
                    for (let rows in Americanas) {
                        ventas+=Americanas[rows].suma;
                        porcentaje+=Americanas[rows].procentaje;
                        premios+=Americanas[rows].premios;
                        participacion+=Americanas[rows].parti;
                    }
                    SummAmeri=[{
                        ventas:ventas,
                        porcentaje:porcentaje,
                        premios:premios,
                        participacion:participacion
                    }]
                }
            }
            if (resp.Nacionales.length!==0){
                let Nacionales=resp.Nacionales;
                let ventas=0;
                let porcentaje=0;
                let premios=0;
                let participacion=0;
                if (tipo===1){
                    for (let rows in Nacionales) {
                        SummNacio.push({
                            fecha:Nacionales[rows].fecha,
                            ventas:Nacionales[rows].suma,
                            porcentaje:Nacionales[rows].procentaje,
                            premios:Nacionales[rows].premios,
                            participacion:Nacionales[rows].parti
                        })
                    }
                }
                if (tipo===2){
                    for (let rows in Nacionales) {
                        ventas+=Nacionales[rows].suma;
                        porcentaje+=Nacionales[rows].procentaje;
                        premios+=Nacionales[rows].premios;
                        participacion+=Nacionales[rows].parti;
                    }
                    SummNacio=[{
                        ventas:ventas,
                        porcentaje:porcentaje,
                        premios:premios,
                        participacion:participacion
                    }]
                }
            }
            tthis.SetActuazz(SummDeport,SummAmeri,SummNacio)
        }
    })
} 