import React, { Component } from 'react';

import Form from 'antd/es/form'
import Input from 'antd/es/input'
import Tabs from 'antd/es/tabs';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import message from 'antd/es/message';
import Avatar from 'antd/es/avatar';
import Spin from 'antd/es/spin';

import moment from 'moment';
import axios from 'axios';
import * as func from './func';
import './cloudopciones.css';
const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;
var _ = require('lodash');
const encode = require('nodejs-base64-encode');




class FromMisDatos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageUrl:false,
            loading:false,
            loadFile:false,
            file:'',
            realAvatar:'0',
            urlAvatar:'0',
            imageHash:Date.now(),
            disablAtv:false,
            bancos:[]
            };
        
            this.queryviewBancosFn=func.queryviewBancosFn.bind(this);
        }

    handleWebsiteChange = () => {
        this.props.form.validateFields(
            (err,valores) => {
            if (!err) {
                this.props.saveMisDatos(valores,this.state.realAvatar); 
            }
            },
        );
    }
    handleChange = (info) => {
        this.setState({loadFile:true})
        var iuTxt=_.toString(this.props.state.seleccion.iu)
        var file=info[0]
        const fd = new FormData();
        var url='https://us-central1-data-avatar-d75e7.cloudfunctions.net/uploadFile';
        fd.append('image',file);
        fd.append('iu',iuTxt);

        // headers: {'X-Requested-With': 'XMLHttpRequest'},
        // onUploadProgress: progressEvent => {
        //   let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        //   console.log('percentCompleted',percentCompleted)
        //   // do whatever you like with the percentage complete
        //   // maybe dispatch an action that will update a progress bar or something
        // }
        let config = {
            method: 'post',
            url,
            data:fd,
            
          }
        axios(
            config
            ).then((res)=>{
            console.log(res)
            if (res.status===200){
                message.success('Avatar Actualizado, Gracias!');
                var x_ext=_.split(file.name,'.');
                var ext=x_ext[x_ext.length-1];
                var  urlAvatar="https://firebasestorage.googleapis.com/v0/b/data-avatar-d75e7.appspot.com/o/"+iuTxt+'.'+ext+"?alt=media";
                var realAvatar=iuTxt+'.'+ext;
                this.setState({realAvatar,urlAvatar,imageHash:Date.now(),loadFile:false})
            }
        }).catch((err)=>{
            if (err) message.error('Disculpe hubo un error en actualizar su avatar!');
            this.setState({loadFile:false})
        })
        
        
        
    }
    setActualizar=()=>{
        var {avatar}=this.props.state.seleccion;
        var {urlAvatar,realAvatar}=this.state;
        if (!_.isUndefined(avatar) && avatar!=='' &&  avatar!=='0') {
            if (avatar.search("https")===-1)
                urlAvatar="https://firebasestorage.googleapis.com/v0/b/data-avatar-d75e7.appspot.com/o/"+avatar+"?alt=media"
            else
                urlAvatar=avatar;

            realAvatar=avatar;
        }

        this.setState({urlAvatar,realAvatar,imageHash:Date.now()});
      
    }
    setDatalist=(bancos)=>{
        this.setState({bancos})
    }
    componentDidMount=()=>{
        this.setActualizar();
        this.queryviewBancosFn();
    }
    componentWillReceiveProps=()=>{
        this.setActualizar();
    }

render() {
    var {loadFile}=this.state;
    const { getFieldDecorator} = this.props.form;
    const recordSelect=this.props.state.seleccion;
    var fecha= moment.unix(_.isUndefined(recordSelect.fn)?moment().unix():recordSelect.fn).format('YYYY-MM-DD')

    return (
        <Form className="FromCloudOpciones" >
            <Row>
                <Col span={10}>
                        <FormItem >
                            {loadFile?
                            <Spin/>
                            :
                            <Avatar size={64} icon="user" src={`${this.state.urlAvatar}&${this.state.imageHash}`} />
                            }
                        </FormItem>
                        {loadFile?"":
                        <Form.Item >
                                <input type="file" name="file" onChange={(e)=>this.handleChange(e.target.files)} />
                        </Form.Item>}
                </Col>
            </Row>
            <Row>
                <Col span={8} >
                    <FormItem label="Nombre:">
                        {getFieldDecorator('nombre', {initialValue: recordSelect.Nombre,
                        rules: [{
                            type: 'string', message: 'Debe tener algun nombre, si es valido mejor!',
                        }, {
                            required: true, message: 'Por favor requiero algun Nombre!',
                        }],
                        })(
                        <Input  />
                        )}
                    </FormItem>
                </Col>
                <Col span={8} offset={1}>
                    <FormItem label="Clave">
                        {getFieldDecorator('password', {initialValue:recordSelect.pw,
                            rules: [{
                            required: true, message: 'Por favor coloque su clave!',
                            }, {
                            validator: this.validateToNextPassword,
                            }],
                        })(
                            <Input type="password" />
                        )}
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <FormItem label="Correo/email">
                        {getFieldDecorator('email', {initialValue: recordSelect.email,
                            rules: [{
                            type: 'email', message: 'Este correo no es valido!',
                            }, {
                            required: true, message: 'Por Favor el correo es requerido!',
                            }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                </Col>
                <Col span={8} offset={1}>
                    <FormItem label="Identifiacion (DIN/CI/PASSAPORTE)">
                        {getFieldDecorator('identificacion', {
                                initialValue: recordSelect.id,
                        rules: [{
                            type: 'string', message: 'Introduce una identifacion valida!',
                        }],
                        })(
                        <Input />
                        )}
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <FormItem label="Fecha de Nacimiento:">
                    {getFieldDecorator('fn',  { initialValue: moment(fecha, 'YYYY-MM-DD'),
                    rules:[{ type: 'object', required: true, message: 'Por Favor coloque su fecha de Nacimiento!' }],})(
                        <DatePicker    format='DD/MM/YYYY'  />
                    )}
                    </FormItem>
                </Col>
                <Col span={8} offset={1}>
                    <FormItem label="Numero Telefonico:">
                    {getFieldDecorator('ntlf', {
                        initialValue: recordSelect.ntlf,
                        rules: [{
                            type: 'string', message: 'Introduce un Numero Telefonico!',
                        }],
                        })(
                        <Input   />
                        )}
                
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <FormItem label="Cuenta Bancaria">
                        {getFieldDecorator('cb', {
                                initialValue: recordSelect.cb,
                        rules: [{
                            type: 'string', message: 'Introduce una cuenta bancaria Valida!',
                        }],
                        })(
                        <Input />
                        )}
                    </FormItem>
                </Col>
                <Col span={8} offset={1}>
                    <FormItem label="Indique el Banco de la Cuenta:">
                        {getFieldDecorator('banco', {
                            initialValue: recordSelect.banco,
                        rules: [
                            { required: true, message: 'Por favor seleccione el banco !' },
                            ],
                        })(
                            <Select placeholder="Seleccione el Banco">
                                <Option value={0}></Option>
                                {this.state.bancos.map((value,index)=>
                                    <Option key={index.toString()} value={value.key}>{value.Text}</Option>
                                )}
                            </Select>
                        )}
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <FormItem label="Tipo de Cuenta:">
                        {getFieldDecorator('tc', {
                            initialValue: recordSelect.tc,
                        rules: [
                            { required: true, message: 'Por favor seleccione su tipo de cuenta!' },
                            ],
                        })(
                        <Select  placeholder="Tipo de Cuenta">
                            <Option value={1}>Corriente</Option>
                            <Option value={2}>Ahorros</Option>
                        </Select> 
                        )}
                    </FormItem>
                </Col>
                <Col span={6} offset={1}>
                    <FormItem label="Moneda">
                    {getFieldDecorator('moneda', {
                        initialValue: recordSelect.Moneda,
                        rules: [
                            { required: true, message: 'Por favor seleccione la moneda a utilizar!' },
                            ],
                        })(
                            <Select placeholder="Moneda">
                                <Option value="BSS">Bolivares</Option>
                                <Option value="$">Dolar</Option>
                                <Option value="PES">Pesos</Option>
                            </Select>
                        )}
                    </FormItem>
                </Col>
                <Col span={6} offset={1}>
                    <FormItem label="Idioma">
                        {getFieldDecorator('idioma', {
                            initialValue: _.isNaN(recordSelect.idioma)?"0":recordSelect.idioma,
                        rules: [
                            { required: true, message: 'Por favor seleccione el idioma utilizar!' },
                            ],
                        })(
                            <Select placeholder="idioma">
                                <Option value="1">Español</Option>
                                <Option value="2">Ingles</Option>
                            </Select>
                        )}
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={20}>
                    <Col span={10}>
                        <FormItem >
                            <Button type="primary" onClick={(e)=>this.handleWebsiteChange()}>Grabar Cambios</Button>
                        </FormItem>
                    </Col>
            </Row>
        </Form>
        
        )
    }
}
const EnhancedFromMisDatos = Form.create()(FromMisDatos);



class OpcionesIU extends Component {

    constructor(props) {
        super(props);     
        this.state = {
            seleccion:[],
            pasww:'******',
            datahistoria:[],
            saldo:0,
            moneda:'Bss',
        }
        this.viewListIUonly =func.viewListIUonly.bind(this);
        this.queryHistoryCuentasFn=func.queryHistoryCuentasFn.bind(this);
        this.grabarIUlitte=func.grabarIUlitte.bind(this);
    }
    setDataIU=(seleccion)=>{
        try{
        seleccion.pw=JSON.parse(encode.decode(seleccion.pw, 'base64'));
        }catch(err){
            
        }
        this.setState({seleccion});
    }
    setDatalist2=(datahistoria)=>{
        this.setState({ datahistoria}); 
    }
    actualizacionesMain=()=>{
        this.viewListIUonly(this.props.tthis.state.id)
        this.queryHistoryCuentasFn('0',this.props.tthis.state.id,0)
        this.props.tthis.changePross()
    }
    setRefress=()=>{
        this.actualizacionesMain();
        this.setState({
            saldo:this.props.tthis.state.saldo,
            moneda:this.props.tthis.state.moneda,
                });
    }
    componentDidMount=()=>{
        this.setRefress();
    }
    componentWillReceiveProps=()=>{
        this.setRefress();
    }
    actualizarMain=()=>{
        this.props.tthis.changePross();
        
    }

    saveMisDatos=(registro,avatar)=>{
        var realAvatar=''
        if (!_.isUndefined(avatar) && avatar!=='0' ){
            realAvatar=avatar
            this.props.tthis.StateAvatar(realAvatar);
        }
        

        var recordnew={ 
            iu: this.props.tthis.state.id,
            Nombre:registro.nombre,
            email:registro.email,
            identif:registro.identificacion,
            Moneda:registro.moneda,
            idioma:_.toString(registro.idioma),
            pw:encode.encode(JSON.stringify(registro.password), 'base64'),
            avatar:realAvatar,
            fn:_.toString(moment(registro.fn).unix()),
            tc:registro.tc,
            banco:registro.banco,
            cb:registro.cb,
            ntlf:registro.ntlf
            }             
    
            console.log(recordnew);
        this.grabarIUlitte(recordnew)

    }
    setClaveNueva=(pasww)=>{
        this.setState({ pasww });
    }

render(){
        return(
            <Row key="Table-02" >   
                <br/>
                <Col >
                <Tabs  type="card">
                    <TabPane tab="Mi Datos" key="1"><EnhancedFromMisDatos {...this} iu={this.props.tthis.state.id}/></TabPane>
                 </Tabs>
                 </Col>
            </Row>     
        )
}
}

export default OpcionesIU;

