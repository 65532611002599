import { createStore} from 'redux';



const defaultVar={
    blockesc:true,
    dias:[],
    series:[],
    delta1:[],
    activoView:{},
    apuesta:0,
    deportes:[],
    openSubItm:[],
    listLottery:[],
    addVal:[],
    animalitos:[],
    mount_inc:true,
    IncMen:['0'],
    iwebData:'',
    propsSecc1:'',
    propsSecc2:'',
    propsSecc3:'',
    propsSecc4:'',
    propsSecc5:'',
    numLott:'',
    ppBoxIm:0,
    vsbprt:false,
    colum:[],
    data:[],
    iddeportes:[],
    smenu:[],
    markPolla:[],
    IDCN_Nac:0,
    dia_nac:'hoy',
    displayBanner:true,
    saldo:0
    }

function reducer(state=defaultVar,action){
    switch (action.type) {
        case 'StateSaldo':
            const {saldo}=action
            return {
                ...state,
                saldo
            }
        case 'IN_data_displayBanner':
            return{
                ...state,
                displayBanner:action.displayBanner
            } 
        case 'IN_data_IDCN_Nac':
            return{
                ...state,
                IDCN_Nac:action.IDCN_Nac
            } 
        case 'IN_data_markPolla':
            return{
                ...state,
                markPolla:action.markPolla
            } 
        case 'IN_data_smenu':
        return{
            ...state,
            smenu:action.smenu
        } 
        case 'IN_data_iddeportes':
        return{
            ...state,
            iddeportes:action.iddeportes
        } 
        case 'IN_data_columnas':
        return{
            ...state,
            colum:action.colum
        } 
        case 'IN_data_data':
        return{
            ...state,
            data:action.data
        } 

        case 'IN_data_List_addVal':
        return{
            ...state,
            addVal:action.addVal
        } 
        case 'IN_data_List_animalitos':
        return{
            ...state,
            animalitos:action.animalitos
        } 
        case 'IN_data_List_Loteria':
        return{
            ...state,
            listLottery:action.listLottery
        } 
        case 'ACTV_PRN':
        return{
            ...state,
            vsbprt:action.vsbprt
        } 
        case 'VALUE_NUM_LOTT':
        return{
            ...state,
            numLott:action.payload
        } 
        case 'IN_DATA_TK_GRAPH_DIA':
        return{
            ...state,
            dias:action.dias
        } 
        case 'IN_DATA_TK_GRAPH':
        return{
            ...state,
            series:action.series
        } 
        case 'IN_DATA_TK_DEP':
        return{
            ...state,
            delta1:action.delta1
        } 
       
        case 'IN_DATA_ACTIVOVIEW_AME':
            return{
                ...state,
                activoView:action.activoView
            } 
        case 'IN_DATA_APUESTA':
            return{
                ...state,
                apuesta:action.apuesta
            } 
        case 'IN_DATA_DEP_1':
            return{
                ...state,
                openSubItm:action.openSubItm
            } 
        case 'IN_DATA_DEP_2':
            return{
                ...state,
                deportes:action.deportes
            } 
        case 'BLOCK':
            return{
                ...state,
                mount_inc:false
            } 
        case 'UNBLOCK':
            return{
                ...state,
                mount_inc:true
            } 
        case 'INIMENU':
            return{
                ...state,
                IncMen:['0']
            } 
        case 'IN_DATA_WEB':
            return{
                ...state,
                iwebData:action.iwebData
            } 
        case 'IN_DATA_WEB1':
            return{
                ...state,
                propsSecc1:action.propsSecc1
            } 
        case 'IN_DATA_WEB2':
            return{
                ...state,
                propsSecc2:action.propsSecc2
            }
        case 'IN_DATA_WEB3':
            return{
                ...state,
                propsSecc3:action.propsSecc3
            } 
        case 'IN_DATA_WEB4':
            return{
                ...state,
                propsSecc4:action.propsSecc4
            } 
        case 'IN_DATA_WEB5':
            return{
                ...state,
                propsSecc5:action.propsSecc5
            } 
        case 'IN_BLOCK_IMP':
            return{
                ...state,
                blockesc:action.blockesc
            } 
        case 'IN_ppBoxIm':
            return{
                ...state,
                ppBoxIm:action.ppBoxIm
            }
        default:
            return state;
        }
}


//const firestoreConnect2 = createFirestoreConnect(firebase)
export default createStore(reducer,defaultVar)
//     createStore(reducer,defaultVar),
//     firestoreConnect(['todos']), // sync todos collection from Firestore into redux
//     connect((state) => ({
//       todosList: state.firestore.data.todos,
//       profile: state.firestore.profile, // pass profile data as props.profile
//       auth: state.firestore.auth // pass auth data as props.auth
//     })
//   )(SomeComponent)


//   import { createFirestoreConnect } from 'react-redux-firebase'
// // create firebase connect that uses another redux store
// const firestoreConnect = createFirestoreConnect('anotherStore')
// // use the firebaseConnect to wrap a component
// export default firestoreConnect()(SomeComponent)