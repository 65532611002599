// Your web app's Firebase configuration
 export const firebaseConfig = {
    apiKey: "AIzaSyB7YcIWZ-CtPeygEKt9vbhK5k73lxPDm8s",
    authDomain: "mrjugada.firebaseapp.com",
    databaseURL: "https://mrjugada.firebaseio.com",
    projectId: "mrjugada",
    storageBucket: "mrjugada.appspot.com",
    messagingSenderId: "917547840093",
    appId: "1:917547840093:web:31086f71f5657dfb3793b4"
  };

