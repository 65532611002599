import React, { Component } from 'react';
import Table from 'antd/es/table';
import Icon from 'antd/es/icon';
import Tabs from 'antd/es/tabs';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import Tag from 'antd/es/tag';
import notification from 'antd/es/notification';
import Popconfirm from 'antd/es/popconfirm';
// import Modal from 'antd/es/modal';
import Spin from 'antd/es/spin';
import FormattedNumber from './component/FormatNumber';
import LoadingBar from 'react-top-loading-bar'
import moment from 'moment';
import * as func from './func';
import {line1} from './genViewTk';
import './sbonline.css';

var _ = require('lodash');
var Barcode = require('react-barcode');

const styleBg=['#108ee9','#590101','#0f5901','#9b9842','#8c429b'];
const { Column } = Table;
const TabPane = Tabs.TabPane;


class Ticketcavas extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            value:[],
            detalle:[]
        }
        this.line=line1.bind(this);
        this.queryviewOneTicketFn=func.queryviewOneTicketFn.bind(this);
       
        }

    setDatalistTk=(value,detalle)=>{
        if (_.isNull(detalle)) detalle=[];
        this.setState({value,detalle})
    }
    
    componentWillMount=()=>{
        this.queryviewOneTicketFn(this.props.serial,this.props.isys)
    }
    render(){
        var op=this.props.isys;

        var {value}=this.state;
        return(
        value.length!==0 &&
            <div  >
                {this.line(op,value,1,this.props.currency ) }
                <Barcode value={!_.isUndefined(value.se)&&value.se} width={1} height={20} margin={0} />
            </div>
        )
    }
}

class MisTickets extends Component {
constructor(props) {
    super(props);        
    this.state = {
        data1 :[],
        data2 :[],
        data3 :[],
        data4 :[],
        pasadoData:[],
        fecha: '',
        tm:null,
        loading:false,
        viewValoresAnul:[]
    }
    this.querydatafechaFn=func.querydatafechaFn.bind(this);
    this.queryviewTicketsFn=func.queryviewTicketsFn.bind(this);
    this.queryviewValoresAnulFN=func.queryviewValoresAnulFN.bind(this);
    this.mutationanulOneTicketFn=func.mutationanulOneTicketFn.bind(this);
    }

setDatalist=(data,tipo)=>{
    this.setState({
        data1:data.sinprocesar,
        data2:data.posibleganar,
        data3:data.posibleganador,
        data4:data.anulados,
    
    });
    this.renderTable(tipo)
}
Changedate=(date)=>{
    this.setState({loading:true})
    this.setState({fecha:date.format("DD/M/YYYY")})
    this.callRefress(date.format("DD/M/YYYY"))
}
renderTable=(op)=>{
    const {data1,data2,data3,data4}=this.state
    const haldlenViewData = [
        data1,
        data2,
        data3,
        data4
    ]
    var viewData=haldlenViewData[op-1];
   
    return (
    <Table 
    rowKey={record => record.serial} 
    dataSource={viewData}
    className={".ant-table-content"}
    onRow={(record) => {
        var serial=record.serial
        var isys=record.isys
        return {
            onClick: () => {
                this.openNotification(serial,op,isys)
                },    
        };}}>
        <Column
            key="serial"
            title="Serial"
            dataIndex="serial"
        />
        <Column
            title=""
            dataIndex="isys_Desc"
            width={10}
            render={(isys_Desc,record) => (
                <span>
                    {<Tag color={styleBg[(record.isys-1)]}  >{isys_Desc}</Tag>}
                </span>
            )}
        />
        <Column
            title="Fecha"
            dataIndex="fecha"
        />
        <Column
            title="Hora"
            dataIndex="hora"
        />
        <Column
            title="Usted Pago"
            dataIndex="apuesta"
            className='column-monto'
            render={(apuesta) => (
                <span>
                  
                    <FormattedNumber value={apuesta} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/>
                </span>
            )}
        />
        <Column
            title="Premio"
            dataIndex="cobra"
            className='column-monto'
            render={(cobra) => (
                <span>
                     
                    <FormattedNumber value={_.toNumber(cobra)} stl="currency"  currency={this.props.tthis.state.moneda} />
                </span>
            )}
        />
    </Table>
)  }  


openNotification = (serial,tipo,isys) => {
    var icon;
    var anuBtn='';
    var iu=this.props.tthis.state.id;
    var {viewValoresAnul}=this.state;
    var anularTK=false;
    switch (isys) {
        case 1:
            if (!_.isUndefined(viewValoresAnul.AnulDeporte)) 
                anularTK=viewValoresAnul.AnulDeporte===1;
            
            break;
        case 2:
            if (!_.isUndefined(viewValoresAnul.AnulAmericana)) 
                anularTK=viewValoresAnul.AnulAmericana===1;
        
            break;
        case 3:
            if (!_.isUndefined(viewValoresAnul.AnulNacionales)) 
                anularTK=viewValoresAnul.AnulNacionales===1;
    
            break;
        default:
            if (!_.isUndefined(viewValoresAnul.AnulLoteria)) 
                anularTK=viewValoresAnul.AnulLoteria===1;
    }
    switch (tipo){
        case 1: icon=<Icon type="meh" style={{ color: '#abb2b9' }}  />; 
                if (anularTK) anuBtn=<Popconfirm  icon={<Icon type="question-circle-o" style={{ color: 'red' }} />} title="Estas seguro de anular es ticket？" okText="Si" cancelText="No" onConfirm={()=> this.mutationanulOneTicketFn(serial,iu,isys) }><Button type="danger">Anular</Button></Popconfirm>;
                break;
        case 2: icon=<Icon type="smile" style={{ color: '#108ee9' }} />; break;
        case 3: icon=<Icon type="frown" style={{ color: '#a93226' }} />; break;
        default: icon=<Icon type="pause-circle" style={{ color: '#f1c40f' }} />; 
    }
    notification.open({
    duration:0,
    message: 'Ticket No.'+serial,
    description: <div> <Ticketcavas isys={isys} tipo={tipo} serial={serial} currency={this.props.tthis.state.moneda}/>{anuBtn}</div>,
    icon: icon,
    style: {
        width: 450,
        marginLeft: 335 - 450}
    });
};
callRefress=(fecha)=>{
    var {id}=this.props.tthis.state;
    var activar=false;
    if (fecha==='-1') {fecha=moment().format('DD/M/YYYY');activar=true;}
    this.queryviewTicketsFn(id,fecha,1);
    this.queryviewTicketsFn(id,fecha,2);
    this.queryviewTicketsFn(id,fecha,3);
    this.queryviewTicketsFn(id,fecha,4);
    this.queryviewValoresAnulFN(id);
    if (activar) this.renderTable(1)
    this.props.tthis.changePross()
}
setRefress=()=>{
   
    if (this.state.fecha===''){
        this.setState({fecha:moment().format('DD/M/YYYY')})
        this.callRefress(moment().format('DD/M/YYYY'))
    }else
        this.callRefress(this.state.fecha)
}

componentDidUpdate=()=>{
  // if (this.props.tthis.state.tm===1) {
  // this.setRefress()
  var diffTime=moment().unix()-this.props.tthis.state.tm
  if (diffTime>=60){
    this.LoadingBar.continuousStart()
    this.props.tmTimer(moment().unix())
    this.setRefress()
  }
   
  // }
  //  this.querydatafechaFn(this.props.tthis.state.id);
}
componentWillReceiveProps=()=>{
    this.props.tmTimer(moment().unix())
}
componentDidMount=()=>{
    this.LoadingBar.continuousStart()
    this.setState({loading:true})
    this.props.tmTimer(moment().unix())
    this.setRefress()
    this.querydatafechaFn(this.props.tthis.state.id);
}
// componentWillUnmount() {
//     this.stopInterval();
//   }
dateRender=(current) => {
    const {pasadoData}=this.state;
    const style = {};
    var momentHoy=current.unix();
    var audi={dep:0,ame:0,nac:0,lot:0}
 
    /// Buscamo si hay data de Deporte el dia solicitado
    let idx=_.findIndex(pasadoData.deporte, function(o) { return ( o.moment === momentHoy ); });
    if (idx!==-1) audi.dep=1
     /// Buscamo si hay data de Americana el dia solicitado
     idx=_.findIndex(pasadoData.americana, function(o) { return ( o.moment === momentHoy ); });
     if (idx!==-1) audi.ame=1
      /// Buscamo si hay data de Deporte el dia solicitado
     idx=_.findIndex(pasadoData.nacionales, function(o) { return ( o.moment === momentHoy ); });
    if (idx!==-1) audi.nac=1
     /// Buscamo si hay data de Loteria el dia solicitado
     idx=_.findIndex(pasadoData.loteria, function(o) { return ( o.moment === momentHoy ); });
     if (idx!==-1) audi.lot=1
    if (audi.dep===1 || audi.ame===1 || audi.nac===1 || audi.lot===1 ) {
        var v=0;
        var c=[];
        // let iv=0;
        // else{iv+=0.5;}
        if (audi.dep===1){v+=0.25;c.push(styleBg[0])}
        // else{iv+=0.5;}
        if (audi.ame===1){v+=0.25;c.push(styleBg[1])}
        // else{iv+=0.5;}
        if (audi.nac===1){v+=0.25;c.push(styleBg[2])}
        // else{iv+=0.5;}
        if (audi.lot===1){v+=0.25;c.push(styleBg[3])}
        var t=0;
        if (v===0.25)   { t=1; }
        if (v===0.5)    { t=v; }
        if (v===0.75)   { t=v; }
        switch (t){
            case 1:     style.border = '1px solid '+c[0]; break;
            case 0.5:   style.borderTop = '1px solid '+c[0]; style.borderLeft= '1px solid '+c[0]; style.borderBottom= '1px solid '+c[1]; style.borderRight = '1px solid '+c[1]; break;
            case 0.75:  style.borderTop = '1px solid '+c[0]; style.borderLeft= '1px solid '+c[0]; style.borderBottom= '1px solid '+c[1]; style.borderRight = '1px solid '+c[2]; break;
            default:
        }
        style.borderRadius = '50%';
        
    }
    return (
        <div className="ant-calendar-date" style={style}>
        {current.date()}
        </div>
    );
}
render(){
        var {loading}=this.state
        return(
            <div key="Table-01" style={{width: '950px'  }}>   
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <br/>
                <Spin tip="Recuperando datos solicitados..." spinning={loading} size="large" >
                    <div><label>Fecha : </label>
                    <DatePicker dateRender={(current)=>this.dateRender(current)} defaultValue={moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')} format="DD/MM/YYYY" onChange={(date)=>this.Changedate(date)} /></div>
                    <br/>
                    <Tabs className="tab-view-tkList" type="card">
                        <TabPane tab="Tickets Posibles" key="1">{this.renderTable(1)}</TabPane>
                        <TabPane tab="Tickets Ganadores" key="2">{this.renderTable(2)}</TabPane>
                        <TabPane tab="Tickets Perdedores" key="3">{this.renderTable(3)}</TabPane>
                        <TabPane tab="Tickets Anulados" key="4">{this.renderTable(4)}</TabPane>
                    </Tabs>
                </Spin>
            </div>     
        )
}
}

export default MisTickets;