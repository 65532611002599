import client from "./conex";
import { gql } from "@apollo/client";
import message from "antd/es/message";
// import global from './globals.js';
// var ip = require('ip');
// const encode = require('nodejs-base64-encode');

const queryviewAmericana = gql`
	query viewAmericana($key: String, $tipo: Int) {
		viewAmericana(key: $key, tipo: $tipo) {
			idh
			sigla
			texto
			cc
			status
			gps {
				carr
				hora
				status
				ejemplares {
					eje
					texto
					status
				}
			}
		}
	}
`;
const queryviewDataJnd = gql`
	query viewDataJnd($code: String) {
		viewDataJnd(code: $code) {
			carr
			dist
			tipo
			edad
			ejemplares {
				Num
				text
				ML
				Jockey
				peso
				Trainer
				edad
			}
		}
	}
`;
const querytimeRespAme = gql`
	query timeRespAme($idcn: Int) {
		timeRespAme(idcn: $idcn) {
			IDCN
			carr
			diffmin
			pt
			close
		}
	}
`;
const querycheckCierreAme = gql`
	query checkCierreAme($idcn: Int) {
		checkCierreAme(idcn: $idcn) {
			IDCN
			carr
			close
		}
	}
`;
const querywhoFirstAme = gql`
	query whoFirstAme($key: String) {
		whoFirstAme(key: $key) {
			IDCN
			Carr
			Hora
			TimeUnix
		}
	}
`;
const querycnfpoolsN1 = gql`
	query cnfpoolsN1($tipo: Int) {
		cnfpoolsN1(tipo: $tipo) {
			id
			tid
			act
			factor
		}
	}
`;
const queryAmericanaResul = gql`
	query AmericanaResul($key: String) {
		AmericanaResul(key: $key) {
			IDCN
			text
			siglas
			dia
			cant
			resultado {
				carr
				win
				place
				show
				four
				confwin
				confplc
				confshw
				conffou
				empate
				exacta
				divexac
				trifecta
				divtrif
				superfecta
				divsuper
				scrat
			}
		}
	}
`;
const querynameHorse = gql`
	query nameHorse(
		$horse: String
		$IDCN: Int
		$carr: Int
		$systm: Int
		$rte: String
	) {
		nameHorse(
			horse: $horse
			IDCN: $IDCN
			carr: $carr
			systm: $systm
			rte: $rte
		) {
			eje
			text
		}
	}
`;
export function querynameHorseFn(tthis, horse, IDCN, carr, systm, rte) {
	client
		.query({
			query: querynameHorse,
			variables: { horse, IDCN, carr, systm, rte },
		})
		.then((data) => {
			var resp = data.data.nameHorse;
			if (resp.length !== 0) tthis.setDatalist(resp);
		});
}
export function queryAmericanaResulFn(tthis, dia) {
	client
		.query({
			query: queryAmericanaResul,
			variables: { key: dia },
		})
		.then((data) => {
			var resp = data.data.AmericanaResul;
			if (resp[0].IDCN !== -1) tthis.setDatalist(resp);
			else message.info("No tenemos la Informacion Disponible!");
		});
}
export function querycnfpoolsN1Fn() {
	client
		.query({
			query: querycnfpoolsN1,
			variables: { tipo: 1 },
		})
		.then((data) => {
			var resp = data.data.cnfpoolsN1;
			if (resp) if (resp.id !== -1) this.setDataPOOL(resp);
		});
}
export function querywhoFirstAmeFn(dia) {
	client
		.query({
			query: querywhoFirstAme,
			variables: { key: dia },
		})
		.then((data) => {
			var whoFirstAme = data.data.whoFirstAme;
			if (whoFirstAme) {
				if (whoFirstAme.length !== 0) {
					if (whoFirstAme[0].IDCN !== -1)
						this.iCaptaDatosPrc(whoFirstAme[0].IDCN);
					else {
						message.info("No tenemos la Informacion Disponible!");
						this.errorload(false);
					}
				} else {
					message.info("No tenemos la Informacion Disponible!");
					this.errorload(false);
				}
			} else {
				message.info("No tenemos la Informacion Disponible!");
				this.errorload(false);
			}
		});
}
export function querycheckCierreAmeFn(tthis, idcn, carr) {
	client
		.query({
			query: querycheckCierreAme,
			variables: { idcn: idcn },
		})
		.then((data) => {
			var checkCierreAme = data.data.checkCierreAme;
			tthis.verifando_cierre_de_Carrera(checkCierreAme, carr);
		});
}
export function querytimeRespAmeFn(idcn, code, idx) {
	client
		.query({
			query: querytimeRespAme,
			variables: { idcn: idcn },
		})
		.then((data) => {
			var timeRespAme = data.data.timeRespAme;
			this.iTimeCount(timeRespAme, code, idx, idcn);
		});
}
export function queryviewDataJndFn(code, idx, idcn) {
	client
		.query({
			query: queryviewDataJnd,
			variables: { code: code },
		})
		.then((data) => {
			var viewDataJnd = data.data.viewDataJnd;
			this.iFinHipoBody(idx, viewDataJnd, idcn);
		});
}

export function queryviewAmericanaFn(dia) {
	client
		.query({
			query: queryviewAmericana,
			variables: { key: dia, tipo: 1 },
		})
		.then((data) => {
			var viewAmericana = data.data.viewAmericana;
			try {
				if (viewAmericana[0].idh !== -1) this.setDatalist(viewAmericana);
				else message.info("No tenemos la Informacion Disponible!");
				this.errorload(false);
			} catch (err) {
				console.log(err);
			}
		})
		.catch((err) => {
			this.errorload(true);
		});
}
