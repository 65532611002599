import React, { Component } from 'react';
import { goToAnchor } from 'react-scrollable-anchor';
import Input from 'antd/es/input'
import Tabs from 'antd/es/tabs';
// import Chart from 'react-apexcharts'
import   './Mainapp.scss';
import * as func from './func';
import {line1} from './genViewTk';
import store from './store';
var _ = require('lodash');
const styleBg=['#108ee9','#590101','#0f5901','#9b9842','#8c429b'];
// const systeEt=['Deportes','Americana','Nacionales','Loteria'];
// const exoticas=['Directas','Exacta','Trifecta','Superfecta','Remate','Pool 4','Loto Hipico'];
const Search= Input.Search;
const { TabPane } = Tabs;
class VerTicket extends Component {

    constructor(props) {
    super(props);
    this.state = {
        delta1:[],
        dias:[],
        options: {
            chart: {
                stacked: true,
            },
            plotOptions: {
                bar: {
                horizontal: true,
                },

            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },

            title: {
                text: 'Ventas/Premios'
            },
            xaxis: { categories:[], labels:{formatter: function (val){return val}} },
            yaxis: {
                title: {
                text: undefined
                },

            },
            tooltip: {
                y: {
                formatter: function (val) {
                    return val 
                }
                }
            },
            fill: {
                opacity: 1

            },
            colors: styleBg,
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
            },
            series:[],
        }
        this.line = line1.bind(this);
        store.subscribe(() => {
            var {options}=this.state;
            options.xaxis.categories=store.getState().dias;
            this.setState({
                series:store.getState().series,
                delta1:store.getState().delta1,
                dias:store.getState().dias,
                options,
            })
        })
    }

    componentDidMount=()=>{
        func.queryallTicketDllFn(this.props.tthis.state.id)
        func.querydataGrapRsmFn(this.props.tthis.state.id)
    }
    TockSerial=(searchTk)=>{
        if (_.findIndex(this.state.delta1, (o)=> o.serial === searchTk)===-1 ){
            func.queryaOneTicketDllFn(_.toNumber(searchTk),this.props.tthis.state.id)
            
        }else
            goToAnchor("s"+searchTk);
    }

render() {
    var {delta1}=this.state;   
    return (
        

        <div >
                <Search
                placeholder="Introduza el Serial a Buscar"
                onSearch={(value) => this.TockSerial(value)}
                enterButton
                />
                <div className="viewTkSearch">
                    {delta1.map((value,index)=>
                        !_.isNull(value)?
                        <div key={`${value.serial}`} id={"s"+value.serial}>
                            {this.line(value.isys,value,index,this.props.tthis.state.moneda)}
                        </div>
                        :''
                    )}
                </div>
                <div className="mixed-chart">
                {this.state.options.xaxis.categories.length!==0&&
                 <Tabs defaultActiveKey="1" >
                    <TabPane tab="Generales" key="1">
                        {/* <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            width="380"
                            /> */}
                    </TabPane>
                    <TabPane tab="Del Dia" key="2">
                        
                    </TabPane>
                 </Tabs> 
                
                    }
            </div>
        </div>

    )

}

}

export default VerTicket;