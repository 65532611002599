import client from './conex'
import {gql} from '@apollo/client'
import message from 'antd/es/message';
// import global from './globals.js';
var _ = require('lodash');
// var ip = require('ip');
// const encode = require('nodejs-base64-encode');

const queryviewNacionales= gql`
query viewNacionales($key:String,$tipo:Int){
    viewAmericana(key:$key,tipo:$tipo) {
        idh
        sigla
        texto
        cc
        status
        gps {
            carr
            hora
            status
            tandaP4
            loto
            ejemplares {
                    eje
                    texto
                    status
                }
        }
    }
}
`;
const querycnfpoolsN2=gql`
    query cnfpoolsN1($tipo:Int,$dia:String,$mnd:String){
        cnfpoolsN1(tipo:$tipo,dia:$dia,mnd:$mnd){
            id
            tid
            act
            factor
        }
    }
    `;  
const queryviewCnfRem=gql`
    query viewCnfRem($carr:Int){
        viewCnfRem(carr:$carr){
            remate{
                precio
                remate
            }
            premio{
                premio
                mas
                porcepremio
            }
        }
    }
    `;  
const queryNacionalesResul=gql`
    query NacionalesResul($key:String){
        NacionalesResul(key:$key) {
            IDCN
            text
            siglas
            dia
            cant
            resultado {
                carr
                win
                place
                show
                four
                confwin
                confplc
                confshw
                conffou
                empate
                exacta   
                divexac 
                trifecta
                divtrif 
                superfecta
                divsuper
                remate
                divremate
            }
        }
    }
    `; 
const queryviewNacionalesPolla= gql`
    query viewNacionalesDTA($dia:String!,$tipo:Int){
        viewNacionalesDTA(dia:$dia,tipo:$tipo) {
            idHipo
            Descripcion
            siglas
            CantCarr
            Ejemplares{
                Carrera
                cantEje
                horse{
                    Noeje
                    Nombre
                    status
                }
            }
        }
    }
    `;
const queryviewPollaMasJDA= gql`
    query viewMorePollaJDA($IDCN:Int!,$tipo:Int){
        viewMorePollaJDA(IDCN:$IDCN,tipo:$tipo) {
            eje
            text
            carr
            act
        }
    }
    `;
const queryviewALlPolla= gql`
query datosPollaGn($IDCN:Int!,$dia:String!,$MONEDA:String){
    allPollaJDA:allPollaJDA(IDCN:$IDCN,MONEDA:$MONEDA) {
      serial
      Jnd
      IDC
      usu
      puntos
      hora
    }
    recaudacion:recaudadoPolla(dia:$dia,Moneda:$MONEDA) {
        fecha
        IDCN
        porMoneda {
            idm
            moneda
            totalVenta
            premio
            Puntostotal
        }
      }
  }
`;
export function queryviewALlPollafn(IDCN,dia,MONEDA){
 
    client.query({
            query: queryviewALlPolla,
            variables:{ IDCN,dia,MONEDA },
    })
    .then(data =>{
        var dataallpolla=data.data.allPollaJDA;
        if (dataallpolla.length!==0) {
            var {recaudacion}=data.data
            this.setState({dataallpolla,recaudacion,nouenFail:false})
        }else{
            message.info('No tenemos la Informacion Disponible!');
            this.setState({nouenFail:true})
        }
    })
} 
export function queryPollamasJDAfn(IDCN,tipo){
    client.query({
            query: queryviewPollaMasJDA,
            variables:{ IDCN,tipo },
    })
    .then(data =>{
        var seleccionadas=data.data.viewMorePollaJDA;
        if (seleccionadas.length!==0) {
            this.setState({seleccionadas,nouenFail:false})
            this.constTRInfo();
        }else{
        message.info('No tenemos la Informacion Disponible!');
        this.setState({nouenFail:true})
        }
    })
} 
export function queryNacionalesPollaDTAFn(dia){
    client.query({
            query: queryviewNacionalesPolla,
            variables:{ dia,tipo:1 },
    })
    .then(data =>{
        var confiPolla=data.data.viewNacionalesDTA;
        if (confiPolla.length!==0) 
            this.setState({confiPolla})
        else{
            message.info('No tenemos toda la informacion Disponible!');
            this.setState({confiPolla:[-1]})
        }    
    })
} 
export function queryNacionalesResulFn(tthis,dia){
        client.query({
                query: queryNacionalesResul,
                variables:{ key:dia },
        })
        .then(data =>{
            var resp=data.data.NacionalesResul;
            if (!_.isNull(resp)){
                if (resp[0].IDCN!==-1) 
                    tthis.setDatalist(resp)
                else
                    message.info('No tenemos la Informacion Disponible!');
            }
        })
    } 
export async function  queryviewCnfRemFn(tthis,carr){
        client.query({
                query: queryviewCnfRem,
                variables:{ carr:carr },
        })
        .then(data =>{
            var resp=data.data.viewCnfRem;
            
            if (!_.isNull(resp)) 
                tthis.setDataRemate(resp)
        })
    } 
export function querycnfpoolsN2Fn(dia,mnd,cb=()=>{}){
    client.query({
            query: querycnfpoolsN2,
            variables:{ tipo:2,dia,mnd },
    })
    .then(data =>{
        var cnfPool=data.data.cnfpoolsN1;
        if (!_.isNull(cnfPool))
            if (cnfPool.id!==-1){ 
                this.setState({cnfPool})
                cb( true)
           } else
           cb( false)
        else
        cb( false)
    })
} 
export function queryviewNacionalesFn(dia,tipo){
    client.query({
            query: queryviewNacionales,
            variables:{ key:dia,tipo },
    })
    .then(data =>{
        var viewAmericana=data.data.viewAmericana;
        try{
        if (viewAmericana[0].idh!==-1){
            var hipodromos=[];
            viewAmericana.forEach((value)=>{
                if (value.status===1)
                    hipodromos.push({
                        id:value.idh,
                        text:value.texto,
                        sta:(value.status===1)
                    })
            })
           
            this.setState({hipodromos,viewData:viewAmericana, disableDiv:false,nohayInfo:false})
            if (hipodromos.length===1){
                this.iCaptadatos(hipodromos[0].id)
            }

        }else{
            this.setState({nohayInfo:true})
        }
        }catch(err){ 
            console.log(err)
        }

    })

} 
/////////////resumidoPolla//////////////////
const queryresumidoPolla= gql` 
query resumidoPolla($Limited:Int,$Moneda:String){
    resumidoPolla(Limited:$Limited,Moneda:$Moneda) {
      Fecha
      Hipodromo
        recaudaRsm {
        idm
        moneda
        totalVenta
        premio
        Puntostotal
      }
      recaudaTk {
        serial
        Jnd
        IDC
        usu
        hora
        puntos
        escrute
        premio
      }
    }
  }
    `;
export function queryresumidoPollaFN(Moneda){
        client.query({
                query: queryresumidoPolla,
                variables:{ Limited:3,Moneda},
        })
        .then(data =>{
            var {resumidoPolla}=data.data;
            if (!_.isNull(resumidoPolla)){
                this.setState({resumidoPolla})
            }
        })
    } 
///////////////////////////////