export const titulo= [
    {
      titulo1: "Numero",
      titulo2: "",
      wih: "80px",
      campo1: "eje",
      campo2: "",
      sizeCp: "13px",
      T2Icon: false,
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 0,
      background: "#a7a7a7",
    },
    {
      titulo1: "Ejemplar",
      titulo2: "",
      wih: "200px",
      campo1: "texto",
      campo2: "Trainer",
      sizeCp: "17px",
      T2Icon: false,
      inp: false,
      pos: 0,
      textAlign: "left",
      tipe: 0,
      background: "#a7a7a7",
    },
    {
      titulo1: "Ganador",
      titulo2: "",
      wih: "100px",
      campo1: "",
      campo2: "",
      sizeCp: "13px",
      inp: true,
      pos: 0,
      textAlign: "left",
      tipe: 0,
      background: "#a7a7a7",
    },
    {
      titulo1: "Exacta",
      titulo2: "",
      wih: "80px",
      campo1: "exacta",
      campo2: "",
      sizeCp: "13px",
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 1,
      background: "rgb(27, 154, 156)",
    },
    {
      titulo1: "Trifecta",
      titulo2: "",
      wih: "120px",
      campo1: "trifecta",
      campo2: "",
      sizeCp: "13px",
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 2,
      background: "rgb(153, 158, 0)",
    },
    {
      titulo1: "SuperFecta",
      titulo2: "",
      wih: "160px",
      campo1: "superfecta",
      campo2: "",
      sizeCp: "13px",
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 3,
      background: "darkkhaki",
    },
  ]



  export const  tituloRemate= [
    {
      titulo1: "Numero",
      titulo2: "",
      wih: "80px",
      campo1: "eje",
      campo2: "",
      sizeCp: "13px",
      T2Icon: false,
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 0,
      background: "#a7a7a7",
    },
    {
      titulo1: "Ejemplar",
      titulo2: "",
      wih: "305px",
      campo1: "texto",
      campo2: "Trainer",
      sizeCp: "17px",
      T2Icon: false,
      inp: false,
      pos: 0,
      textAlign: "left",
      tipe: 0,
      background: "#a7a7a7",
    },
    {
      titulo1: "Precio",
      titulo2: "",
      wih: "115px",
      campo1: "precio",
      campo2: "",
      sizeCp: "18px",
      inp: false,
      pos: 0,
      textAlign: "right",
      tipe: 1,
      background: "darkkhaki",
    },
    {
      titulo1: "Remate de",
      titulo2: "",
      wih: "120px",
      campo1: "remate",
      campo2: "",
      sizeCp: "18px",
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 1,
      background: "rgb(27, 154, 156)",
    },
    {
      titulo1: "Tu Oferta?",
      titulo2: "",
      wih: "120px",
      campo1: "",
      campo2: "",
      sizeCp: "13px",
      inp: true,
      pos: 0,
      textAlign: "center",
      tipe: 0,
      background: "rgb(153, 158, 0)",
    },
  ]

  export const   tituloTanda1= [
    {
      titulo1: "Numero",
      titulo2: "",
      wih: "80px",
      campo1: "eje",
      campo2: "",
      sizeCp: "13px",
      T2Icon: false,
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 0,
      background: "#a7a7a7",
      tanda: "0",
    },
    {
      titulo1: "Ejemplar",
      titulo2: "",
      wih: "130px",
      campo1: "texto",
      campo2: "Trainer",
      sizeCp: "17px",
      T2Icon: false,
      inp: false,
      pos: 0,
      textAlign: "left",
      tipe: 0,
      background: "#a7a7a7",
      tanda: "0",
    },
    {
      titulo1: "Pool de 4",
      titulo2: "Tanda No.",
      wih: "170px",
      campo1: "pool4",
      campo2: "",
      sizeCp: "13px",
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 1,
      background: "darkkhaki",
      tanda: "tandap4",
    },
    {
      titulo1: "Loto",
      titulo2: "",
      wih: "360px",
      campo1: "lotto",
      campo2: "",
      sizeCp: "13px",
      inp: false,
      pos: 0,
      textAlign: "center",
      tipe: 1,
      background: "rgb(27, 154, 156)",
      tanda: "0",
    },
  ]