import React, { Component } from 'react';

import Menu from 'antd/es/menu'
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Tag from 'antd/es/tag';

import moment from 'moment';
import FormattedNumber from './component/FormatNumber';
import ReactToPrint from "react-to-print";
import Cloudwindows from "./cloudwindows";
import {styleBg} from './globals.js';
import * as func from './func_repor';
import   './cloudreporte.scss';
var _ = require('lodash'); 
// var PrintTemplate = require ('react-print');
const Option = Select.Option;

class Reporte1 extends Component {
    render(){
            var {titulo}=this.props.tthis.state;
            var {iusuario}=this.props.tthis.state;
            var {SummDeport}=this.props.tthis.state;
            var {SummAmeri}=this.props.tthis.state;
            var {SummNacio}=this.props.tthis.state;
            var {opciones}=this.props.tthis.state;
            var total1=0,total2=0,total3=0,total4=0,total5=0;
            var lisOp=_.split(opciones,',')
            console.log(lisOp,_.indexOf(lisOp,"0"))
            return(
                <div className="reportGeneral">
                    <div className="TituloRptPrn">
                        <div className="TitleRpt"><label>{titulo}</label></div>
                        <div className="Rptpropiedad"><label>Usuario:{iusuario}</label></div>
                        <div className="Rpt1"><label>Desde:{this.props.tthis.state.desde}</label></div>
                        <div className="Rpt2"><label>Hasta:{this.props.tthis.state.hasta}</label></div>
                    </div>
                    <div className="TituloRpt">
                        <div className="Tt1"><label>Sistema</label></div>
                        <div className="Tt2"><label>Ventas</label></div>
                        <div className="Tt3"><label>%</label></div>
                        <div className="Tt4"><label>Premio</label></div>
                        <div className="Tt5"><label>Ganacia</label></div>
                        <div className="Tt6"><label>Participacion</label></div>
                    </div>
                    {
                        _.indexOf(lisOp,"0")!==-1?
                        
                            SummDeport.map((value,index)=>{
                                total1+=value.ventas;
                                total2+=value.porcentaje;
                                total3+=value.premios;
                                total4+=value.ventas-(value.porcentaje+value.premios);
                                total5+=value.participacion;
                            return(
                                <div className="Rg1">
                                    <div className="Tt1"><label>DEPORTE</label></div>
                                    <div className="Tt2"><label><FormattedNumber value={value.ventas} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                    <div className="Tt3"><label><FormattedNumber value={value.porcentaje} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt4"><label><FormattedNumber value={value.premios} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt5"><label><FormattedNumber value={value.ventas-(value.porcentaje+value.premios)} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt6"><label><FormattedNumber value={value.participacion} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                </div>
                            )
                    })
                        
                        :''
                    }
                    {
                        _.indexOf(lisOp,"1")!==-1?
                        
                        SummAmeri.map((value,index)=>{
    
                            total1+=value.ventas;
                            total2+=value.porcentaje;
                            total3+=value.premios;
                            total4+=value.ventas-(value.porcentaje+value.premios);
                            total5+=value.participacion;
    
                            return (
                                <div className="Rg1">
                                    <div className="Tt1"><label>AMERICANA</label></div>
                                    <div className="Tt2"><label><FormattedNumber value={value.ventas} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                    <div className="Tt3"><label><FormattedNumber value={value.porcentaje} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt4"><label><FormattedNumber value={value.premios} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt5"><label><FormattedNumber value={value.ventas-(value.porcentaje+value.premios)} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt6"><label><FormattedNumber value={value.participacion} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                </div>
                            )
                          }  )
                        
                        :''
                    }
                    {
                        _.indexOf(lisOp,"2")!==-1?
                        
                        SummNacio.map((value,index)=>{
                            total1+=value.ventas;
                            total2+=value.porcentaje;
                            total3+=value.premios;
                            total4+=value.ventas-(value.porcentaje+value.premios);
                            total5+=value.participacion;
    
                            return (
                                <div className="Rg1">
                                    <div className="Tt1"><label>NACIONALES</label></div>
                                    <div className="Tt2"><label><FormattedNumber value={value.ventas} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                    <div className="Tt3"><label><FormattedNumber value={value.porcentaje} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt4"><label><FormattedNumber value={value.premios} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt5"><label><FormattedNumber value={value.ventas-(value.porcentaje+value.premios)} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt6"><label><FormattedNumber value={value.participacion} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                </div>
                            )
                        })
                        
                        :''
                    }
                    <div className="Total1">
                        <div className="Tt1"><label>Total</label></div>
                        <div className="Tt2"><label><FormattedNumber value={total1} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                        <div className="Tt3"><label><FormattedNumber value={total2} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                        <div className="Tt4"><label><FormattedNumber value={total3} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                        <div className="Tt5"><label><FormattedNumber value={total4} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                        <div className="Tt6"><label><FormattedNumber value={total5} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                    </div>
                </div>
    
            )
    }
}

class Reporte2 extends Component {
    render(){
            var {titulo}=this.props.tthis.state;
            var {iusuario}=this.props.tthis.state;
            var {SummDeport}=this.props.tthis.state;
            var {SummAmeri}=this.props.tthis.state;
            var {SummNacio}=this.props.tthis.state;
            var {opciones}=this.props.tthis.state;
            var total1=0,total2=0,total3=0,total4=0,total5=0;
            var stotal1=0,stotal2=0,stotal3=0,stotal4=0,stotal5=0;
            var atotal1=0,atotal2=0,atotal3=0,atotal4=0,atotal5=0;
            var ntotal1=0,ntotal2=0,ntotal3=0,ntotal4=0,ntotal5=0;
            var lisOp=_.split(opciones,',')
            console.log(lisOp,_.indexOf(lisOp,"0"))
            return(
                <div className="reportGeneral">
                    <div className="TituloRptPrn">
                        <div className="TitleRpt"><label>{titulo}</label></div>
                        <div className="Rptpropiedad"><label>Usuario:{iusuario}</label></div>
                        <div className="Rpt1"><label>Desde:{this.props.tthis.state.desde}</label></div>
                        <div className="Rpt2"><label>Hasta:{this.props.tthis.state.hasta}</label></div>
                    </div>
                    <div className="TituloRpt">
                        <div className="Tt1"><label>Fecha/Sis</label></div>
                        <div className="Tt2"><label>Ventas</label></div>
                        <div className="Tt3"><label>%</label></div>
                        <div className="Tt4"><label>Premio</label></div>
                        <div className="Tt5"><label>Ganacia</label></div>
                        <div className="Tt6"><label>Participacion</label></div>
                    </div>
                    {
                        _.indexOf(lisOp,"0")!==-1?
                        <div>
                            { SummDeport.map((value,index)=>{
                                    total1+=value.ventas;
                                    total2+=value.porcentaje;
                                    total3+=value.premios;
                                    total4+=value.ventas-(value.porcentaje+value.premios);
                                    total5+=value.participacion;

                                    stotal1+=value.ventas;
                                    stotal2+=value.porcentaje;
                                    stotal3+=value.premios;
                                    stotal4+=value.ventas-(value.porcentaje+value.premios);
                                    stotal5+=value.participacion;

                                return(
                                    <div className="Rg1">
                                        <div className="Tt1"><label>{value.fecha+'/Dep'}</label></div>
                                        <div className="Tt2"><label><FormattedNumber value={value.ventas} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                        <div className="Tt3"><label><FormattedNumber value={value.porcentaje} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                        <div className="Tt4"><label><FormattedNumber value={value.premios} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                        <div className="Tt5"><label><FormattedNumber value={value.ventas-(value.porcentaje+value.premios)} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                        <div className="Tt6"><label><FormattedNumber value={value.participacion} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    </div>
                                )
                            })}
                            <div className="Rg1">
                                <div className="sTt1"><label>Subtotal(Dep):</label></div>
                                <div className="sTt2"><label><FormattedNumber value={stotal1} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                <div className="sTt3"><label><FormattedNumber value={stotal2}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt4"><label><FormattedNumber value={stotal3}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt5"><label><FormattedNumber value={stotal4}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt6"><label><FormattedNumber value={stotal5}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                            </div>
                        </div>
                        :''
                    }
                    
                    {
                    
                        _.indexOf(lisOp,"1")!==-1?
                        <div>
                            {SummAmeri.map((value,index)=>{
    
                            total1+=value.ventas;
                            total2+=value.porcentaje;
                            total3+=value.premios;
                            total4+=value.ventas-(value.porcentaje+value.premios);
                            total5+=value.participacion;


                            atotal1+=value.ventas;
                            atotal2+=value.porcentaje;
                            atotal3+=value.premios;
                            atotal4+=value.ventas-(value.porcentaje+value.premios);
                            atotal5+=value.participacion;
    
                            return (
                                <div className="Rg1">
                                    <div className="Tt1"><label>{value.fecha+'/Ame'}</label></div>
                                    <div className="Tt2"><label><FormattedNumber value={value.ventas} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                    <div className="Tt3"><label><FormattedNumber value={value.porcentaje} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt4"><label><FormattedNumber value={value.premios} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt5"><label><FormattedNumber value={value.ventas-(value.porcentaje+value.premios)} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt6"><label><FormattedNumber value={value.participacion} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                </div>
                            )
                          }  )}

                          <div className="Rg1">
                                <div className="sTt1"><label>Subtotal(Ame):</label></div>
                                <div className="sTt2"><label><FormattedNumber value={atotal1} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                <div className="sTt3"><label><FormattedNumber value={atotal2}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt4"><label><FormattedNumber value={atotal3}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt5"><label><FormattedNumber value={atotal4}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt6"><label><FormattedNumber value={atotal5}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                            </div>
                        </div>
                        
                        :''
                    }
                    {
                        _.indexOf(lisOp,"2")!==-1?
                        <div>
                            {SummNacio.map((value,index)=>{
                            total1+=value.ventas;
                            total2+=value.porcentaje;
                            total3+=value.premios;
                            total4+=value.ventas-(value.porcentaje+value.premios);
                            total5+=value.participacion;

                            ntotal1+=value.ventas;
                            ntotal2+=value.porcentaje;
                            ntotal3+=value.premios;
                            ntotal4+=value.ventas-(value.porcentaje+value.premios);
                            ntotal5+=value.participacion;
    
                            return (
                                <div className="Rg1">
                                    <div className="Tt1"><label>{value.fecha+'/Nac'}</label></div>
                                    <div className="Tt2"><label><FormattedNumber value={value.ventas} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                                    <div className="Tt3"><label><FormattedNumber value={value.porcentaje} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt4"><label><FormattedNumber value={value.premios} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt5"><label><FormattedNumber value={value.ventas-(value.porcentaje+value.premios)} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt6"><label><FormattedNumber value={value.participacion} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                </div>
                            )
                        })}

                        <div className="Rg1">
                              <div className="sTt1"><label>Subtotal(Nac):</label></div>
                              <div className="sTt2"><label><FormattedNumber value={ntotal1} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                              <div className="sTt3"><label><FormattedNumber value={ntotal2}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                              <div className="sTt4"><label><FormattedNumber value={ntotal3}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                              <div className="sTt5"><label><FormattedNumber value={ntotal4}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                              <div className="sTt6"><label><FormattedNumber value={ntotal5}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                          </div>
                      </div>
                        
                        :''
                    }
                    <div className="Total1">
                        <div className="Tt1"><label>Total</label></div>
                        <div className="Tt2"><label><FormattedNumber value={total1} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>  
                        <div className="Tt3"><label><FormattedNumber value={total2} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                        <div className="Tt4"><label><FormattedNumber value={total3} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                        <div className="Tt5"><label><FormattedNumber value={total4} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                        <div className="Tt6"><label><FormattedNumber value={total5} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                    </div>
                </div>
    
            )
    }
}

class Reporte3 extends Component {
    render(){
            var {titulo}=this.props.tthis.state;
            var {iusuario}=this.props.tthis.state;
            var {tSummDeport}=this.props.tthis.state;
            var {tSummAmeri}=this.props.tthis.state;
            var {tSummNacio}=this.props.tthis.state;
            var {opciones}=this.props.tthis.state;
            var total1=0,total2=0;
            // ,total3=0,total4=0,total5=0;
            var stotal1=0,stotal2=0
            var atotal1=0,atotal2=0
            var ntotal1=0,ntotal2=0
            var lisOp=_.split(opciones,',')
            console.log(lisOp,_.indexOf(lisOp,"0"))
            return(
                <div className="reportGeneralTk">
                    <div className="TituloRptPrn">
                        <div className="TitleRpt"><label>{titulo}</label></div>
                        <div className="Rptpropiedad"><label>Usuario:{iusuario}</label></div>
                        <div className="Rpt1"><label>Desde:{this.props.tthis.state.desde}</label></div>
                        <div className="Rpt2"><label>Hasta:{this.props.tthis.state.hasta}</label></div>
                    </div>
                    <div className="TituloRpt_Tk">
                        <div className="Tt1"><label>Fecha/Sis</label></div>
                        <div className="Tt2"><label>Serial</label></div>
                        <div className="Tt3"><label>Monto</label></div>
                        <div className="Tt4tk"><label>Premio</label></div>
                    </div>
                    {
                        _.indexOf(lisOp,"0")!==-1?
                        <div>
                            { tSummDeport.map((value,index)=>{
                                    total1+=value.monto;
                                    total2+=value.premio;

                                    stotal1+=value.monto;
                                    stotal2+=value.premio;

                                return(
                                    <div className="Rg1">
                                        <div className="Tt1"><label>{value.fecha+'/Dep'}</label></div>
                                        <div className="Tt2"><label>{value.serial}</label></div>  
                                        <div className="Tt3"><label><FormattedNumber value={value.monto} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                        <div className="Tt4tk"><label><FormattedNumber value={value.premio} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    </div>
                                )
                            })}
                            <div className="Rg1">
                                <div className="sTt1"><label>Subtotal(Dep):</label></div>
                                <div className="sTt2"><label></label></div>  
                                <div className="sTt3"><label><FormattedNumber value={stotal1}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt4Tk"><label><FormattedNumber value={stotal2}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                            </div>
                        </div>
                        :''
                    }
                    
                    {
                    
                        _.indexOf(lisOp,"1")!==-1?
                        <div>
                            {tSummAmeri.map((value,index)=>{
    
                            total1+=value.monto;
                            total2+=value.premio;

                            atotal1+=value.monto;
                            atotal2+=value.premio;
    
                            return (
                                <div className="Rg1">
                                    <div className="Tt1"><label>{value.fecha+'/Ame'}</label></div>
                                    <div className="Tt2"><label>{value.serial}</label></div>  
                                    <div className="Tt3"><label><FormattedNumber value={value.monto} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt4tk"><label><FormattedNumber value={value.premio} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                </div>
                            )
                          }  )}

                          <div className="Rg1">
                                <div className="sTt1"><label>Subtotal(Ame):</label></div>
                                <div className="sTt2"><label></label></div>  
                                <div className="sTt3"><label><FormattedNumber value={atotal1}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                <div className="sTt4Tk"><label><FormattedNumber value={atotal2}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                            </div>
                        </div>
                        
                        :''
                    }
                    {
                        _.indexOf(lisOp,"2")!==-1?
                        <div>
                            {tSummNacio.map((value,index)=>{
                            total1+=value.monto;
                            total2+=value.premio;

                            ntotal1+=value.monto;
                            ntotal2+=value.premio;
    
                            return (
                                <div className="Rg1">
                                    <div className="Tt1"><label>{value.fecha+'/Nac'}</label></div>
                                    <div className="Tt2"><label>{value.serial}</label></div>  
                                    <div className="Tt3"><label><FormattedNumber value={value.monto} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                    <div className="Tt4tk"><label><FormattedNumber value={value.premio} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                                </div>
                            )
                        })}

                        <div className="Rg1">
                              <div className="sTt1"><label>Subtotal(Nac):</label></div>
                              <div className="sTt2"><label></label></div>  
                              <div className="sTt3"><label><FormattedNumber value={ntotal1}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                              <div className="sTt4Tk"><label><FormattedNumber value={ntotal2}  stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                          </div>
                      </div>
                        
                        :''
                    }
                    <div className="Total1tk">
                        <div className="Tt1"><label>Total</label></div>
                        <div className="Tt2"><label></label></div>  
                        <div className="Tt3"><label><FormattedNumber value={total1} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                        <div className="Tt4tk"><label><FormattedNumber value={total2} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/></label></div>
                    </div>
                </div>
    
            )
    }
}

class cloudreporte extends Component {
    constructor(props) {
    super(props);        
    this.state = {
            menu_reporte:['Ventas/Ganacias Resumido','Ventas/Ganacias Detallado','Reporte de Premios','Reporte de Ventas','Reporte de Ticket Anulados'],
            iBodyReportes:'',  
            iViewReports:'',
            system:['Deportes','Americana','Nacionales','Loteria'],
            btnHb:false,
            titulo:'',
            visible:false,
            iusuario:'',
            opciones:[0,1,2,3],
            titulo_color:['#90a4ae','#b0bec5','#607d8b','#01579b','#9e9e9e'],
            titulo_color_text:['#000','#000','#FFF','#FFF','#000'],
            SummDeport:[],SummAmeri:[],SummNacio:[],
            tSummDeport:[],tSummAmeri:[],tSummNacio:[],
            desde:moment().format('DD/M/YYYY'),
            hasta:moment().format('DD/M/YYYY'),
        }}

    handleChange=(opciones,option)=> {
            this.setState({opciones})
    }
    ChangedateD=(date)=>{
        this.setState({desde:date.format("DD/M/YYYY")})
    }
    ChangedateH=(date)=>{
        this.setState({hasta:date.format("DD/M/YYYY")})
    }
    inside=(btnHb,op)=>(
        <div>
        <Row className="divTitulo">
            <Col span={3}>
                <label>Desde:</label>
            </Col>
            <Col span={8}>
                <DatePicker size="small" defaultValue={moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')} format="DD/MM/YYYY"  onChange={(date)=>this.ChangedateD(date)}/>
            </Col>
            <Col span={3}>
                <label>Hasta:</label>
            </Col>
            <Col span={8}>
                <DatePicker size="small" defaultValue={moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')} format="DD/MM/YYYY"  onChange={(date)=>this.ChangedateH(date)}/>
            </Col>
        </Row>
        <Row className="CellSelectOne">
            <Col>
                <Select
                    mode="multiple"
                    placeholder="Seleccione"
                    defaultValue={[0,1,2,3]}
                    onChange={(e,op)=>this.handleChange(e,op)}
                >
                {this.state.system.map((value,index)=>
                    // <Option key={index} value={index}>{value}</Option>
                    <Option key={`op_2341_${index}`} value={index} >
                        <Tag key={`op_2342_${index}`} color={styleBg[(index)]}  >{value}</Tag>
                    </Option>
                )}
                </Select>
            </Col>
        </Row>
        <Row type="flex" justify="end" className="CellButtom">
            <Col span={7}>
            <Button  disabled={btnHb} type="primary" shape="round" icon="eye" size="small" onClick={()=>this.iReporteView(op)}>Ver Reporte</Button>
            </Col>
        </Row>
        </div>
    )
    opcionReporte1=(titulo,op)=>{
        const {btnHb}=this.state;
        return(

            <Cloudwindows
            icon={ <Icon type="exception" theme="outlined"  />}
            classWindowsTxt="barrWindows"
            inside={this.inside(btnHb,op)}
            title={titulo}
            classMain="mainwindowsReports"
            statusList={false}
            titleBkgn={this.state.titulo_color[op]}
            colorBkg={this.state.titulo_color_text[op]}
            />
            // <div className="DivMainRepor">
            //     <div className="divTitulo"><label style={{color:this.state.titulo_color[op]}}>{titulo}</label></div>
                
            // </div>
    )}
    SetActuazz=(SummDeport,SummAmeri,SummNacio,id)=>{
        this.setState({SummDeport,SummAmeri,SummNacio,iViewReports:this.strReporte1(id),visible:true})
    }
    SetActuazz1=(tSummDeport,tSummAmeri,tSummNacio,id)=>{
        this.setState({tSummDeport,tSummAmeri,tSummNacio,iViewReports:this.strReporte1(id),visible:true})
    }
    iReporteView=async (id)=>{
        if (this.state.opciones.length!==0){
            switch(id){
                case 0:
                    await func.queryviewDataReportFn(this,this.state.desde,this.state.hasta,this.props.tthis.state.id,2)
                    this.setState({iViewReports:this.strReporte1(),iusuario:this.props.tthis.state.id+'-'+this.props.tthis.state.iu })
                    break;
                case 1:
                    await func.queryviewDataReportFn(this,this.state.desde,this.state.hasta,this.props.tthis.state.id,1)
                    this.setState({iViewReports:this.strReporte2(),iusuario:this.props.tthis.state.id+'-'+this.props.tthis.state.iu })
                    break;
                case 2:
                    await func.queryviewDataReporTkFn(this,this.state.desde,this.state.hasta,this.props.tthis.state.id,2)
                    this.setState({iViewReports:this.strReporte3(),iusuario:this.props.tthis.state.id+'-'+this.props.tthis.state.iu })
                    break;
                case 3:
                    await func.queryviewDataReporTkFn(this,this.state.desde,this.state.hasta,this.props.tthis.state.id,1)
                    this.setState({iViewReports:this.strReporte3(),iusuario:this.props.tthis.state.id+'-'+this.props.tthis.state.iu })
                    break;
                default:
                    await func.queryviewDataReporTkFn(this,this.state.desde,this.state.hasta,this.props.tthis.state.id,3)
                    this.setState({iViewReports:this.strReporte3(),iusuario:this.props.tthis.state.id+'-'+this.props.tthis.state.iu })
            }
            
        }else
         message.error('No tengo ninguna reporte que mostrar');
    }
    strReporte1=()=>{
        return <Reporte1 ref={el => (this.componentRef = el)}  tthis={this}/>
    }
    strReporte2=()=>{
        return <Reporte2 ref={el => (this.componentRef = el)}  tthis={this}/>
    }
    strReporte3=()=>{
        return <Reporte3 ref={el => (this.componentRef = el)}  tthis={this}/>
    }
    iCaptadatos=(id,titulo)=>{
        this.setState({
            titulo,
            iBodyReportes:this.opcionReporte1(titulo,id)
        })
    }
    handleOk=(op)=>{
        this.setState({visible:false})
    }

render() {
    return(
        <div> 
            <div style={{width: '300px',float: 'left'}} >
                    <Menu
                        mode="inline"
                        theme="dark"
                        >
                    {this.state.menu_reporte.map((value,index)=>
                        <Menu.Item key={index} onClick={()=>this.iCaptadatos(index,value)} >
                            <Icon type="check" theme="outlined" />
                            <span>{value}</span>
                        </Menu.Item>
                    )
                    }
                </Menu>
            </div>
            <div  style={{float: 'left'}}>
                {this.state.iBodyReportes}
            </div>
            <Modal
                wrapClassName="modal-reports"
                title={this.state.titulo}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleOk}
                footer={null}
                >
                <div>
                {this.state.iViewReports}
                    <div className="btnPrintReport">
                        <label>
                        <ReactToPrint
                                trigger={() => <Button type="primary">Imprimir Reporte</Button>}
                                content={() => this.componentRef}
                        />
                        </label>
                    </div>
                </div>
            </Modal>
        </div> 
    )}

}


export default cloudreporte;