import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';

import MainIngo from './MainInfo'; 
import './index.css';
import   './MainInfo.css';
import 'rc-banner-anim/assets/index.css';
import * as serviceWorker from './serviceWorker';
// import registerServiceWorker from './registerServiceWorker';

// if ("serviceWorker" in navigator) {
//         navigator.serviceWorker
//           .register('firebase-messaging-sw.js')
//           .then(function(registration) {
//             console.log("Registration successful, scope is:", registration.scope);
//           })
//           .catch(function(err) {
//             console.log("Service worker registration failed, error:", err);
//           });
//       }

ReactDOM.render(
        <Router>
                <MainIngo />
        </Router>
    
, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

