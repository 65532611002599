import React, { Component } from 'react';
import Tabs from 'antd/es/tabs';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Icon from 'antd/es/icon';
import Table from 'antd/es/table';
import Tag from 'antd/es/tag';
import Alert from 'antd/es/alert';
import {colorWin,colorLtr} from './globals.js';
import Spinnerwait from './spinnerwait';
import * as func from './func_nac';
import   './cloudnacResulPolla.scss';

var _ = require('lodash'); 
const { TabPane } = Tabs;

class cloudnacResulPolla extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resumidoPolla:[],
            columns:[{ title: 'Usario', dataIndex: 'usu', key: 'usu' },
            { title: 'Jugada/Puntos', dataIndex: 'Jnd', key: 'Jnd',render: (values,record) => (
                <div>
                <Row>
               {
                    values.split('-').map((tag,index) => {
                     return(
                        <Tag key={`${index}-${tag}`} style={{color:colorLtr[_.toNumber(tag)-1]}}  color={colorWin[_.toNumber(tag)-1]}>{tag}</Tag>
                     )   
                    })
                }
                </Row>
                <Row>
                {
                    record.escrute.split('-').map((tag,index) => {
                     return(
                        <Tag  key={`${index}-${tag}`} color="#108ee9">{tag}</Tag>
                     )   
                    })
                }
                </Row>
                </div>
            ) },
            { title: 'Puntos', dataIndex: 'puntos', key: 'puntos',  sorter: (a, b) => a.puntos - b.puntos },
           ]
        }
        this.queryresumidoPollaFN=func.queryresumidoPollaFN.bind(this);
    }
    viewDataRSMpolla=(registro,dataallpolla,txtHipodromo)=>{
        var {moneda}=this.props;
        if (_.isUndefined(moneda)) moneda='BSS';
        var {totalVenta,premio,Puntostotal}=registro;
        var {columns}=this.state;
        var ppGan=0;
        var zsuma=_.sumBy(dataallpolla, function(o) { return o.puntos; });
        if (zsuma!==0){
            dataallpolla.forEach(element => {
                if (element.puntos===Puntostotal)ppGan++
            });
        } 

        return (
        <Row className="rsm-info-polla">
        <Row className="info-info-polla">
        <Col span={24}  className="rsl-row-Info">
            <Row  type="flex" justify="center" >
                <Col xs={20} lg={24}>
                    <Alert
                    message="Escrutinios"
                    description={<Row className="rsm-info-alert">
                        <Col span={24}>
                            <Row>
                                <Col className="info-lbl-polla inf-alt" span={10}>Hipodromo:</Col>
                                <Col className="inf-alt" span={12}>
                                    {txtHipodromo}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="info-lbl-polla" span={10}>Total Pollas:</Col>
                                <Col   span={12}>
                                    {new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(totalVenta)}
                                </Col>
                            </Row>
                            <Row>
                                <Col  className="info-lbl-polla inf-alt" span={10}>a Repartir:</Col>
                                <Col  className="inf-alt" span={12}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(premio)}</Col>
                            </Row>
                            <Row>
                                <Col  className="info-lbl-polla" span={10}>Puntos:</Col>
                                <Col span={12}>{Puntostotal}</Col>
                            </Row>
                            <Row>
                                <Col  className="info-lbl-polla inf-alt" span={10}>Ganadores:</Col>
                                <Col  className="inf-alt" span={12}>{ppGan}</Col>
                            </Row>
                        </Col>
                       
                    </Row>}
                />
                </Col>
            </Row>
        </Col>
    </Row>
    <Row>
        <Col span={24}  className="rsl-row-table">
        <Row type="flex" justify="space-around" align="middle">
                <Table
                rowKey={record => record.serial}
                className="components-table-demo-nested"
                columns={columns}
                dataSource={dataallpolla}
                expandedRowRender={record => <p style={{ margin: 0 }}>Ticke No:{record.serial}, Hora:{record.hora}, </p>}
            />
            </Row>
        </Col>
    </Row>
     </Row>
    )
    }
    componentDidMount=()=>{
        var {moneda}=this.props;
        if (_.isUndefined(moneda)) moneda='BSS';
        this.queryresumidoPollaFN(moneda)
    }

    render() {
        var {resumidoPolla}=this.state;
        return (
            <Row>
                {resumidoPolla.length===0?
                <Col span={24} >
                    <Spinnerwait/>
                </Col>
                :
                <Col span={24} >
                    <Tabs tabBarGutter={10} tabBarStyle={{color:'#fff'}} >
                        {resumidoPolla.map((value,index)=>
                        
                        <TabPane 
                            tab={<div><Icon type="calendar" style={{ fontSize: '20px' }}   theme="twoTone" twoToneColor="yellow" /><label className="rsl-polla-vl1">{value.Fecha}</label></div>} 
                            key={index}>
                                {this.viewDataRSMpolla(value.recaudaRsm,value.recaudaTk,value.Hipodromo)}
                        </TabPane>
                        
                        
                        )}
                        
                    </Tabs>
                </Col>
                }
            </Row>
        );
    }
}

export default cloudnacResulPolla;