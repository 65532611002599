import React, { Component } from 'react';
import Alert from 'antd/es/alert';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Result from 'antd/es/result';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import * as func from './func_nac';
import store from './store';
var _ =  require('lodash');
const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_849701_8eluroeanb9.js', // generated by iconfont.cn
});

class cloudSelecPolla extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            registros:[],
            nouenFail:false,
            markPolla:[],
            IDCN_Nac:0,
            seleccionadas:[
                {eje: 1,text :  "Luis Emilio",carr:1,act:1 },
                {eje : 2,text :  "Orodiva ",carr:2,act:1},
                {eje : 3,text :  "Queen Mayi",carr:3,act:1},
                {eje : 7,text :  "Turcotte",carr:4,act:1 },
                {eje : 7,text :  "Top Secret",carr:5,act:1},
                {eje : 7,text :  "Pachanguera",carr:6,act:1},
                {eje : 3,text :  "Crackspeed",carr:7,act:1},
                {eje : 4,text :  "Money Green",carr:8,act:1},
                {eje : 3,text :  "El Gran Isaac",carr:9,act:1},
                {eje : 3,text :  "Mr. Guani",carr:10,act:1},
                {eje : 3,text :  "Gregoriano" ,carr:11,act:1},
                {eje : 3,text :  "Khal Drogo ",carr:12,act:1},
                {eje : 3,text :  "Mi Sto Abituando",carr:13,act:1}
              ]
        }
        store.subscribe(() => {
            this.setState({
                markPolla:store.getState().markPolla,
                IDCN_Nac:store.getState().IDCN_Nac,
            })
        })
        this.queryPollamasJDAfn=func.queryPollamasJDAfn.bind(this);
    }
    constTRInfo=()=>{
        var {seleccionadas,registros}=this.state;
        var conteo=0,linea=[],thisnew=registros.length===0,idl=0;

        for (let i=0;i<=seleccionadas.length-1;i++){
            if (thisnew){
                if (conteo===2){
                    linea.push(seleccionadas[i])
                    registros.push(linea)
                    linea=[]
                    conteo=0;
                }else{
                    conteo++;
                    linea.push(seleccionadas[i])
                }
            }else{
                let idx=_.findIndex(registros,(o)=>{
                   return o[0].carr===seleccionadas[i].carr || o[1].carr===seleccionadas[i].carr || o[2].carr===seleccionadas[i].carr 
                })
                if (idx!==-1){
                    var regiVal=registros[idx];
                    if (regiVal[0].carr===seleccionadas[i].carr)idl=0;
                    if (!_.isUndefined(regiVal[1])) if (regiVal[1].carr===seleccionadas[i].carr)idl=1;
                    if (!_.isUndefined(regiVal[2])) if (regiVal[2].carr===seleccionadas[i].carr)idl=2;

                    registros[idx][idl].act=1;
                    registros[idx][idl].eje=seleccionadas[i].eje;
                    registros[idx][idl].text=seleccionadas[i].text;
                    

                }
            }
        }
        registros.push(linea);
        this.setState({registros})
    }
    onClose=(xvalue,closeCarr)=>{
        var {seleccionadas}=this.state;
        for (let i=0;i<=seleccionadas.length-1;i++){
            if (seleccionadas[i].carr===closeCarr)
                seleccionadas[i].act=0;
        }
        this.setState({seleccionadas})
    }
    afterCloseFn=(closeCarr)=>{
        this.onClose(0,closeCarr);
    }

    onMarkPolla=()=>{
        var {seleccionadas}=this.state;
        var markPolla=[]
        for (let i=0;i<=seleccionadas.length-1;i++){
            if (seleccionadas[i].act===0)
                markPolla.push(0)
            else
                markPolla.push(seleccionadas[i].eje)
        }
        console.log(markPolla)
        store.dispatch({ type: 'IN_data_markPolla',markPolla });
        this.props.onChildNacionaes(1);
        this.props.tthis.hideModal();
    }
    actFunValoresINC=()=>{
        var {IDCN_Nac}=store.getState()
        this.queryPollamasJDAfn(IDCN_Nac,1);
    }
    componentDidUpdate= (prevProps)=>{
        if (prevProps.unixMomemt!==this.props.unixMomemt)
            this.actFunValoresINC();
        
    }
    componentDidMount=()=>{
       this.actFunValoresINC();
    }
    render() {
        var {registros,nouenFail}=this.state;
        return (
            
            <div className="fromCloudSelecPolla">
            <Row>
                <Col span={24}  className="rowCloudSelect_btn">
                    <Row  type="flex" justify="end" >
                        {!nouenFail&&
                            <Button type="primary"  icon="download"  className="pollaMasJugada" onClick={()=>this.onMarkPolla()}>
                                    Lo Quiero en mi Ticket
                            </Button>
                        }
                        <Button  type="danger" icon="logout"  className="buttomSalirPolla" onClick={()=>this.props.tthis.hideModal()} >
                                Salir
                        </Button>
                    </Row>
                </Col>
              </Row>   
              {nouenFail?
                  <Result
                  status="404"
                  title="No hay pollas realizadas"
                  subTitle="Pero por ahora no han echo jugadas para ofrecerte esta informacion!."
                  
                />
                :
            registros.map((values,index) => 
            <Row key={'clod'+index.toString()} className="rowCloudSelect">
                <Col span={7} offset={1}>
                    {!_.isUndefined(values[0])&&
                        values[0].act===1&&
                        <Alert
                            message={<span>Carrera:{values[0].carr}º</span>}
                            description={`Ejemplar: ${values[0].eje} ${values[0].text} `}
                            type="success"
                            banner
                            icon={<MyIcon type="icon-saima" style={{ fontSize: '25px'}}/>}
                            closeText={<Icon type="close-circle" />}
                            onClose={(xvalue)=>this.onClose(xvalue,values[0].carr)}
                            showIcon
                            afterClose={()=>this.afterCloseFn(values[0].carr)}
                        />
                    }
                </Col>
                <Col span={7} offset={1}>
                    {!_.isUndefined(values[1])&&
                     values[1].act===1&&
                        <Alert
                            message={<span>Carrera:{values[1].carr}º</span>}
                            description={`Ejemplar: ${values[1].eje} ${values[1].text} `}
                            type="success"
                            icon={<MyIcon type="icon-saima" style={{ fontSize: '25px'}}/>}
                            banner
                            closeText={<Icon type="close-circle" />}
                            onClose={(xvalue)=>this.onClose(xvalue,values[1].carr)}
                            showIcon
                            afterClose={()=>this.afterCloseFn(values[1].carr)}
                        />
                    }
                </Col>
                <Col span={7} offset={1}>
                    {!_.isUndefined(values[2])&&
                     values[2].act===1&&
                    <Alert
                         message={<span>Carrera:{values[2].carr}º</span>}
                        description={`Ejemplar: ${values[2].eje} ${values[2].text} `}
                        type="success"
                        icon={<MyIcon type="icon-saima" style={{ fontSize: '25px'}}/>}
                        banner
                        closeText={<Icon type="close-circle" />}
                        onClose={(xvalue)=>this.onClose(xvalue,values[2].carr)}
                        showIcon
                        afterClose={()=>this.afterCloseFn(values[2].carr)}
                    />
                    }
                </Col>
            </Row>
            
            )}
            </div>

            
        );
    }
}

export default cloudSelecPolla;