import React, { Component } from 'react';
import Icon from 'antd/es/icon';
import Tag from 'antd/es/tag';
import * as func from './func_ame';
import   './cloudnacionales.scss';
const Entities = require('html-entities').AllHtmlEntities;
var _ = require('lodash'); 
const entities = new Entities();

class cloudameResulforCarr extends Component {
    constructor(props) {
        super(props);        
        this.state = {
                Registo:[],
                Name:'',
                horse:[],
                colorWin:['#6f0303','#fff','#021629','#ada31b','#07330f','#000','#ab7481','#88432b','#188281','#410163','#8A8A8A','#709879','#6C4735','#5D1231','#ada8a2','#71739a'],
                colorLtr:['#fff','#000','#fff','#000','#fff','yellow','black','black','black','white','white','black','white','white','black','white']
        }
    }
    setDatalist=(horse)=>{
        this.setState({horse})
    }
    changedSubMenu=()=>{
        this.props.tthis.changedSubMenu(2)
    }
    componentDidMount=()=>{
        this.setState({Registo:this.props.Registo,Name:this.props.Name})
        console.log(this.props.Registo)
        var horse=this.props.Registo.win+','+this.props.Registo.place+','+this.props.Registo.show+','+this.props.Registo.four;
        func.querynameHorseFn(this,horse,this.props.IDCN,this.props.Registo.carr,3)
    }
    
    render() {
        var {Registo}=this.state;
        var {Name}=this.state;
        var {horse}=this.state;
        var win=_.split(Registo.confwin,'|')
        var place=_.split(Registo.confplc,'|')
        var show=_.split(Registo.confshw,'|')
        var conffou=_.split(Registo.conffou,'|')
        var Namewin='' , Nameplace='' , Nameshow='' , Namefour='';
        if (horse.length!==0){
            
            var idx=_.findIndex(horse, function(o) { return (o.eje === Registo.win ); });
            if (idx!==-1) Namewin=entities.decode(horse[idx].text);

           
            idx=_.findIndex(horse, function(o) { return (o.eje === Registo.place ); });
            if (idx!==-1)  Nameplace=entities.decode(horse[idx].text);

            
            idx=_.findIndex(horse, function(o) { return (o.eje === Registo.show ); });
            if (idx!==-1)  Nameshow=entities.decode(horse[idx].text);

            
            idx=_.findIndex(horse, function(o) { return (o.eje === Registo.four ); });
            if (idx!==-1)  Namefour=entities.decode(horse[idx].text);

        }

        return(
                <div> 
                    <div className="resulNacHipTitleDate_BarrConf"> 
                        <span className="resulNacHipTitleDateConf" onClick={()=> this.changedSubMenu()}>{"< Volver "}</span> 
                        <span className="resulNacCellResulInfo">{Name+' '+Registo.carr+'C'}</span> 
                        <span className="resulNacHipTitleWin">Win</span> 
                        <span className="resulNacHipTitlePace">Place</span> 
                        <span className="resulNacHipTitleShow">Show</span> 
                    </div> 
                    {Registo.win!=='0'?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellResul"> 
                                <span className="resulNacHipResul">1º</span>
                            </div>
                            <div className="resulNacCellResulEjem"> 
                                <span className="resulNacHipResulEje"><Tag style={{color:this.state.colorLtr[_.toNumber(Registo.win)-1]}} color={this.state.colorWin[_.toNumber(Registo.win)-1]}>{Registo.win}</Tag>{Namewin}</span>
                            </div>
                            <div className="resulNacCellResulEjemObs"> 
                                <span className="resulNacHipResulEje">{Registo.empate==='1-2'?<Tag color="#108ee9">Empate</Tag>:''}</span>
                            </div>
                            <div className="resulNacCellConforme1"> 
                                <span className="resulNacCellText1">{win[0]}</span>
                            </div>
                            <div className="resulNacCellConforme2"> 
                                <span className="resulNacCellText2">{win[1]}</span>
                            </div>
                            <div className="resulNacCellConforme3"> 
                                <span className="resulNacCellText3">{win[2]}</span>
                            </div>
                    </div>:''}
                    {Registo.place!=='0'?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellResul"> 
                                <span className="resulNacHipResul">2º</span>
                            </div>
                            <div className="resulNacCellResulEjem"> 
                                <span className="resulNacHipResulEje"><Tag style={{color:this.state.colorLtr[_.toNumber(Registo.place)-1]}} color={this.state.colorWin[_.toNumber(Registo.place)-1]}>{Registo.place}</Tag>{Nameplace}</span>
                            </div>
                            <div className="resulNacCellResulEjemObs"> 
                                <span className="resulNacHipResulEje">{Registo.empate==='1-2' || Registo.empate==='2-3'?<Tag color="#108ee9">Empate</Tag>:''}</span>
                            </div>
                            <div className="resulNacCellConforme1"> 
                                <span className="resulNacCellText1">{place[0]}</span>
                            </div>
                            <div className="resulNacCellConforme2"> 
                                <span className="resulNacCellText2">{place[1]}</span>
                            </div>
                            <div className="resulNacCellConforme3"> 
                                <span className="resulNacCellText3">{place[2]}</span>
                            </div>
                    </div>:''}
                    {Registo.show!=='0'?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellResul"> 
                                <span className="resulNacHipResul">3º</span>
                            </div>
                            <div className="resulNacCellResulEjem"> 
                                <span className="resulNacHipResulEje"><Tag style={{color:this.state.colorLtr[_.toNumber(Registo.show)-1]}}  color={this.state.colorWin[_.toNumber(Registo.show)-1]}>{Registo.show}</Tag>{Nameshow}</span>
                            </div>
                            <div className="resulNacCellResulEjemObs"> 
                                <span className="resulNacHipResulEje">{Registo.empate==='2-3'  || Registo.empate==='3-4' ?<Tag color="#108ee9">Empate</Tag>:''}</span>
                            </div>
                            <div className="resulNacCellConforme1"> 
                                <span className="resulNacCellText1">{show[0]}</span>
                            </div>
                            <div className="resulNacCellConforme2"> 
                                <span className="resulNacCellText2">{show[1]}</span>
                            </div>
                            <div className="resulNacCellConforme3"> 
                                <span className="resulNacCellText3">{show[2]}</span>
                            </div>
                    </div>:''}
                    {Registo.four!=='0' || Registo.four!=='' ?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellResul"> 
                                <span className="resulNacHipResul">4º</span>
                            </div>
                            <div className="resulNacCellResulEjem"> 
                                <span className="resulNacHipResulEje"><Tag style={{color:this.state.colorLtr[_.toNumber(Registo.four)-1]}} color={this.state.colorWin[_.toNumber(Registo.four)-1]}>{Registo.four}</Tag>{Namefour}</span>
                            </div>
                            <div className="resulNacCellResulEjemObs"> 
                                <span className="resulNacHipResulEje">{Registo.empate==='3-4'?<Tag color="#108ee9">Empate</Tag>:''}</span>
                            </div>
                            <div className="resulNacCellConforme1"> 
                                <span className="resulNacCellText1">{conffou[0]}</span>
                            </div>
                            <div className="resulNacCellConforme2"> 
                                <span className="resulNacCellText2">{conffou[1]}</span>
                            </div>
                            <div className="resulNacCellConforme3"> 
                                <span className="resulNacCellText3">{conffou[2]}</span>
                            </div>
                    </div>:''}

                    <div className="resulNacHipTitleExoticas"> 
                        <span className="resulNactitle1Exo" >Exoticas</span> 
                        <span className="resulNactitle2Exo" >Llegada</span> 
                        <span className="resulNactitle3Exo" >Dividendos/Premios</span> 
                    </div>
                    {Registo.divexac!=='0'?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellTitleEXO"> 
                                <span className="resulNacHipResulEje"><Icon type="login" />  Exacta</span>
                            </div>
                            <div className="resulNacCellExo1"> 
                                <span className="resulNacHipResulEje">{Registo.exacta}</span>
                            </div>
                            <div className="resulNacCellExo2"> 
                                <span className="resulNacCellText1">{Registo.divexac}</span>
                            </div>
                    </div>:''}
                    {Registo.divtrif!=='0'?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellTitleEXO"> 
                                <span className="resulNacHipResulEje"><Icon type="login" />  Trifecta</span>
                            </div>
                            <div className="resulNacCellExo1"> 
                                <span className="resulNacHipResulEje">{Registo.trifecta}</span>
                            </div>
                            <div className="resulNacCellExo2"> 
                                <span className="resulNacCellText1">{Registo.divtrif}</span>
                            </div>
                    </div>:''}
                    {Registo.divsuper!=='0'?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellTitleEXO"> 
                                <span className="resulNacHipResulEje"><Icon type="login" />  SuperFecta</span>
                            </div>
                            <div className="resulNacCellExo1"> 
                                <span className="resulNacHipResulEje">{Registo.superfecta}</span>
                            </div>
                            <div className="resulNacCellExo2"> 
                                <span className="resulNacCellText1">{Registo.divsuper}</span>
                            </div>
                    </div>:''}
                    {Registo.remate!=='0'?
                    <div className="resulNacfileMainConforme">
                            <div className="resulNacCellTitleEXO"> 
                                <span className="resulNacHipResulEje"><Icon type="login" />  Remate</span>
                            </div>
                            <div className="resulNacCellExo1"> 
                                <span className="resulNacHipResulEje">{Registo.remate}</span>
                            </div>
                            <div className="resulNacCellExo2"> 
                                <span className="resulNacCellText1">{Registo.divremate}</span>
                            </div>
                    </div>:''}

                </div> 
            
        )
    }

}

export default cloudameResulforCarr;