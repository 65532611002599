import { createIntl } from 'react-intl';
const {formatNumber} = createIntl({
    locale: 'es-ES'
  })

const FormattedNumber = ({value,currency='USD',stl,minimumFractionDigits=0,maximumFractionDigits=0}) => {
    return (
        formatNumber(value,
                    { style:stl,
                    minimumFractionDigits,
                    maximumFractionDigits,
                    currency
                    }
        )                                    
    )
}

export default FormattedNumber