import React, { Component } from 'react';
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import Tabs from 'antd/es/tabs'
import Select from 'antd/es/select';
import Alert from 'antd/es/alert';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import FormattedNumber from './component/FormatNumber';
import moment from 'moment';
import * as func from './func';
import store from "./store";
import './cloudopciones.css';
import './iuCuentas.css'
const { Column } = Table;
const FormItem = Form.Item;
const Option = Select.Option;
const TabPane = Tabs.TabPane;
var _ = require('lodash');
const encode = require('nodejs-base64-encode');


////////////////////// From Depositos ////////////////////////
///moment('2018/10/31 22:05').unix()

class FromDepositos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bancos:[]
            }; 
            this.queryviewBancosFn=func.queryviewBancosFn.bind(this);
        }

    handleWebsiteChange = () => {
        this.props.form.validateFields(
            (err,valores) => {
            if (!err) {
                this.props.tthis.saveDatos(valores,this.props.iu,1); 
            }
            },
        );
    }
    setDatalist=(bancos)=>{
        this.setState({bancos})
    }

    componentDidMount=()=>{
        this.queryviewBancosFn()
    }

render() {
    const { getFieldDecorator} = this.props.form;
    
    return (
        <div className="fromDepositos">
        <div className="Inititulofrom1"> <label className='titulofrom2'>DEPOSITO</label></div>
        <Form >
            <FormItem   label="Fecha en que Realizo la Transsaccion:">
            {getFieldDecorator('fecha',  {
            rules:[{ type: 'object', required: true, message: 'Por Favor coloque la fecha de la transsaccion!' }],})(
                <DatePicker showTime format="DD-MM-YYYY HH:mm:ss" />
            )}
            </FormItem>
            <FormItem label="Monto:">
                    {getFieldDecorator('monto', {
                    rules: [{required: true, message: 'Por favor requiero este dato!'},
                        {type: 'number', message: 'Este valor NO es un numero !', 
                        transform(value) {
                            return _.toNumber(value);
                        }}],
                    })(
                    <Input />
                    )}
            </FormItem>
            <FormItem label="Numero de Deposito/Transferencia:">
                {getFieldDecorator('Notransaccion', {
                rules: [{
                    type: 'string', message: 'Debe tener alguna descripcion !',
                }, {
                    required: true, message: 'Por favor requiero alguna descripcion !',
                }],
                })(
                <Input  />
                )}
            </FormItem>
            <FormItem label="Indique desde que Banco se realizo:">
                {getFieldDecorator('banco', {
                rules: [
                    { required: true, message: 'Por favor seleccione el banco donde hizo la transaccion!' },
                    ],
                })(
                    <Select placeholder="Seleccione el Banco">
                        <Option value="0"></Option>
                        {this.state.bancos.map((value,index)=>
                            <Option value={value.Text}>{value.Text}</Option>
                        )}
                    </Select>
                )}
            </FormItem>
            <FormItem >
            <Row gutter={20}>
                <Col span={10}>
                    <Button type="primary" onClick={(e)=>this.handleWebsiteChange()}>Registra Deposito</Button>
                </Col>
                <Col span={10}>
                    <Button onClick={()=> this.props.tthis.clearDatos(false)} >Cancelar</Button>
                </Col>
            </Row>
            </FormItem>
        </Form>
        </div>
        )
    }
}

const EnhancedFormDeposito = Form.create()(FromDepositos);


////////////////////// From Retiros ////////////////////////

class FromRetiros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bancos:[]
            }; 

            this.queryviewBancosFn=func.queryviewBancosFn.bind(this);
        }

    handleWebsiteChange = () => {
        this.props.form.validateFields(
            (err,valores) => {
            if (!err) {
                this.props.tthis.saveRetiros(valores,this.props.tthis.iu,2); 
            }
            },
        );
    }

    setDatalist=(bancos)=>{
        this.setState({bancos})
    }

    componentDidMount=()=>{
        this.queryviewBancosFn()
    }


render() {
    const { getFieldDecorator} = this.props.form;
    return (
        <div className="fromRetiro">
        <div className="Inititulofrom1"> <label className='titulofrom1'>RETIRO(SOLICITUD)</label></div>
        <Form >
            <FormItem label="Fecha de Transsaccion:"  >
            {getFieldDecorator('fecha',  {initialValue:moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss'),
            rules:[{ type: 'object', required: true, message: 'Por Favor coloque la fecha de la transsaccion!' }],})(
                <DatePicker format="DD-MM-YYYY HH:mm a" disabled className="picket-tk-iu" />
            )}
            </FormItem>
            <FormItem label="Monto a retirar">
                    {getFieldDecorator('monto', {
                    rules: [{required: true, message: 'Por favor requiero este dato!'},
                        {type: 'number', message: 'Este valor NO es un numero !', 
                        transform(value) {
                            return _.toNumber(value);
                        }}],
                    })(
                    <Input />
                    )}
            </FormItem>
            <FormItem label="Cuenta para realizar el Deposito:">
                {getFieldDecorator('cuenta', {
                rules: [{
                    type: 'string', message: 'Debe tener alguna descripcion !',
                }, {
                    required: true, message: 'Por favor requiero alguna descripcion !',
                }],
                })(
                <Input  />
                )}
            </FormItem>
            <FormItem label="Indique desde que Banco para la Transaccion:">
                {getFieldDecorator('banco', {
                rules: [
                    { required: true, message: 'Por favor seleccione el banco donde hizo la transaccion!' },
                    ],
                })(
                    <Select placeholder="Seleccione el Banco">
                        <Option value="0"></Option>
                        {this.state.bancos.map((value,index)=>
                            <Option value={value.Text}>{value.Text}</Option>
                        )}
                    </Select>
                )}
            </FormItem>
            <FormItem label="Titular de la Cuenta:">
                {getFieldDecorator('titular', {
                rules: [{
                    type: 'string', message: 'Debe tener alguna descripcion !',
                }, {
                    required: true, message: 'Por favor requiero alguna descripcion !',
                }],
                })(
                <Input  />
                )}
            </FormItem>
            <FormItem label="Identificacion:">
                {getFieldDecorator('identificacion', {
                rules: [{
                    type: 'string', message: 'Debe tener alguna descripcion !',
                }, {
                    required: true, message: 'Por favor requiero alguna descripcion !',
                }],
                })(
                <Input  />
                )}
            </FormItem>
            <FormItem >
            <Row gutter={20}>
                <Col span={10}>
                    <Button type="primary" onClick={(e)=>this.handleWebsiteChange()}>Registra Retiro</Button>
                </Col>
                <Col span={10}>
                    <Button onClick={()=> this.props.tthis.clearDatos(false)} >Cancelar</Button>
                </Col>
            </Row>
            </FormItem>
        </Form>
        </div>
        )
    }
}

const EnhancedFormRetiros = Form.create()(FromRetiros);

class FromMisTrans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accion:''
            }; 

        this.mutationRegistroCuentasFn=func.mutationRegistroCuentasFn.bind(this); 
        }

    fromDeposito=()=>{
        return (
            <div className="fromDepositoMain">
                <br/>
                <EnhancedFormDeposito tthis={this}/>
                <br/>
            </div>
        )
    }
    fromRetiro=()=>{
        return (
            <div className="fromRetiroMain">
                <br/>
                <EnhancedFormRetiros tthis={this}/>
                <br/>
            </div>
        )
    }
  
    saveRetiros=(registro,iu,ts)=>{
        var tvalue=registro.fecha.format("YYYY-MM-DD HH:MM")
        var recordnew={
            fecha: _.toString(moment(tvalue).unix()),
            iu: this.props.iu,
            ts: ts, 
            monto: _.toNumber(registro.monto),
            text:'<<'+registro.cuenta+'<<'+registro.banco+'<<'+registro.titular+'<<'+registro.identificacion,
            estatus:2,
        }
        this.mutationRegistroCuentasFn(recordnew)  /// Graba la Transsaccion
    }

    saveDatos=(registro,iu,ts)=>{
        var tvalue=registro.fecha.format("YYYY-MM-DD HH:MM")
        var recordnew={
            fecha: _.toString(moment(tvalue).unix()),
            iu: this.props.iu,
            ts: ts, 
            monto: _.toNumber(registro.monto),
            text:'<<'+registro.Notransaccion+'<<'+registro.banco+' <<',
            estatus:2,
        }
        this.mutationRegistroCuentasFn(recordnew)  /// Graba la Transsaccion
    }
    actualizaciones=()=>{
        this.props.tthis.actualizacionesMain();
    }
    clearDatos=()=>{
        this.setState({accion:''})
    }

    
    render() {
        const {saldo} = store.getState()

        return (
            <div>
             <Button type="primary" onClick={()=>this.setState({accion:this.fromDeposito()})} >Reportar Deposito</Button>&nbsp;
             <Button type="danger"  onClick={()=>this.setState({accion:this.fromRetiro()})}>Solicitud de Retiro</Button><br/><br/>
             {this.state.accion}
                <Alert
                message="Saldo Actual"
                description={<div><label>Disponible:</label>
             
                    <FormattedNumber value={saldo} stl="currency"  currency={this.props.tthis.state.moneda} minimumFractionDigits={2} maximumFractionDigits={2}/>
                </div>}
                type="info"
                />
                <Table 
                rowKey={record => _.toInteger(record.id)} 
                dataSource={this.props.tthis.state.datahistoria}
                rowClassName={
                    ({estatus})=>{
                        const statusTransacc ={
                            2: "markc-estatus-block",
                            3:"markc-estatus-block-noaprobado"
                        }
                        return statusTransacc[estatus] || 'markc-estatus-standard'
                    }
                }
               >
                    <Column
                        key="id"
                        title="Fecha"
                        dataIndex="fecha"
                        render={(fecha) => (
                            <span>
                                {moment.unix(fecha).format('MMM D YYYY, h:mm a')}
                            </span>
                        )}
                    />
                     <Column
                        title="Descripcion"
                        dataIndex="descripcion"
                        width={400}
                    />
                    <Column
                        title="Origen"
                        dataIndex="origen"
                    />
                    <Column
                        title="Monto"
                        dataIndex="monto"
                        className='column-monto'
                        width={120}
                        render={(monto,record) => (
                            <span>
                           
                                <FormattedNumber value={monto} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/>
                            </span>
                        )}
                    />
                    <Column
                        title="D/C"
                        dataIndex="operacion"
                    />
                    <Column
                        title="Saldo"
                        dataIndex="saldo"
                        className='column-monto'
                        width={140}
                        render={(saldo,record) => (
                            
                            (record.estatus===1 ?
                            <span>
                                <FormattedNumber value={saldo} stl="decimal" minimumFractionDigits={2} maximumFractionDigits={2}/>
                            </span>
                            :record.estatus===2 ?
                            <span>Por Aprobrar</span>:
                            record.estatus===3 ?
                            <span>NO APROBADO</span>: <span></span>)
                        )}
                    />
                </Table>
        </div>

        )}
}

class TranssacIU extends Component {

    constructor(props) {
        super(props);     
        this.state = {
            seleccion:[],
            pasww:'******',
            datahistoria:[],
            saldo:0,
            moneda:'Bss',
        }
        this.queryHistoryCuentasFn=func.queryHistoryCuentasFn.bind(this);
        this.viewListIUonly =func.viewListIUonly.bind(this);
    }
    setDataIU=(seleccion)=>{
        try{
        seleccion.pw=JSON.parse(encode.decode(seleccion.pw, 'base64'));
        }catch(err){
        }
        this.setState({seleccion});
    }
    setDatalist2=(datahistoria)=>{
        this.setState({ datahistoria}); 
    }
    actualizacionesMain=()=>{
        this.viewListIUonly(this.props.tthis.state.id)
        this.queryHistoryCuentasFn('0',this.props.tthis.state.id,0,15)
        this.props.tthis.changePross()
    }
    setRefress=()=>{
        this.actualizacionesMain();
        this.setState({
            saldo:this.props.tthis.state.saldo,
            moneda:this.props.tthis.state.moneda,
                });
    }
    componentDidMount=()=>{
        this.setState({ datahistoria:[]}); 
        this.setRefress();
    }
    componentWillReceiveProps=()=>{
        this.setState({ datahistoria:[]}); 
        this.setRefress();
    }
    actualizarMain=()=>{
        this.props.tthis.changePross()
    }

    saveMisDatos=(registro)=>{
        var realAvatar='0';
        if (registro.upload!=='undefined'){
            var ext=_.split(registro.upload,'.')
            realAvatar=this.props.tthis.state.id+'.'+ext[1];
        }

        var recordnew={ 
            iu: this.props.tthis.state.id,
            Nombre:registro.nombre,
            email:registro.email,
            identif:registro.identificacion,
            Moneda:registro.moneda,
            idioma:_.toString(registro.idioma),
            pw:encode.encode(JSON.stringify(registro.password), 'base64'),
            avatar:realAvatar,
            fn:_.toString(moment(registro.fn).unix()),
            tc:registro.tc,
            banco:registro.banco,
            cb:registro.cb,
            ntlf:registro.ntlf
            }                       
        func.grabarIUlitte(recordnew,this)

    }
    setClaveNueva=(pasww)=>{
        this.setState({ pasww });
    }

render(){
        return(
            <div key="Table-02" >   
                <br/>
                <Tabs className="tab-view-trassc" type="card">
                    <TabPane tab="Transacciones" key="2"><FromMisTrans  tthis={this} iu={this.props.tthis.state.id} /> </TabPane>
                </Tabs>
            </div>     
        )
}
}

export default TranssacIU;

