
import { Modal, message } from "antd";
import { toket, urlMsg } from "../../globals"



export const messageBoxForError = ({ content, title,refInputPhone }) => {
    message.error(`${title}-${content}`, 1.5, () => {
        refInputPhone.current.select();
        refInputPhone.current.focus();
    });
};

export const sendMessengerData = async ({numberPhoneForSend,menssengerTxt,refInputPhone}) => {
    const code = numberPhoneForSend.substr(0, 3);
    const data = {
        code: code,
        phone: numberPhoneForSend.substr(3),
        mensaje: menssengerTxt,
    };

    // eslint-disable-next-line no-undef
    return await fetch(urlMsg, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${toket}`,
        },
    })
        .then(async (res) => res.json())
        .catch(() => {
            messageBoxForError({
                title: "Error",
                content: "No se puede enviar el mensaje",
                refInputPhone
            });
            return false;
        })
        .then(async (response) => {
            const { error, mensageStates } = response;
            if (error) {
                const displayError = mensageStates || error;
                messageBoxForError({
                    title: "Error",
                    content: displayError,
                    refInputPhone
                });
                return false;
            } else {
                Modal.success({
                    title: "Listo ✅",
                    content: `Ticket enviado al ${code}-${numberPhoneForSend.substr(
                        3
                    )} 📲`,
                });
                return true;
            }
        });
};

