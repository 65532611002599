import React, {  useRef } from "react";
import { Modal, Input } from "antd";
import { useWhatsapp } from "./hooks/useWhatsapp";

import "./index.css";

const Whatsapp = ({ activate, onchange, menssengerTxt }) => {
	const refInputPhone = useRef();
	const {
		selectBefore,
		validNumberPhone,
		visible,
		listaAuthCode,
		onSend,
		setNumberPhone,
		closeModal,
	} = useWhatsapp({ refInputPhone, menssengerTxt, activate, onchange });

	return (
		<Modal
			title="Indique el Numero telefonico: (Eje.4146123456)"
			visible={visible}
			onOk={onSend}
			onCancel={closeModal}
			okText="Enviar"
			cancelText="Cancelar"
			closable={false}
			maskClosable={false}
			destroyOnClose={true}
		>
			<div style={{ marginBottom: 16 }}>
				<Input
					className={validNumberPhone ? "ws-border-allow" : "ws-border-deny"}
					ref={refInputPhone}
					addonBefore={selectBefore}
					placeholder="Numero Telefonico"
					autoFocus={true}
					onChange={setNumberPhone}
				/>
				<small className="ws-small-info">Solo: {listaAuthCode}</small>
			</div>
		</Modal>
	);
};

export default Whatsapp;
