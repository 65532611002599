import React, { Component } from 'react';
import * as func from './func_nac';
import   './cloudnacionales.scss';
var _ = require('lodash'); 
class cloudameResul extends Component {
    constructor(props) {
        super(props);        
        this.state = {
                listConforme:[],
                dia:'hoy',
                tm:null,
        }
    }

   
    setDatalist=(listConforme)=>{
        this.setState({
            listConforme,
        })
    }
    CallResulCarr=(carr,IDCN)=>{
        var idx1=_.findIndex(this.state.listConforme, function(o) { return (o.IDCN === IDCN ); });
        var regis1=this.state.listConforme[idx1].resultado
        var idx2=_.findIndex(regis1, function(o) { return (o.carr === carr ); });
        var regis=regis1[idx2]
        this.props.tthis.viewResultadoCarrNac(regis,this.state.listConforme[idx1].text,this.state.listConforme[idx1].IDCN)
    }
    callUpdate=()=>{
        func.queryNacionalesResulFn(this,this.state.dia)
    }
    componentWillMount=()=>{
        this.callUpdate()
    }
    componentDidMount=()=>{
        var {tm}=this.state
        tm=setInterval(()=>this.callUpdate(),30000);
        this.setState({tm})
    }
    componentWillUnmount=()=>{
        var {tm}=this.state
        clearInterval(tm)
    }
    render() {
        return(
            this.state.listConforme.length!==0?
                <div> 
                <div className="resulNacHipTitleDate_Barr"> 
                <span className="resulNacHipTitleDate">{this.state.listConforme[0].dia}</span> 
                </div> 
                {this.state.listConforme.map((value,index)=> 
                    <div key={index} className="resulNacfileMain">
                        <span className="resulNacHipTitle">{_.capitalize(value.text)}</span>
                        {value.resultado.map((value1,index1)=>  
                            <div key={index1} className="resulNacCell" onClick={()=>this.CallResulCarr(value1.carr,value.IDCN)}> 
                                <span className={"resulNacHip"+(value.cant===value1.carr?'_ult':'')}>{value1.carr}</span>
                                <span className={"resulNacHip2"+(value.cant===value1.carr?'_ult':'')}>Resultado</span>
                            </div>
                        )} 
                    </div>
                )}   
                </div> 
            :''
        )
    }

}

export default cloudameResul;