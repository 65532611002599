import React, { Component,createRef } from "react";

import Menu from "antd/es/menu";
import Popover from "antd/es/popover";
import Input from "antd/es/input";
import Icon from "antd/es/icon";
import Tabs from "antd/es/tabs";
import Button from "antd/es/button";
import Tag from "antd/es/tag";
import notification from "antd/es/notification";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Tooltip from "antd/es/tooltip";
import Result from "antd/es/result";
import message from "antd/es/message";
import {formatValues} from './component/formatValues'
import {titulo,tituloRemate,tituloTanda1 } from './cloudNacionales-cnfg'
import {colorWin,colorLtr} from './globals';

import * as func from "./func_nac";
import * as funcext from "./func";
import Spinnerwait from "./spinnerwait";
import "./cloudnacionales.scss";
import store from "./store";

var _ = require("lodash");
var ip = require("ip");
const TabPane = Tabs.TabPane;
const ButtonGroup = Button.Group;

class cloudnacionales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receptor:false,
      nombredePolla:'',
      idDefault: 0,
      confiPolla: [],
      markPolla: [],
      isHabilitar: [true, true, true, true],
      tm: null,
      disableDiv: false,
      StopRender: true,
      activoView: {},
      diffmin: [],
      impRef: [],
      impRefRmt: [],
      viewData: [],
      dato: [],
      datoRmt: [],
      NameHipo: "",
      hora: "",
      id: 0,
      posh:0,
      timeOut: 0,
      aceptatAP: true,
      actRace: 1,
      dia: "hoy", //'hoy'
      nohayInfo: false,
      iBodyNacionales: "",
      hipodromos: [],
      hipoinfo: [],
      hoserInfo: [],
      rowelecc: [],
      poolsxrace: [],
      markpool: [],
      cnfPool: [],
      remateIni: [],
      remateIniCnf: [],
      tandaxRace: [],

      viewpoolxrace: {
        exacta: true,
        trifecta: true,
        superfecta: true,
        ganador: true,
        pool4: true,
        lotto: true,
        polla: true,
      },
    };
    this.refInput = createRef();
    this.queryNacionalesPollaDTAFn = func.queryNacionalesPollaDTAFn.bind(this);
    this.querycnfpoolsN2Fn = func.querycnfpoolsN2Fn.bind(this);
    this.queryviewNacionalesFn = func.queryviewNacionalesFn.bind(this);

    store.subscribe(() => {
      this.setState({
        markPolla: store.getState().markPolla,
      });
    });
  }
  iCaptadatos = (idDefault) => {
    this.setState({ idDefault });
    this.iClearDatos(0);

    this.props.virtualTicketNac([], [], 0, 0);

    this.iFrmDatos(idDefault);
  };
  iFrmDatos = async (id) => {
    var { poolsxrace } = this.state;
    var { viewData } = this.state;
    var { tandaxRace } = this.state;
    var rowelecc = [];
    var ejemplares = [];
    var actRace = 0,
      hora = "",
      NameHipo = "";
    var hoserInfo = [];
    var dato = [];
    var datoRmt = [];
    var ap;
    var idx = _.findIndex(viewData, function (o) {
      return o.idh === id;
    });
    // var { markPolla, confiPolla, viewpoolxrace, cnfPool,receptor } = this.state;
    var gps = viewData[idx].gps;
    NameHipo = viewData[idx].texto;
    var actiCarr = true;
    var actiTanda = true;
    var actiPool = true;
    var tndp4 = 1,
      tndlto = 0;
    for (var row in gps) {
      if (actiCarr) {
        if (gps[row].status === "A") {
          actRace = _.toNumber(gps[row].carr);
          hora = gps[row].hora;
          ejemplares = gps[row].ejemplares;
          var cAnte = ejemplares.length;
          hoserInfo.push({
            carr: actRace,
            dist: "",
            ejemplares: ejemplares,
          });
          actiCarr = false;
        }
      }
      if (actiTanda) {
        if (gps[row].tandaP4 === 1) {
          if (gps[row].status === "A") actiTanda = false;
          else tndp4++;
        }
      }
      if (actiPool) {
        if (gps[row].loto === 1) {
          if (gps[row].status === "A") {
            actiPool = false;
            tndlto = _.toNumber(gps[row].carr);
          }
        }
      }
    }
    var habPool = [];
    if (actiTanda) tndp4 = -1;
    if (actiPool) tndlto = -1;
    else {
      var maxi = viewData[idx].cc - tndlto + 1;
      for (var c = 1; c <= maxi; c++) habPool.push(c);
    }

    tandaxRace.push({
      pool4: [1, 2, 3, 4],
      lotto: habPool,
    });

    await func.queryviewCnfRemFn(this, actRace);

    for (var i = 0; i <= cAnte - 1; i++) {
      rowelecc.push("#666");
      ap = ["", i];
      dato.push(ap);
      datoRmt.push(ap);
    }

    poolsxrace.push({
      exacta: [1, 2],
      trifecta: [1, 2, 3],
      superfecta: [1, 2, 3, 4],
    });

    //// Activar IDCN_Nac por redux
    store.dispatch({ type: "IN_data_IDCN_Nac", IDCN_Nac: id });

    this.setState({
      posh:idx,//*** Posicion del IDCN Solicitado (Hipodromo) */
      activoView: {
        IDCN: id,
        carr: actRace,
        ce: cAnte,
        tandap4: tndp4,
        loto: tndlto,
      },
      dato,
      datoRmt,
      poolsxrace,
      hoserInfo,
      NameHipo,
      hora,
      actRace,
      rowelecc,
      disableDiv: false,
      tandaxRace,
    });
    var iBodyNacionales =  this.despligueHipo(
      hoserInfo,
      NameHipo,
      hora,
      actRace,
      this.state.markpool,
      poolsxrace,
      rowelecc,
      this.state.viewpoolxrace,
      tandaxRace,idx
    );

    this.setState({ iBodyNacionales });

    this.onfocusOne();
    if (actRace === 0)
      message.warning("Tenemos todas las Carreras Cerradas por esta Jornada! ");
  };
  iClearDatos = (code) => {
    var disableDiv = false;
    if (code === -7) {
      disableDiv = true;
      this.queryviewNacionalesFn(this.state.dia,2);
    }
    this.setState({
      iBodyNacionales: "",
      impRef: [],
      StopRender: true,
      aceptatAP: true,
      disableDiv,
      timeOut: 0,
      viewpoolxrace: {
        exacta: true,
        trifecta: true,
        superfecta: true,
        ganador: true,
        pool4: true,
        lotto: true,
        polla: true,
      },
      markpool: [],
    });
  };

  onRef = (ref, x, y) => {
    var impRef = this.state.impRef;
    if (!_.isNull(ref)) {
      impRef.push({ eje: x, pos: y, ref: ref });
      this.setState({ impRef });
    }
  };
  onRefRmt = (ref, x, y) => {
    var { impRefRmt } = this.state;
    if (!_.isNull(ref)) {
      impRefRmt.push({ eje: x, pos: y, ref: ref });
      this.setState({ impRefRmt });
    }
  };
  OnclickTab = (event) => {
    if (event === "2") this.props.changeviewAp(true, false, false);
    else this.props.changeviewAp(false, true, true);
  };

  despligueHipo = (
    hoserInfo,
    NameHipo,
    hora,
    actRace,
    markpool,
    cnfap,
    rowelecc,
    viewpoolxrace,
    tandaxRace,idx
  ) => {
    var { isHabilitar } = this.state;
    var disableDiv = this.state.disableDiv;
    var headamericanventa = {
      width: "850px",
      background: "#a7a7a7",
      opacity: disableDiv ? "0.6" : "1",
    };
    return hoserInfo.length !== 0 ? (
      <Tabs
        style={{ height: "10000px", color: "#fff", width: "780px" }}
        onTabClick={(e) => this.OnclickTab(e)}
      >
        {isHabilitar[0] && (
          <TabPane tab="Ganadores" key="1">
            <div style={headamericanventa}>
              <div className="tablaHeadcarrNaci">
                <div className="barraHipo">
                  Carrera&nbsp;{actRace}&nbsp;{NameHipo}
                </div>

                <div key="hip05" className="barraHipoCarrSel">
                  <span>{actRace}</span>
                </div>
                <div key="hip06" className="barraHipoCarrNac">
                  <div className="barraHipoCarrNac_n2">
                    <span className="spanbarraHipo">
                      <samp className="span1barraHipo">&nbsp;Partida:</samp>
                      {hora}
                      <span className="spanbarraHipo">
                        <samp className="span1barraHipo">&nbsp;Distancia:</samp>
                        {hoserInfo[0].dist}{" "}
                      </span>
                      <span
                        className={
                          this.state.aceptatAP
                            ? "BtnAceptaAP_none"
                            : "BtnAceptaAP"
                        }
                      >
                        <Button
                          ghost
                          block
                          className="ant-btn3 ant-btn-background-ghost3"
                          onClick={() => this.onClickAceptarAP()}
                        >
                          {" "}
                          Aceptar Apuesta{" "}
                        </Button>
                      </span>{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div style={styles.tablaApuestaHead}>
                {titulo.map((
                  value,
                  index //rgb(161, 161, 161)
                ) => (
                  <div
                    key={"hip_" + index}
                    style={{
                      float: "left",
                      width: value.wih,
                      background: value.background,
                      display: "table-cell",
                      height: "50px",
                      verticalAlign: "middle",
                      textAlign: value.textAlign,
                    }}
                  >
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        height: value.titulo2 !== "" ? "30px" : "50px",
                        padding: value.titulo2 !== "" ? "5px" : "15px",
                        paddingLeft: "0px",
                      }}
                    >
                      {value.titulo1}
                    </span>
                    {value.titulo2 !== "" ? (
                      <span
                        style={{
                          width: "100%",
                          display: "block",
                          height: "20px",
                        }}
                      >
                        {value.titulo2}
                      </span>
                    ) : (
                      ""
                    )}

                    {hoserInfo[0].ejemplares.map((value1, index2) => {
                      var x = index2,
                        y = value.pos;
                      return (
                        <div
                          key={"W" + index2}
                          style={{
                            width: "100%",
                            float: "left",
                            display: "table-cell",
                            background: rowelecc[index2],
                            verticalAlign: "middle",
                            borderBottom: "1px solid #757575",
                            height: "50px",
                          }}
                        >
                          {value.inp ? (
                            <div>
                              <span
                                style={{
                                  width: "100%",
                                  display: viewpoolxrace["ganador"]
                                    ? "block"
                                    : "none",
                                  color: "#e4e4e4",
                                  padding:
                                    value1[value.campo2] !== ""
                                      ? "5px"
                                      : "15px",
                                  paddingLeft: "0px",
                                  textAlign: value.textAlign,
                                  fontSize: value.sizeCp,
                                  fontWeight: "lighter",
                                }}
                              >
                                <Input
                                  onChange={(e) => this.onChanAp(e, x, y)}
                                  type="Number"
                                  ref={(ref) => this.onRef(ref, x, y)}
                                  onPressEnter={() =>
                                    this.onPressEnterInput(x, y)
                                  }
                                  onFocus={() => this.focusAp(x)}
                                  onBlur={() => this.onBlurAp(x)}
                                  disabled={disableDiv}
                                />
                              </span>
                            </div>
                          ) : value.tipe === 0 ? (
                            <div>
                              <span
                                style={{
                                  width: "100%",
                                  display: "block",
                                  color: "#e4e4e4",
                                  padding:
                                    value1[value.campo2] !== ""
                                      ? "5px"
                                      : "15px",
                                  paddingLeft: "0px",
                                  textAlign: value.textAlign,
                                  fontSize: value.sizeCp,
                                  fontWeight: "lighter",
                                }}
                              >
                                {value.campo1 === "eje" ? (
                                  <Tag
                                    style={{
                                      color: colorLtr[
                                        _.toNumber(value1[value.campo1]) - 1
                                      ],
                                    }}
                                    color={
                                      colorWin[
                                        _.toNumber(value1[value.campo1]) - 1
                                      ]
                                    }
                                  >
                                    {value1[value.campo1]}
                                  </Tag>
                                ) : (
                                  value1[value.campo1]
                                )}
                              </span>
                              {value.T2Icon ? (
                                <Popover
                                  title={"Informacion"}
                                  content={value1[value.campo2]}
                                  trigger="click"
                                >
                                  <Icon type="info-circle" theme="outlined" />{" "}
                                </Popover>
                              ) : value1[value.campo2] !== "" ? (
                                <span
                                  style={{
                                    width: "100%",
                                    display: "block",
                                    color: "#bbb",
                                    fontSize: "12px",
                                  }}
                                >
                                  {value1[value.campo2]}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div
                              key={"exo_" + index2}
                              style={{
                                width: "100%",
                                float: "left",
                                display: "table-cell",
                                background: rowelecc[index2],
                                verticalAlign: "middle",
                                borderBottom: "1px solid #757575",
                                height: "50px",
                              }}
                            >
                              {cnfap[0][value.campo1].map((value4, index4) => {
                                var celda = index2 + "-" + value4;
                                var idx = _.findIndex(markpool, function (o) {
                                  return (
                                    o.pool === value.campo1 && o.celda === celda
                                  );
                                });
                                return disableDiv === true ? (
                                  <div
                                    key={"ap_exo_" + index4}
                                    className={
                                      (index4 ===
                                      cnfap[0][value.campo1].length - 1
                                        ? "cellBoxSpGan1_cellback"
                                        : "cellBoxSpGan1") +
                                      (idx === -1 ? "" : "_sel")
                                    }
                                  >
                                    <span
                                      style={{
                                        padding: "5px",
                                        display: viewpoolxrace[value.campo1]
                                          ? ""
                                          : "none",
                                      }}
                                    >
                                      {value4 + "º"}
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    key={"ap_exo_" + index4}
                                    className={
                                      (index4 ===
                                      cnfap[0][value.campo1].length - 1
                                        ? "cellBoxSpGan1_cellback"
                                        : "cellBoxSpGan1") +
                                      (idx === -1 ? "" : "_sel")
                                    }
                                    onClick={() =>
                                      this.onClickCellExotica(
                                        value.campo1,
                                        celda
                                      )
                                    }
                                  >
                                    <span
                                      style={{
                                        padding: "5px",
                                        display: viewpoolxrace[value.campo1]
                                          ? ""
                                          : "none",
                                      }}
                                    >
                                      {value4 + "º"}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </TabPane>
        )}
        {isHabilitar[1] && (
          <TabPane tab="Remate" key="2">
            {this.despliegueRemate(
              hoserInfo,
              NameHipo,
              hora,
              actRace,
              markpool,
              cnfap,
              rowelecc,
              viewpoolxrace
            )}
          </TabPane>
        )}
        {isHabilitar[2] && (
          <TabPane tab="Pool 4 / Lotto Hipico" key="3">
            {this.despligueTandas1(
              hoserInfo,
              NameHipo,
              hora,
              actRace,
              markpool,
              rowelecc,
              viewpoolxrace,
              tandaxRace
            )}
          </TabPane>
        )}
        {isHabilitar[3] && (
          <TabPane tab="Polla Hipica" key="4">
            {this.desplieguePolla(idx)}
          </TabPane>
        )}
      </Tabs>
    ) : (
      ""
    );
  };
  haldlenNamePolla = ({target:{value}})=>{
    // nombredePolla
    this.setState({nombredePolla:value})
    // store.dispatch({ type: "IN_data_namePolla", nombredePolla: value });
  }
  desplieguePolla = (posh) => {
    var { activoView,markPolla, confiPolla, viewpoolxrace, cnfPool,receptor } = this.state;
    
    if (confiPolla.length !== 0) {
      if (confiPolla[posh] !== -1) {
        const {IDCN} = activoView
        // console.log('posh',posh,activoView,cnfPool)
        var fmultp = 0;
        var idx = _.findIndex(cnfPool, function (o) {
          return o.tid === "polla" && o.id === IDCN ;
        });
        if (idx !== -1) fmultp = _.toNumber(cnfPool[idx].factor);

        var { Ejemplares, CantCarr } = confiPolla[posh]; ////=>tengo que indicar que IDCN voy a buscar por ahora es un solo hipodromo
      
        var carrAdd = [],
          ejemRow = [],
          validados = [];
        // carrAdd.push('');
        var inicializar = false;
        if (markPolla.length === 0) inicializar = true;
        for (let i = 1; i <= CantCarr; i++) {
          carrAdd.push(i);
          if (inicializar) markPolla.push(0);
        }
        for (let i = 1; i <= 25; i++) ejemRow.push(i);
        for (let j = 1; j <= 25; j++) {
          var linea = [];
          for (let i = 1; i <= CantCarr; i++) {
            var { cantEje, horse } = Ejemplares[i - 1];
            if (j <= cantEje) {
              try {
                if (!(typeof (horse[j - 1].status) === 'undefined')) {
                  if (horse[j - 1].status) linea.push(`${j}º`);
                  else linea.push(-1);
                } else {
                  linea.push(-1);
                }
              } catch (err) {
                linea.push(0);
              }
            } else linea.push(0);
          }
          validados.push(linea);
        }
      }
    }
    return (
      <div style={{ display: viewpoolxrace["polla"] ? "block" : "none" }}>
        {confiPolla.length === 0 ? (
          <Col>
            <Spinnerwait />
          </Col>
        ) : confiPolla[posh] !== -1 ? (
          <div className="pollaBLNK">
            <Row>
              <Col xl={5} sm={5} xs={3}>
                <div className="pollaTitle">
                  <span className="pollaTexto">
                    {" "}
                    {(this.props.tthis.responsibeWith(850) || "") +
                      this.props.tthis.state.moneda}
                    .{formatValues({value:fmultp})}
                  </span>
                </div>
              </Col>

              <Col xl={19} sm={14} xs={11}>
                <Row type="flex" justify="end" className="pollaButton">
                  <ButtonGroup>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.props.onChangeMenuPrin("Pollas Jugadas", 10)
                      }
                    >
                      Pollas Jugadas
                    </Button>
                    <Button
                      className="pollaMasJugada"
                      onClick={() =>
                        this.props.onChangeMenuPrin("Polla Mas Jugada", 11)
                      }
                    >
                      {this.props.tthis.responsibeWith(850) ? (
                        <Icon type="notification" />
                      ) : (
                        "Mas Jugada"
                      )}
                    </Button>
                    <Button
                      className="pollaMasRecomendada"
                      onClick={() =>
                        this.props.onChangeMenuPrin(
                          "Polla Recomendada por la Banca",
                          12
                        )
                      }
                    >
                      {this.props.tthis.responsibeWith(850) ? (
                        <Icon type="like" />
                      ) : (
                        "Recomendada"
                      )}
                    </Button>
                  </ButtonGroup>
                </Row>
              </Col>
            </Row>
            {receptor ?
            <Row>
              <Col>
                <div style={{ marginBottom: 16 }}>
                  <Input
                    onChange={this.haldlenNamePolla} 
                    ref={this.refInput}
                    autoFocus={true} 
                    className="input-receptor"
                    addonBefore="CLIENTE:"   />
                </div>
              </Col>
            </Row>:<div></div>
            }
            <Row>
              {/* <Col span={1}>
                            <div className="pollaSpaceH"></div>
                        </Col> */}
              <Col xl={24} sm={18}>
                {carrAdd.map(
                  (value, index) => (
                    // <Col key={index.toString()} offset={1} sm={1}>
                    <div key={index.toString()} className="pollaRace">
                      {value}
                    </div>
                  )
                  // </Col>
                )}
              </Col>
            </Row>
            {ejemRow.map((value, index) => (
              <Row key={index.toString()}>
                {/* <Col span={1}>
                                    <div className="pollaSpace" >
                                        {value===8&&"Ejemplares"}
                                    </div>
                                </Col>  */}
                <Col xl={24} sm={18}>
                  {validados[index].map((value, index1) =>
                    value !== 0 ? (
                      <Tooltip
                        key={"val" + index.toString() + "-" + index1.toString()}
                        placement="top"
                        title={`No.${index + 1}-${
                          Ejemplares[index1].horse[index]?Ejemplares[index1].horse[index].Nombre:'N/D'
                        }`}
                      >
                        <div
                          className={
                            value !== -1
                              ? markPolla[index1] === index + 1
                                ? "pollaMarck_Hab_selec"
                                : "pollaMarck_Hab"
                              : "pollaMarck_Ret"
                          }
                          onClick={() =>
                            value !== -1
                              ? this.onClickPolla(
                                  index1 + 1,
                                  index + 1,
                                  CantCarr
                                )
                              : message.warning("Ejemplar Retirado")
                          }
                        >
                          {value !== -1 ? value : "R"}
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        key={
                          "inval" + index.toString() + "-" + index1.toString()
                        }
                        className="pollaMarck_Des"
                      ></div>
                    )
                  )}
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <Row className="bckResulNoHay">
            <Result
              status="404"
              title="No se ha configurado esta jugada"
              subTitle="Pero pronto te ofreceremos esta informacion!."
            />
          </Row>
        )}
      </div>
    );
  };
  onStateGnlRender = async () => {
    var { markpool, viewpoolxrace } = this.state;
    var markPolla = store.getState().markPolla;
    var inicio = false;
    if (markpool.length === 0) {
      inicio = true;
      viewpoolxrace = {
        exacta: true,
        trifecta: true,
        superfecta: true,
        ganador: true,
        pool4: true,
        lotto: true,
        polla: true,
      };
    }
    var pool = "polla",
      celda = "";
    for (let i = 1; i <= markPolla.length; i++) {
      celda = "";
      if (markPolla[i - 1] !== 0) {
        ////   carrera - ejemplar marcado
        celda = `${i - 1}-${markPolla[i - 1]}`;

        var idx = markpool.findIndex( (o)=> {
          var exploCelda = _.split(o.celda, "-");
          return o.pool === pool && parseInt(exploCelda[0]) === (i - 1);
        });
        if (idx === -1) {
          markpool.push({
            pool,
            celda,
          });
        } else markpool[idx].celda = celda;
      }
    }

    if (inicio) {
      viewpoolxrace["pool4"] = pool === "pool4" ? true : false;
      viewpoolxrace["lotto"] = pool === "lotto" ? true : false;
      viewpoolxrace["trifecta"] = pool === "trifecta" ? true : false;
      viewpoolxrace["superfecta"] = pool === "superfecta" ? true : false;
      viewpoolxrace["exacta"] = pool === "exacta" ? true : false;
      viewpoolxrace["ganador"] = pool === "ganador" ? true : false;
      viewpoolxrace["polla"] = pool === "polla" ? true : false;
      this.props.changeviewAp(false, false, true);
    }

    this.setState({
      viewpoolxrace,
      markpool,
    });

    await this.marketTicket();

    this.setState({
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        markpool,
        this.state.poolsxrace,
        this.state.rowelecc,
        viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });

  };
  onClickPolla = (carr, ejemplar, tCarr) => {
    var { markPolla,receptor } = this.state;
    var inicializar = false;
    if (markPolla.length === 0) inicializar = true;
    for (var i = 1; i <= tCarr; i++) if (inicializar) markPolla.push(0);
    markPolla[carr - 1] = ejemplar;

    store.dispatch({ type: "IN_data_markPolla", markPolla });
    this.onStateGnlRender();
    if (receptor && this.refInput.current)
      this.refInput.current.focus()
  };
  // Modulo llamado por Mainapp (Padre) para el render desde hijos externos
  propsActualizar = () => {
    this.onStateGnlRender();
  };
  ////////////////////////////////////////////////////////////////////////////
  despligueTandas1 = (
    hoserInfo,
    NameHipo,
    hora,
    actRace,
    markpool,
    rowelecc,
    viewpoolxrace,
    tandaxRace
  ) => {
    var disableDiv = this.state.disableDiv;
    var { activoView } = this.state;
    var headamericanventa = {
      width: "850px",
      background: "#a7a7a7",
      opacity: disableDiv ? "0.6" : "1",
    };
    var cnfap = tandaxRace;
    return (
      <div style={headamericanventa}>
        <div className="tablaHeadcarrNaci">
          <div className="barraHipo">
            Carrera&nbsp;{actRace}&nbsp;{NameHipo}
          </div>

          <div key="hip05" className="barraHipoCarrSel">
            <span>{actRace}</span>
          </div>
          <div key="hip06" className="barraHipoCarrNac">
            <div className="barraHipoCarrNac_n2">
              <span className="spanbarraHipo">
                <samp className="span1barraHipo">&nbsp;Partida:</samp>
                {hora}
                <span className="spanbarraHipo">
                  <samp className="span1barraHipo">&nbsp;Distancia:</samp>
                  {hoserInfo[0].dist}{" "}
                </span>
                <span
                  className={
                    this.state.aceptatAP ? "BtnAceptaAP_none" : "BtnAceptaAP"
                  }
                >
                  <Button
                    ghost
                    block
                    className="ant-btn3 ant-btn-background-ghost3"
                    onClick={() => this.onClickAceptarAP()}
                  >
                    {" "}
                    Aceptar Apuesta{" "}
                  </Button>
                </span>{" "}
              </span>
            </div>
          </div>
        </div>
        <div style={styles.tablaApuestaHead}>
          {tituloTanda1.map((
            value,
            index //rgb(161, 161, 161)
          ) => (
            <div
              key={"hip_" + index}
              style={{
                float: "left",
                width: value.wih,
                background: value.background,
                display: "table-cell",
                height: "50px",
                verticalAlign: "middle",
                textAlign: value.textAlign,
              }}
            >
              <span
                style={{
                  width: "100%",
                  display: "block",
                  height: value.titulo2 !== "" ? "30px" : "50px",
                  padding: value.titulo2 !== "" ? "5px" : "15px",
                  paddingLeft: "0px",
                }}
              >
                {value.titulo1}
              </span>
              {value.titulo2 !== "" ? (
                <span
                  style={{ width: "100%", display: "block", height: "20px" }}
                >
                  {value.titulo2 +
                    " " +
                    (value.tanda !== "0" ? activoView[value.tanda] : "")}
                </span>
              ) : (
                ""
              )}

              <div
                key={"Pri" + index}
                className="head1tandaIndc"
                style={{ background: value.background }}
              >
                {value.tipe === 1
                  ? cnfap[0][value.campo1].map((value4, index4) => {
                      var celda = "A-" + value4;
                      return value.campo1 === "pool4" &&
                        activoView.tandap4 !== -1 ? (
                        <div
                          key={"ind_tanda_" + index4}
                          className={
                            index4 === cnfap[0][value.campo1].length - 1
                              ? "cellBoxSpTan1_cellback"
                              : "cellBoxSpTan1"
                          }
                          onClick={() =>
                            this.onClickCellExotica(value.campo1, celda)
                          }
                        >
                          <span
                            style={{
                              display: viewpoolxrace[value.campo1]
                                ? ""
                                : "none",
                            }}
                          >
                            <div className="boxCellTanda">
                              <label className="marckSelectTnd">
                                {" "}
                                {value4}{" "}
                              </label>
                            </div>
                          </span>
                        </div>
                      ) : value.campo1 === "lotto" && activoView.loto !== -1 ? (
                        <div
                          key={"ind_tanda_" + index4}
                          className={
                            index4 === cnfap[0][value.campo1].length - 1
                              ? "cellBoxSpTan1_cellback"
                              : "cellBoxSpTan1"
                          }
                          onClick={() =>
                            this.onClickCellExotica(value.campo1, celda)
                          }
                        >
                          <span
                            style={{
                              display: viewpoolxrace[value.campo1]
                                ? ""
                                : "none",
                            }}
                          >
                            <div className="boxCellTanda">
                              <label className="marckSelectTnd">
                                {" "}
                                {value4}{" "}
                              </label>
                            </div>
                          </span>
                        </div>
                      ) : (
                        ""
                      );
                    })
                  : ""}
              </div>

              {hoserInfo[0].ejemplares.map((value1, index2) => {
                var x = index2;
                return (
                  <div
                    id="head1"
                    key={"W" + index2}
                    className="head1tanda"
                    style={{ background: rowelecc[index2] }}
                  >
                    {value.tipe === 0 ? (
                      <div>
                        <span
                          style={{
                            width: "100%",
                            display: "block",
                            color: "#e4e4e4",
                            padding:
                              value1[value.campo2] !== "" ? "5px" : "15px",
                            paddingLeft: "0px",
                            textAlign: value.textAlign,
                            fontSize: value.sizeCp,
                            fontWeight: "lighter",
                          }}
                        >
                          {value.campo1 === "eje" ? (
                            <Tag
                              style={{
                                color: colorLtr[
                                  _.toNumber(value1[value.campo1]) - 1
                                ],
                              }}
                              color={
                                colorWin[
                                  _.toNumber(value1[value.campo1]) - 1
                                ]
                              }
                            >
                              {value1[value.campo1]}
                            </Tag>
                          ) : (
                            value1[value.campo1]
                          )}
                        </span>
                        {value.T2Icon ? (
                          <Popover
                            title={"Informacion"}
                            content={value1[value.campo2]}
                            trigger="click"
                          >
                            <Icon type="info-circle" theme="outlined" />{" "}
                          </Popover>
                        ) : value1[value.campo2] !== "" ? (
                          <span
                            style={{
                              width: "100%",
                              display: "block",
                              color: "#bbb",
                              fontSize: "12px",
                            }}
                          >
                            {value1[value.campo2]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div
                        key={"tanda_" + index2}
                        style={{
                          width: "100%",
                          float: "left",
                          display: "table-cell",
                          background: rowelecc[index2],
                          verticalAlign: "middle",
                          borderBottom: "1px solid #757575",
                          height: "50px",
                        }}
                      >
                        {cnfap[0][value.campo1].map((value4, index4) => {
                          var celda = index2 + "-" + value4;
                          var idx = _.findIndex(markpool, function (o) {
                            return o.pool === value.campo1 && o.celda === celda;
                          });
                          return disableDiv ? (
                            <div
                              key={"ap_tanda_" + index4}
                              className={
                                index4 === cnfap[0][value.campo1].length - 1
                                  ? "cellBoxSpTan1_cellback"
                                  : "cellBoxSpTan1"
                              }
                            >
                              <span
                                style={{
                                  display: viewpoolxrace[value.campo1]
                                    ? ""
                                    : "none",
                                }}
                              >
                                <div className="boxCellTanda"></div>
                              </span>
                            </div>
                          ) : value.campo1 === "pool4" &&
                            activoView.tandap4 !== -1 ? (
                            <div
                              key={"ap_tanda_" + index4}
                              className={
                                index4 === cnfap[0][value.campo1].length - 1
                                  ? "cellBoxSpTan1_cellback"
                                  : "cellBoxSpTan1"
                              }
                              onFocus={(focus) => this.focusAp(x)}
                              onClick={() =>
                                this.onClickCellExotica(value.campo1, celda)
                              }
                            >
                              <span
                                style={{
                                  display: viewpoolxrace[value.campo1]
                                    ? ""
                                    : "none",
                                }}
                              >
                                <div id="boxCellTnd" className="boxCellTanda">
                                  <label className="marckSelectTnd">
                                    {" "}
                                    {idx !== -1 ? "X" : ""}{" "}
                                  </label>
                                </div>
                              </span>
                            </div>
                          ) : value.campo1 === "lotto" &&
                            activoView.loto !== -1 ? (
                            <div
                              key={"ap_tanda_" + index4}
                              className={
                                index4 === cnfap[0][value.campo1].length - 1
                                  ? "cellBoxSpTan1_cellback"
                                  : "cellBoxSpTan1"
                              }
                              onFocus={(focus) => this.focusAp(x)}
                              onClick={() =>
                                this.onClickCellExotica(value.campo1, celda)
                              }
                            >
                              <span
                                style={{
                                  display: viewpoolxrace[value.campo1]
                                    ? ""
                                    : "none",
                                }}
                              >
                                <div id="boxCellTnd" className="boxCellTanda">
                                  <label className="marckSelectTnd">
                                    {" "}
                                    {idx !== -1 ? "X" : ""}{" "}
                                  </label>
                                </div>
                              </span>
                            </div>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  despliegueRemate = (
    hoserInfo,
    NameHipo,
    hora,
    actRace,
    markpool,
    cnfap,
    rowelecc,
    viewpoolxrace
  ) => {
    var { disableDiv } = this.state;
    var { remateIni } = this.state;
    var { remateIniCnf } = this.state;
    var headamericanventa = {
      width: "850px",
      background: "#a7a7a7",
      opacity: disableDiv ? "0.6" : "1",
    };

    return hoserInfo.length !== 0 && remateIni.length !== 0 ? (
      <div style={headamericanventa}>
        <div className="tablaHeadcarrNaci">
          <div className="barraHipo">
            Carrera&nbsp;{actRace}&nbsp;{NameHipo}
          </div>

          <div key="hip05" className="barraHipoCarrSel">
            <span>{actRace}</span>
          </div>
          <div key="hip06" className="barraHipoCarrNac">
            <div className="spanbarraHipoNac">
              <span className="spanbarraHipoNac_1">
                <samp className="span1barraHipoNac">&nbsp;Partida:</samp>
                {hora}{" "}
              </span>
              <span className="spanbarraHipoNac_2">
                <samp className="span1barraHipoNac">&nbsp;Distancia:</samp>
                {hoserInfo[0].dist}{" "}
              </span>
            </div>
            <div className="spanbarraHipoNac_3">
              <samp className="span1barraHipoNac">&nbsp;Premio:</samp>
              {remateIniCnf.premio +
                "+" +
                remateIniCnf.mas +
                "=" +
                (remateIniCnf.mas + remateIniCnf.premio)}
            </div>
          </div>
        </div>
        <div style={styles.tablaApuestaHead}>
          {tituloRemate.map((
            value,
            index //rgb(161, 161, 161)
          ) => (
            <div
              key={"hip_" + index}
              style={{
                float: "left",
                width: value.wih,
                background: value.background,
                display: "table-cell",
                height: "50px",
                verticalAlign: "middle",
                textAlign: value.textAlign,
              }}
            >
              <span
                style={{
                  width: "100%",
                  display: "block",
                  height: value.titulo2 !== "" ? "30px" : "50px",
                  padding: value.titulo2 !== "" ? "5px" : "15px",
                  paddingLeft: "0px",
                }}
              >
                {value.titulo1}
              </span>
              {value.titulo2 !== "" ? (
                <span
                  style={{ width: "100%", display: "block", height: "20px" }}
                >
                  {value.titulo2}
                </span>
              ) : (
                ""
              )}

              {hoserInfo[0].ejemplares.map((value1, index2) => {
                var x = index2,
                  y = value.pos;
                return (
                  <div
                    key={"W" + index2}
                    style={{
                      width: "100%",
                      float: "left",
                      display: "table-cell",
                      background: rowelecc[index2],
                      verticalAlign: "middle",
                      borderBottom: "1px solid #757575",
                      height: "50px",
                    }}
                  >
                    {value.inp ? (
                      <div>
                        <span
                          style={{
                            width: "100%",
                            display: viewpoolxrace["ganador"]
                              ? "block"
                              : "none",
                            color: "#e4e4e4",
                            padding:
                              value1[value.campo2] !== "" ? "5px" : "15px",
                            paddingLeft: "0px",
                            textAlign: value.textAlign,
                            fontSize: value.sizeCp,
                            fontWeight: "lighter",
                          }}
                        >
                          <Input
                            value={this.state.datoRmt[x][0]}
                            onChange={(e) => this.onChanApRmt(e, x, y)}
                            type="Number"
                            maxLength="10"
                            ref={(ref) => this.onRefRmt(ref, x, y)}
                            onPressEnter={() => this.onPressEnterInputRmt(x, y)}
                            onFocus={() => this.focusApRmt(x)}
                            onBlur={() => this.onBlurApRmt(x)}
                            disabled={disableDiv}
                          />
                        </span>
                      </div>
                    ) : value.tipe === 0 ? (
                      <div>
                        <span
                          style={{
                            width: "100%",
                            display: "block",
                            color: "#e4e4e4",
                            padding:
                              value1[value.campo2] !== "" ? "5px" : "15px",
                            paddingLeft: "0px",
                            textAlign: value.textAlign,
                            fontSize: value.sizeCp,
                            fontWeight: "lighter",
                          }}
                        >
                          {value.campo1 === "eje" ? (
                            <Tag
                              style={{
                                color: colorLtr[
                                  _.toNumber(value1[value.campo1]) - 1
                                ],
                              }}
                              color={
                                colorWin[
                                  _.toNumber(value1[value.campo1]) - 1
                                ]
                              }
                            >
                              {value1[value.campo1]}
                            </Tag>
                          ) : (
                            value1[value.campo1]
                          )}
                        </span>
                        {value.T2Icon ? (
                          <Popover
                            title={"Informacion"}
                            content={value1[value.campo2]}
                            trigger="click"
                          >
                            <Icon type="info-circle" theme="outlined" />{" "}
                          </Popover>
                        ) : value1[value.campo2] !== "" ? (
                          <span
                            style={{
                              width: "100%",
                              display: "block",
                              color: "#bbb",
                              fontSize: "12px",
                            }}
                          >
                            {value1[value.campo2]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : !_.isUndefined(remateIni[index2].bckgrm) ? (
                      <div>
                        <span
                          style={{
                            width: "100%",
                            display: "block",
                            color: remateIni[index2].bckgrm,
                            padding:
                              value1[value.campo2] !== "" ? "5px" : "15px",
                            paddingLeft: "0px",
                            textAlign: value.textAlign,
                            fontSize: value.sizeCp,
                            fontWeight: "lighter",
                          }}
                        >
                          {value.campo1 === "eje" ? (
                            <Tag
                              style={{
                                color: colorLtr[
                                  _.toNumber(value1[value.campo1]) - 1
                                ],
                              }}
                              color={
                                colorWin[
                                  _.toNumber(value1[value.campo1]) - 1
                                ]
                              }
                            >
                              {value1[value.campo1]}
                            </Tag>
                          ) : (
                            remateIni[index2][value.campo1]
                          )}
                        </span>
                        {value.T2Icon ? (
                          <Popover
                            title={"Informacion"}
                            content={value1[value.campo2]}
                            trigger="click"
                          >
                            <Icon type="info-circle" theme="outlined" />{" "}
                          </Popover>
                        ) : value1[value.campo2] !== "" ? (
                          <span
                            style={{
                              width: "100%",
                              display: "block",
                              color: "#bbb",
                              fontSize: "12px",
                            }}
                          >
                            {value1[value.campo2]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    ) : (
      ""
    );
  };

  iClearDatosAme = () => {
    this.setState({ disableDiv: true, remateIniCnf: [], remateIni: [] });
    this.iActuaLisNac();
    this.iActuaLisNacRmt(1);
  };
  ///// Para GANADORES proceso de ticket ///////
  viewTicketNac = () => {
    var lineal = [];
    var virtualTicket2 = [];
    var n = 0;
    var total = 0;
    var ejemp;
    var totalTicket = 0;
    var { impRef } = this.state;
    var { activoView } = this.state;
    var { dato } = this.state;
    var { hoserInfo } = this.state;
    var apTicket = [];
    var linea1, linea2, linea3;
    var carr = activoView.carr;
    var IDCN = activoView.IDCN;
    var w = 0,
      p = 0,
      s = 0;
    let idx = _.findIndex(hoserInfo, function (o) {
      return o.carr === carr;
    });
    var ejemHip = hoserInfo[idx].ejemplares;
    for (let i = 0; i <= dato.length - 1; i++) {
      lineal = [];
      linea1 = "";
      linea2 = "";
      linea3 = "";
     
      w = 0;
      p = 0;
      s = 0;
      if (dato[i][0] !== "") {
        linea1 = ejemHip[i].texto;
        linea2 = this.state.NameHipo + " " + carr + "C";
        ejemp = ejemHip[i].eje;
        if (dato[i][0] !== "" && dato[i][0] !== "0") {
          linea3 = "Gan " + dato[i][0];
          w = _.toNumber(dato[i][0]);
        }
        total = _.toNumber(dato[i][0]);

        if (total !== 0) {
          lineal.push({
            lineA: linea1,
            lineB: linea2,
            lineC: linea3,
            colum: ejemp,
            id: n,
          });
          virtualTicket2.push(lineal);
          totalTicket += total;
        }
        let idx = _.findIndex(impRef, function (o) {
          return o.eje === i && o.pos === 0;
        });
        impRef[idx].ref.state.value = "";

        apTicket.push({
          IDCN: IDCN,
          carr: carr,
          eje: parseInt(ejemp),
          w: w,
          p: p,
          s: s,
          ln: n,
          tipo: 1,
        });
      }
      n++;
    }
    this.props.virtualTicketNac(virtualTicket2, apTicket, totalTicket, 0);
    this.setState({
      aceptatAP: true,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        this.state.markpool,
        this.state.poolsxrace,
        this.state.rowelecc,
        this.state.viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };

  /////////////////////////////////////////////////////////////////////////////////
  onClickCellExotica = async (pool, celda) => {
    var { markpool } = this.state;
    var { viewpoolxrace } = this.state;
    var inicio = false;
    var tipoC = _.split(celda, "-");
    if (tipoC[0] === "A") {
      var { hoserInfo } = this.state;
      var cnat = hoserInfo[0].ejemplares.length;
      for (let i = 0; i <= cnat - 1; i++) {
        let iCelda = i + "-" + tipoC[1];
        let idx = _.findIndex(markpool, function (o) {
          return o.pool === pool && o.celda === iCelda;
        });
        if (idx !== -1) {
          _.pullAt(markpool, idx);
          if (markpool.length === 0) {
            inicio = true;
            viewpoolxrace = {
              exacta: true,
              trifecta: true,
              superfecta: true,
              ganador: true,
              pool4: true,
              lotto: true,
              polla: true,
            };
          }
        } else {
          markpool.push({
            pool,
            celda: iCelda,
          });
        }
      }
    } else {
      var idx = _.findIndex(markpool, function (o) {
        return o.pool === pool;
      });
      if (idx === -1) {
        viewpoolxrace = {
          exacta: true,
          trifecta: true,
          superfecta: true,
          ganador: true,
          pool4: true,
          lotto: true,
          polla: true,
        };
        markpool = [];
        markpool.push({
          pool,
          celda: celda,
        });
      } else {
        idx = _.findIndex(markpool, function (o) {
          return o.pool === pool && o.celda === celda;
        });
        if (idx !== -1) {
          _.pullAt(markpool, idx);
          if (markpool.length === 0) {
            inicio = true;
            viewpoolxrace = {
              exacta: true,
              trifecta: true,
              superfecta: true,
              ganador: true,
              pool4: true,
              lotto: true,
              polla: true,
            };
          }
        } else {
          markpool.push({
            pool,
            celda: celda,
          });
        }
      }
    }

    if (!inicio) {
      viewpoolxrace["pool4"] = pool === "pool4" ? true : false;
      viewpoolxrace["lotto"] = pool === "lotto" ? true : false;
      viewpoolxrace["trifecta"] = pool === "trifecta" ? true : false;
      viewpoolxrace["superfecta"] = pool === "superfecta" ? true : false;
      viewpoolxrace["exacta"] = pool === "exacta" ? true : false;
      viewpoolxrace["ganador"] = pool === "ganador" ? true : false;
      viewpoolxrace["polla"] = pool === "polla" ? true : false;
      this.props.changeviewAp(true, false, true);
    }
    this.setState({
      viewpoolxrace,
      markpool,
    });
    //  if (pool!=='pool4' && pool!=='lotto')
    await this.marketTicket();
    this.setState({
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        markpool,
        this.state.poolsxrace,
        this.state.rowelecc,
        viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };

  marketTicket = () => {
    var { markpool } = this.state;
    var { hoserInfo } = this.state;
    var { activoView } = this.state;
    var { cnfPool } = this.state;
    var virtualTicket2 = [];
    var p1 = [],
      p2 = [],
      p3 = [],
      p4 = [],
      p5 = [],
      p6 = [],
      p7 = [],
      p8 = [],
      p9 = [];
    var pool = [];
    var carr = activoView.carr;
    var IDCN = activoView.IDCN;
    var tanda = activoView.tandap4;
    var idx = _.findIndex(hoserInfo, function (o) {
      return o.carr === carr;
    });
    var ejemHip = hoserInfo[idx].ejemplares;
    if (markpool.length !== 0) {
      var verpool = markpool[0].pool;
      var linea = [],
        mul = 0,
        lineal = [];
      var linea1, linea2, linea3;
      if (verpool !== "polla") {
        for (let j = 0; j <= markpool.length - 1; j++) {
          let nock = _.split(markpool[j].celda, "-");
          let idx = _.findIndex(linea, function (o) {
            return (
              o.eje === _.toNumber(nock[0]) + 1 && o.poss === _.toNumber(nock[1])
            );
          });
          if (idx === -1)
            linea.push({
              eje: _.toNumber(nock[0]) + 1,
              text: ejemHip[_.toNumber(nock[0])].texto,
              poss: _.toNumber(nock[1]),
            });

       
          switch (_.toNumber(nock[1])) {
            case 1:
              p1.push(_.toNumber(nock[0]) + 1);
              break;
            case 2:
              p2.push(_.toNumber(nock[0]) + 1);
              break;
            case 3:
              p3.push(_.toNumber(nock[0]) + 1);
              break;
            case 4:
              p4.push(_.toNumber(nock[0]) + 1);
              break;
            case 5:
              p5.push(_.toNumber(nock[0]) + 1);
              break;
            case 6:
              p6.push(_.toNumber(nock[0]) + 1);
              break;
            case 7:
              p7.push(_.toNumber(nock[0]) + 1);
              break;
            case 8:
              p8.push(_.toNumber(nock[0]) + 1);
              break;
            default:
              p9.push(_.toNumber(nock[0]) + 1);
              
          }
        }
        let cal
        switch (verpool) {
          case "trifecta":
          case "superfecta":
          case "exacta":
            cal = funcext.calculosdePool(p1, p2, p3, p4, verpool);
            break;
          default:
            var { tandaxRace } = this.state;
            var cantloto = tandaxRace[0].lotto;
            var cantl = cantloto.length;
            cal = funcext.calculosdePool2(
              p1,
              p2,
              p3,
              p4,
              p5,
              p6,
              p7,
              p8,
              p9,
              verpool,
              cantl
            );
        }
        mul = cal.mul;
        var subtt = cal.subtt;
      } else {
        var { confiPolla,posh } = this.state;
        var { Ejemplares, CantCarr } = confiPolla[posh];
        mul = 1 * markpool.length === CantCarr ? 1 : 0;
        subtt = markpool.length;
        for (var j = 0; j <= markpool.length - 1; j++) {
          ////   carrera - ejemplar marcado
          var nock = _.split(markpool[j].celda, "-");

          var { horse } = Ejemplares[parseInt(nock[0])];

          linea.push({
            eje: _.toNumber(nock[1]),
            text: horse[_.toNumber(nock[1]) - 1].Nombre,
            poss: _.toNumber(nock[0]) + 1,
          });
        }
      }
      if (mul > 0) {
        var fmultp = 0;
        var tipo = 0;
        let idx = _.findIndex(cnfPool, function (o) {
          return o.tid === verpool && o.id === IDCN
        });
        if (idx !== -1) {
          fmultp = _.toNumber(cnfPool[idx].factor);
          tipo = cnfPool[idx].id + 1;
        }
        var valor = mul * fmultp;
        linea = _.orderBy(linea, ["poss"], ["asc"]);
        linea1 = "";
        linea2 = "";
        linea3 = "";
        switch (verpool) {
          case "polla":
            for (let j = 0; j <= linea.length - 1; j++) {
              linea1 +=
                linea[j].poss +
                "-" +
                linea[j].text +
                "(" +
                linea[j].eje +
                ")/n"; //_.truncate(linea[j].text, {'length': 10})
              pool.push({ pos: linea[j].poss, eje: linea[j].eje });
            }
            tipo = 8;
            break;
          case "trifecta":
          case "superfecta":
          case "exacta":
            for (let j = 0; j <= linea.length - 1; j++) {
              linea1 +=
                linea[j].poss +
                "-" +
                linea[j].text +
                "(" +
                linea[j].eje +
                ")/n"; //_.truncate(linea[j].text, {'length': 10})
              pool.push({ pos: linea[j].poss, eje: linea[j].eje });
            }
            linea2 =this.state.NameHipo + " " + carr + "C " + _.capitalize(verpool);
            break;
          case "lotto":
            tanda = carr;
            /* falls through */
          case "pool4":
            var poss = 0;
            var lne = [];
            for (let j = 0; j <= linea.length - 1; j++) {
              if (poss !== linea[j].poss) {
                if (poss !== 0) {
                  if (ejemHip.length === lne.length)
                    linea1 += poss + "). TODOS x" + lne.length + "/n";
                  else
                    linea1 +=
                      poss +
                      "). " +
                      _.join(lne, "-") +
                      " x" +
                      lne.length +
                      "/n";

                  pool.push({ pos: poss, eje: _.join(lne, "-") });
                }
                poss = linea[j].poss;
                lne = [];
              }
              lne.push(linea[j].eje);
            }
            if (ejemHip.length === lne.length)
              linea1 += poss + "). TODOS x" + lne.length + "/n";
            else
              linea1 +=
                poss + "). " + _.join(lne, "-") + " x" + lne.length + "/n";

            pool.push({ pos: poss, eje: _.join(lne, "-") });

            linea2 =
              this.state.NameHipo +
              " Tanda:" +
              tanda +
              " " +
              _.capitalize(verpool);
              break;
              default:
    
        }
        if (verpool === "polla")
          linea3 = `${subtt}=${this.props.tthis.state.moneda}${valor} Precio`;
        else linea3 = _.join(subtt, "x") + "=" + valor + " Precio ";
        lineal.push({
          lineA: linea1,
          lineB: linea2,
          lineC: linea3,
          colum: "",
          id: 0,
        }); //_.capitalize(verpool)

        virtualTicket2.push(lineal);
      }
      var tvalor = 0;
      var ap = 0;
      let apuestatk
      switch (verpool) {
        case "polla":
          apuestatk = [{ IDCN, carr, pool, fm: valor, tipo }];
          ap = valor;
          break;
        case "trifecta":
        case "superfecta":
        case "exacta":
           apuestatk = [{ IDCN, carr, pool, fm: valor, tipo }];
          break;
        // case "pool4":
        // case "lotto":
        default:
          apuestatk = [{ IDCN, carr: tanda, pool, fm: valor, tipo }];
          tvalor = valor;
          ap = 1;
        
      }
      this.props.virtualTicketNac(virtualTicket2, apuestatk, ap, tvalor);
      this.setState({
        iBodyNacionales: this.despligueHipo(
          this.state.hoserInfo,
          this.state.NameHipo,
          this.state.hora,
          this.state.actRace,
          this.state.markpool,
          this.state.poolsxrace,
          this.state.rowelecc,
          this.state.viewpoolxrace,
          this.state.tandaxRace,this.state.posh
        ),
      });
    }
  };
  Salir = () => {
    this.props.tthis.Salir();
  };
  setChgBlock = (stateBtn, stateAp) => {
    this.props.tthis.setChgBlock(stateBtn, stateAp);
  };
  iRegistroOferta = (oferta, jnd) => {
    var { activoView } = this.state;
    var IDCN = activoView.IDCN;
    var carr = activoView.carr;

    var recordnew = {
      jng: _.join(jnd, "|"),
      iu: this.props.tthis.state.id,
      ap: oferta,
      ip: ip.address(),
      carr: carr,
      idcn: IDCN,
      tipo: 4,
    };

    funcext.mutationRegistrarApuestaFn(
      recordnew,
      4,
      this,
      this.props.tthis.state.id
    ); /// Graba la Transsaccion
  };

  initCheckTk = (id) => {
    var { dato, viewpoolxrace, markPolla } = this.state;
    var markpool = [];
    
    dato.forEach((value, index) => {
      if (id === -1) {
        value[0] = "";
      } else {
        if (id === index) value[0] = "";
      }
    });

    viewpoolxrace["pool4"] = true;
    viewpoolxrace["lotto"] = true;
    viewpoolxrace["trifecta"] = true;
    viewpoolxrace["superfecta"] = true;
    viewpoolxrace["exacta"] = true;
    viewpoolxrace["ganador"] = true;
    viewpoolxrace["polla"] = true;

    markPolla.splice(0, markPolla.length);

    this.props.changeviewAp(false, true, true);
    this.setState({
      nombredePolla:'',
      dato,
      viewpoolxrace,
      markpool: [],
      markPolla,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        markpool,
        this.state.poolsxrace,
        this.state.rowelecc,
        viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
    this.onfocusOne();

    if (this.refInput.current){
      this.refInput.current.select()
    }
  };
  onChanAp = (e, x, y) => {
    var { viewpoolxrace } = this.state;
    var dato = this.state.dato;
    var p;
    var ejem = dato[x][1];
    var buscado = [];

    for (var i = 0; i <= dato.length - 1; i++) {
      if (dato[i][1] === ejem && i !== x)
        if (dato[i][0] !== "") buscado.push(i);
    }

    for (let i = 0; i <= buscado.length - 1; i++) {
      p = buscado[i];
      dato[x][0] = dato[p][0];
      dato[p][0] = "";
    }
    viewpoolxrace["trifecta"] = false;
    viewpoolxrace["superfecta"] = false;
    viewpoolxrace["exacta"] = false;
    viewpoolxrace["ganador"] = true;

    dato[x][y] = e.target.value;

    this.setState({
      dato,
      aceptatAP: false,
      viewpoolxrace,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        this.state.markpool,
        this.state.poolsxrace,
        this.state.rowelecc,
        viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };

  onChanApRmt = (e, x, y) => {
    var { datoRmt } = this.state;
    var p;
    var ejem = datoRmt[x][1];
    var buscado = [];

    for (var i = 0; i <= datoRmt.length - 1; i++) {
      if (datoRmt[i][1] === ejem && i !== x)
        if (datoRmt[i][0] !== "") buscado.push(i);
    }

    for (let i = 0; i <= buscado.length - 1; i++) {
      p = buscado[i];
      datoRmt[x][0] = datoRmt[p][0];
      datoRmt[p][0] = "";
    }

    datoRmt[x][y] = e.target.value;

    this.setState({
      datoRmt,
      aceptatAP: false,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        this.state.markpool,
        this.state.poolsxrace,
        this.state.rowelecc,
        this.state.viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };

  onPressEnterInput = (x, y) => {
    var by = y + 1;
    if (by === 1) {
      by = 0;
      var { activoView } = this.state;
      if (x === activoView.ce - 1) x = 0;
      else x++;

      this.viewTicketNac();
    }
    var impRef = this.state.impRef;
    var idx = _.findIndex(impRef, function (o) {
      return o.eje === x && o.pos === by;
    });
    var iRef = impRef[idx].ref;
    iRef.focus();
  };
  onPressEnterInputRmt = (x, y) => {
    var { datoRmt } = this.state;
    var { remateIni } = this.state;
    var { remateIniCnf } = this.state;
    var by;
    var miOferta = _.toNumber(datoRmt[x][0]);
    var iOferta = remateIni[x].precio * (remateIniCnf.porcepremio * 0.01 + 1);
    if (miOferta >= iOferta) {
       by = y + 1;
      if (by === 1) {
        by = 0;
        var { activoView } = this.state;
        if (x === activoView.ce - 1) x = 0;
        else x++;

        //    this.viewTicketNac();
      }
      var jnd = [];
      jnd.push(x + "-" + miOferta);
      this.iRegistroOferta(miOferta, jnd);
    } else {
      by = y;
      notification.error({
        message: "Error tu Oferta",
        description:
          "Esta Oferta realizada esta por debajo de la permitida, debe ser igual o mayor a la que se muestra en la oferta!",
      });
    }
    var { impRefRmt } = this.state;
    var idx = _.findIndex(impRefRmt, function (o) {
      return o.eje === x && o.pos === by;
    });
    var iRef = impRefRmt[idx].ref;
    iRef.focus();
    iRef.select();
  };
  focusAp = (eje) => {
    var { rowelecc } = this.state;
    rowelecc[eje] = "rgb(161, 161, 161)";
    this.setState({
      rowelecc,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        this.state.markpool,
        this.state.poolsxrace,
        rowelecc,
        this.state.viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };
  focusApRmt = (eje) => {
    var { rowelecc } = this.state;
    var { remateIni } = this.state;
    var { remateIniCnf } = this.state;
    var { datoRmt } = this.state;
    var iOferta = remateIni[eje].precio * (remateIniCnf.porcepremio * 0.01 + 1);
    datoRmt[eje][0] = iOferta;
    rowelecc[eje] = "rgb(161, 161, 161)";

    var { impRefRmt } = this.state;
    var idx = _.findIndex(impRefRmt, function (o) {
      return o.eje === eje;
    });
    var iRef = impRefRmt[idx].ref;
    iRef.select();

    this.setState({
      rowelecc,
      datoRmt,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        this.state.markpool,
        this.state.poolsxrace,
        rowelecc,
        this.state.viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };
  onBlurAp = (eje) => {
    var { rowelecc } = this.state;
    rowelecc[eje] = "#666";
    this.setState({
      rowelecc,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        this.state.markpool,
        this.state.poolsxrace,
        rowelecc,
        this.state.viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };
  onBlurApRmt = (eje) => {
    var { rowelecc } = this.state;
    var { datoRmt } = this.state;
    for (var i = 0; i <= datoRmt.length - 1; i++) datoRmt[i][0] = "";

    rowelecc[eje] = "#666";
    this.setState({
      rowelecc,
      datoRmt,
      iBodyNacionales: this.despligueHipo(
        this.state.hoserInfo,
        this.state.NameHipo,
        this.state.hora,
        this.state.actRace,
        this.state.markpool,
        this.state.poolsxrace,
        rowelecc,
        this.state.viewpoolxrace,
        this.state.tandaxRace,this.state.posh
      ),
    });
  };
  onfocusOne = () => {

    const {impRef,receptor} = this.state;
    var idx = _.findIndex(impRef, function (o) {
      return o.eje === 0;
    });
    if (idx !== -1) {
      var iRef = impRef[0].ref;
      iRef.focus();
    }
    if (receptor && this.refInput.current )
      this.refInput.current.focus()
  };
  onClickAceptarAP = () => {
    this.setState({ aceptatAP: true });
    this.viewTicketNac();
    this.onfocusOne();
  };
  setDataPOOL = (cnfPool) => {
    this.setState({ cnfPool });
  };

  setDataRemate = (remateIniG) => {
    var remateIni2 = remateIniG.remate;
    var remateIniCnf = remateIniG.premio;
    if (!_.isNull(remateIni2) && !_.isUndefined(remateIni2)) {
      var { remateIni } = this.state;
      var remateIniTMP = [];
      if (remateIni.length !== 0) {
        for (var row in remateIni) {
          if (remateIni[row].precio !== remateIni2[row].precio) {
            remateIniTMP.push({
              precio: remateIni2[row].precio,
              remate: remateIni2[row].remate,
              bckgrm: "#d1d632",
            });
          } else {
            remateIniTMP.push({
              precio: remateIni2[row].precio,
              remate: remateIni2[row].remate,
              bckgrm: "rgb(228, 228, 228)",
            });
          }
        }
      } else {
        for (let row in remateIni2) {
          remateIniTMP.push({
            precio: remateIni2[row].precio,
            remate: remateIni2[row].remate,
            bckgrm: "rgb(228, 228, 228)",
          });
        }
      }
      this.setState({ remateIni: remateIniTMP, remateIniCnf });
    }
  };
  setDatalist = (viewData) => {
    var hipodromos = [];
    viewData.forEach((value) => {
      if (value.status === 1)
        hipodromos.push({
          id: value.idh,
          text: value.texto,
          sta: value.status === 1,
        });
    });
    this.setState({ hipodromos, viewData, disableDiv: false });
    // if (hipodromos.length===1)
    //     this.setState({timeOut:61})
  };
  iActuaLisNac = () => {
    // var { habiNacionales } = this.props.tthis.state;
    var { dia } = this.state;
    let tipo =3;
      // !habiNacionales[0] && !habiNacionales[1] && !habiNacionales[2] && habiNacionales[3]
      //   ? 3
      //   : 2;
    this.queryviewNacionalesFn(dia,tipo);
    this.queryNacionalesPollaDTAFn(dia);
    this.querycnfpoolsN2Fn(dia, this.props.tthis.state.moneda, (value) => {
      if (value) this.iActuaLisNacRmt(0);
    });
 
  };
  iActuaLisNacRmt = async (op) => {
    if (op === 1) func.queryviewCnfRemFn(this, this.state.actRace);
    var iBodyNacionales =  this.despligueHipo(
      this.state.hoserInfo,
      this.state.NameHipo,
      this.state.hora,
      this.state.actRace,
      this.state.markpool,
      this.state.poolsxrace,
      this.state.rowelecc,
      this.state.viewpoolxrace,
      this.state.tandaxRace,this.state.posh
    );
    this.setState({ iBodyNacionales });
  };

  componentWillMount = () => {
    this.iActuaLisNac();
  };

  componentDidMount = () => {
    var { habiNacionales } = this.props.tthis.state;
    const {receptor} = this.props;
    
    var isHabilitar = [];
    if (habiNacionales.length !== 0) {
      for (let i = 0; i <= habiNacionales.length - 1; i++)
        isHabilitar.push(habiNacionales[i]);
      this.setState({ isHabilitar });
    }
    var { tm } = this.state;
    /// Activar la OPCION via SUBCRIPTIONS GRAPHQL
    // tm = setInterval(() => this.iActuaLisNacRmt(1), 30000);
    
    this.setState({ tm ,receptor});

 
  };
  componentWillUnmount = () => {
    var { tm } = this.state;
    clearInterval(tm);
  };
  render() {
    var { hipodromos, iBodyNacionales, nohayInfo } = this.state;
    return (
      <Row>
        {hipodromos.length === 0 ? (
          !nohayInfo ? (
            <Col>
              <Spinnerwait />
            </Col>
          ) : (
            <Col span={18}>
              <Row className="bckResulNoHay">
                <Result
                  status="404"
                  title="No hay configuracion disponible"
                  subTitle="Lo sentimos, pero no tenemos jugadas configuradas para hoy!"
                />
              </Row>
            </Col>
          )
        ) : (
          <div>
            <Row>
              <Col span={24}>
                {/* <div style={{width: '170px',float: 'left'}} > */}
                <Menu
                  mode="horizontal"
                  theme="dark"
                  defaultSelectedKeys={[hipodromos.length === 1 ? "0" : "-1"]}
                >
                  {hipodromos.map((value, index) => (
                    <Menu.Item
                      key={index}
                      onClick={() => this.iCaptadatos(value.id)}
                    >
                      {value.sta ? (
                        <Icon type="check" theme="outlined" />
                      ) : (
                        <Icon type="close" theme="outlined" />
                      )}
                      <span>{value.text}</span>
                    </Menu.Item>
                  ))}
                </Menu>
                {/* </div> */}
              </Col>
            </Row>
            <Row type="flex" align="middle">
              <Col xl={2}></Col>
              <Col xl={20}>
                <Row type="flex" align="middle">
                  {iBodyNacionales}
                </Row>
              </Col>
              <Col xl={2}></Col>
            </Row>
          </div>
        )}
      </Row>
    );
  }
}

const styles = {
  tablaApuestaHead: {
    background: "#a7a7a7",
    color: "#444",
    position: "relative",
    marginTop: "0px",
  },
};
export default cloudnacionales;
