import React from "react";
import Icon from "antd/es/icon";
import message from "antd/es/message";
import firebase from "firebase";
import {firebaseConfig} from './conect_fb'
import "./index.scss";
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const ErrorModuleGoogle = (error) => {
  switch (error) {
    case "auth/popup-closed-by-user":
      console.log("Close by User!!");
      break;
    default:
      message.error(`Ups! Tuvimos un error ${error} `);
      break;
  }
};
const GoogleConnet = ({ text, onSummit, props = null }) => {
  const OAuthGoogle = () => {
    const oAuthGoogle = new firebase.auth.GoogleAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(oAuthGoogle)
      .then(({ user, credential }) => {
        const { displayName, email, photoURL, phoneNumber, uid } = user;
        const { operationType } = credential;
        return onSummit({
          error: false,
          displayName,
          email,
          photoURL,
          phoneNumber,
          uid,
          operationType,
          props,
        });
      })
      .catch((error) => {
        const { code } = error;
        return onSummit({ error: code });
      });
  };

  return (
    <button className="oAuth-google-main" onClick={OAuthGoogle}>
      <Icon
        type="google"
        theme="outlined"
        style={{
          marginTop: "10px",
          marginLeft: "10px",
          float: "left",
          fontSize: "30px",
          color: "white",
        }}
      />
      <div className="oAuth-google-text">{text}</div>
    </button>
  );
};
export default GoogleConnet;
