import React, { Component } from 'react';
import Tabs from 'antd/es/tabs';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import CloudameResul from './cloudnacResul';
import CloudnacResulPolla from './cloudnacResulPolla';
const { TabPane } = Tabs;
class cloudnacResumidoResul extends Component {
    render() {
        var {habiNacionales}=this.props;
        return (
            <Row>
                <Col xl={15} lg={19} className="cloud-frm-grl">
                    <Tabs tabBarStyle={{color:'#fff'}}  >
                        {habiNacionales[0]?
                        <TabPane tab="Ganadores" key="1">
                            <CloudameResul {...this.props}/>
                        </TabPane>:''}
                        {habiNacionales[3]?
                        <TabPane tab="Polla" key="2">
                            <CloudnacResulPolla {...this.props}/>
                        </TabPane>
                        :''}
                    </Tabs>
                </Col>
            </Row>
        );
    }
}

export default cloudnacResumidoResul;