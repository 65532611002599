import React, { Component } from 'react';

import Table from 'antd/es/table';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Result from 'antd/es/result';
import Tag from 'antd/es/tag';
import Row from 'antd/es/row';
import Alert from 'antd/es/alert';
import store from './store';
import {colorWin,colorLtr} from './globals.js';
import * as func from './func_nac';
import   './cloudnacionales.scss';
var _ = require('lodash');


class cloudmonitorPolla extends Component {
    tm;
    constructor(props) {
        super(props);        
        this.state = {
            IDCN_Nac:0,
            markPolla:[],recaudacion:[],
            columns:[{ title: 'Usario', dataIndex: 'usu', key: 'usu' },
            { title: 'Jugada', dataIndex: 'Jnd', key: 'Jnd',render: values => (
                <span>
               {
                    values.split('-').map((tag,index) => {
                     return(
                        <Tag key={`${index}-${tag}`} style={{color:colorLtr[_.toNumber(tag)-1]}}  color={colorWin[_.toNumber(tag)-1]}>{tag}</Tag>
                     )   
                    })
                }
                </span>
            ) },
            { title: 'Puntos', dataIndex: 'puntos', key: 'puntos',  sorter: (a, b) => a.puntos - b.puntos },
            {title: 'Accion', key: 'operation', render: (key) =>
                <Button size="small" onClick={()=>this.onMarkPolla(key)} >Copiar!</Button>
            //  <Icon type="like" theme="twoTone" style={{fontSize:"25px"}} />
            }]
            ,dataallpolla:[]
        }
        store.subscribe(() => {
            this.setState({
                markPolla:store.getState().markPolla,
                IDCN_Nac:store.getState().IDCN_Nac,
            })
        })
        this.queryviewALlPollafn=func.queryviewALlPollafn.bind(this);
    }
    actuValoresPolla=()=>{
        var {moneda}=this.props.tthis.state;
        var {IDCN_Nac,dia_nac}=store.getState();
        this.queryviewALlPollafn(IDCN_Nac,dia_nac,moneda);
    }
    onMarkPolla=(key)=>{
        var {Jnd}=key;
        var seleccionadas=_.split(Jnd,'-')
        var markPolla=[]
        for (let i=0;i<=seleccionadas.length-1;i++)
                markPolla.push(parseInt(seleccionadas[i]))
        // console.log(markPolla)
        store.dispatch({ type: 'IN_data_markPolla',markPolla });
        this.props.onChildNacionaes(1);
        this.props.tthis.hideModal();
    }
 
    componentDidUpdate= (prevProps)=>{
        if (prevProps.unixMomemt!==this.props.unixMomemt){
            this.actuValoresPolla();  
            this.tm=setInterval(()=>this.actuValoresPolla(1),30000);
        }
    }

    componentDidMount=()=>{
        this.actuValoresPolla();
        this.tm=setInterval(()=>this.actuValoresPolla(1),30000);
     }
    render() {
        var totalVenta=0,premio=0,Puntostotal=0;
        var {columns,dataallpolla,nouenFail,recaudacion}=this.state;
        var {moneda}=this.props.tthis.state;
        var {IDCN_Nac}=store.getState();
        var  idx=_.findIndex(recaudacion, function(o) { return ( o.IDCN === IDCN_Nac); });
        if (idx!==-1){
            var {porMoneda}=recaudacion[idx];
            idx=_.findIndex(porMoneda, function(o) { return ( o.moneda === moneda); });
            if (idx!==-1){
                var {totalVenta:tempTotalVenta,premio:tempPremio,Puntostotal:tempPuntostotal}=porMoneda[idx];
                totalVenta=tempTotalVenta
                premio=tempPremio
                Puntostotal=tempPuntostotal
            }
        }
        return (
            <div>
            <Row className="info-info-polla">
                <Col span={24}  className="rowCloudSelect_btn">
                    <Row  type="flex" justify="center" >
                        <Col span={20}>
                            <Alert
                            message="Informacion"
                            description={<Row>
                                <Col span={20}>
                                    <Row>
                                        <Col className="info-lbl-polla" span={8}>Ventas Totales:</Col>
                                        <Col span={6}>
                                            {new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(totalVenta)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col  className="info-lbl-polla" span={8}>Premio a Repartir:</Col>
                                        <Col span={6}>{new Intl.NumberFormat('es', { style: 'currency', currency:moneda}).format(premio)}</Col>
                                    </Row>
                                    <Row>
                                        <Col  className="info-lbl-polla" span={8}>Puntos Alcanzados:</Col>
                                        <Col span={6}>{Puntostotal}</Col>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Button  type="danger" icon="logout"  className="buttomSalirPolla" onClick={()=>{clearInterval(this.tm);this.props.tthis.hideModal()}} >
                                     Salir
                                    </Button>
                                </Col>
                            </Row>}
                            type="success"
                            showIcon
                           
                        />
                        </Col>
                        
                    </Row>
                </Col>

            </Row>
            {nouenFail?
                  <Result
                  status="404"
                  title="No hay pollas realizadas"
                  subTitle="Pero por ahora no han echo jugadas para ofrecerte esta informacion!."
                />
                :  
                <Row>
                    <Col span={24}  className="rowCloudSelect_btn">
                    <Row type="flex" justify="space-around" align="middle">
                            <Table
                            rowKey={record => record.serial}
                            className="components-table-demo-nested"
                            columns={columns}
                            dataSource={dataallpolla}
                            expandedRowRender={record => <p style={{ margin: 0 }}>Ticke No:{record.serial}, Hora:{record.hora}, </p>}
                        />
                        </Row>
                    </Col>
            </Row>
            }
          </div>
        );
    }
}

export default cloudmonitorPolla;