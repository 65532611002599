import React, { Component } from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Icon from 'antd/es/icon';
import List from 'antd/es/list';

import   './cloudwindows.scss'; 
var _ =  require('lodash');
var id_select=0;
class cloudwindows extends Component {

    listMainP=(listData,onChange)=>{
        return (
            <List
            itemLayout="vertical"
            size="small"
            locale={{ emptyText:<label style={{color:'#fff'}}> <Icon type="delete" style={{fontSize: '22px',color:'#fff'}}/>No hay Informacion</label> }} 
            dataSource={listData}
            renderItem={(item,index) => (
            <List.Item
                className={id_select===index?"listStyItem":''}
                key={item.title}
                onClick={()=>onChange(item.id,item.title,index)}
                >
                <List.Item.Meta
            title={<label className="mainLabletitle">{item.activa===1?<Icon type="check-circle" style={{ fontSize: '16px', color: 'whitesmoke' }} />:<Icon type="close-circle" style={{ fontSize: '16px', color: 'darkred' }} />}{item.title}</label>}
                description={<label className="mainLabledescrp">{item.description}</label>}
                />
            </List.Item>
            )}
            />
        )
    }
    
    render() {
        var {title              // Titulo de la Ventana
            ,icon               // Icono JSX de la Ventana al lado del Titulo (Derecho)
            ,barrIcons          // Barra de Comandos JSX 
            ,classMain          // ClassName principal de la Ventada, define tamaño y altura de la misma asi como el color de fonde de la ventana
            ,classMainList      // ClassName de la Lista de Derecha para de la ventana, si esta activada
            ,inside             // Contenido de la ventana principal, JSX
            ,listData           // Valores JSON del List que se muestra del lado derecho de la ventada
            ,onChange           // Funcion a llamar al dar click a la ventana de la lista parametros (id,titulo,index)
            ,statusList         // True: Habilita el ListData, False: Deshabilita el ListData, con false es solo una ventana con el mismo formato definido
            ,titleBkgn          // Color Backgroud del Titulo de la ventana, si no se coloca todo por default  #939696
            ,colorBkg           // Color de las Letras del titulo, si no se coloca todo por default  #000 
            ,classWindowsTxt    // ClassName del titulo Tipo de letra, tamaño, color, de formato General
            }=this.props;
        return (
            <div className={_.isUndefined(classMain)?"mainwindows":classMain} >
            <Row >
                <Col span={15} className="barrwindows_1" style={{background:_.isUndefined(titleBkgn)?"#939696":titleBkgn,color:_.isUndefined(colorBkg)?"#000":colorBkg}}> 
                       {barrIcons}
                </Col>
                <Col span={9} className="barrwindows_2" style={{background:_.isUndefined(titleBkgn)?"#939696":titleBkgn,color:_.isUndefined(colorBkg)?"#000":colorBkg}}> 
                <Row type="flex" justify="end" align="bottom">
                     <label className={_.isUndefined(classWindowsTxt)?"barrwindowstext":classWindowsTxt} style={{color:_.isUndefined(colorBkg)?"#000":colorBkg}}>{_.isUndefined(icon)?<Icon type="deployment-unit" />:icon}&nbsp;{title}</label>
                </Row>
                </Col>
            </Row>
            <Row gutter={2}>
                {statusList?
                <div>
                <Col span={9} >
                    <div className={_.isUndefined(classMainList)?"mainListwindows":classMainList}>
                       {this.listMainP(listData,onChange)}
                    </div>
                </Col>
                
                <Col span={15} >
                    <div className='mainContent'>
                        {inside}
                    </div>
                </Col>
                </div>
                :
                <Col span={24}  >
                        {inside}
                </Col>
                }
            </Row>
        </div>
        );
    }
}

export default cloudwindows;