import moment from "moment";
import { gql } from "@apollo/client";
import message from "antd/es/message";
import notification from "antd/es/notification";
import { goToAnchor } from "react-scrollable-anchor";

import client from "./conex";
// import {jti} from './globals';
import store from "./store";

import { hashGet, hashSet } from "./services/general";

const _ = require("lodash");
var nJwt = require("njwt");
var bcrypt = require("bcryptjs");
var ip = require("ip");
const encode = require("nodejs-base64-encode");

const querysportLegueScore = gql`
	query sportLegueScore($dia: String) {
		sportLegueScore(dia: $dia) {
			sport
			sub {
				key
				texto
			}
		}
	}
`;
const querysportLegue = gql`
	query sportLegue($dia: String) {
		sportLegue(dia: $dia) {
			sport
			sub {
				key
				texto
			}
		}
	}
`;
const querysportColum = gql`
	query sportColum($key: Int) {
		sportColum(key: $key) {
			tituloDiv
			apuesta {
				title
				dataIndex
				key
			}
		}
	}
`;
const queryoddsLegue = gql`
	query oddsLegue($grupo: Int, $dia: String) {
		oddsLegue(grupo: $grupo, dia: $dia) {
			idp
			partido
			key
			code
			ide
			partidos
			hora
			extra
			status
			publicado
			ap
			img
			size
		}
	}
`;
const queryGenerarPw = gql`
	query newpw($iu: String) {
		newpw(iu: $iu) {
			iu
			pw
		}
	}
`;
const mutationIU = gql`
	mutation registroUI($input: RecordUsuario) {
		registroUI(input: $input)
	}
`;
const mutationIUlitte = gql`
	mutation registroUIlitte($input: RecordUsuarioLitte) {
		registroUIlitte(input: $input)
	}
`;
const mutationIUlitte_Main = gql`
	mutation registroUIlitte_Main($input: RecordUsuarioLitte) {
		registroUIlitte_Main(input: $input)
	}
`;
const querySearchIU = gql`
	query searchIU {
		searchIU
	}
`;
const queryViewIU = gql`
	query viewIU($key: Int, $iu: Int, $like: String, $idPRV: String) {
		viewIU(key: $key, iu: $iu, like: $like, idPRV: $idPRV) {
			iu
			Usuario
			Nombre
			email
			tipo
			identif
			Moneda
			idioma
			activo
			Deporte
			Americana
			Nacionales
			Loteria
			tope
			pago
			pw
			ticket
			fn
			tc
			banco
			cb
			ntlf
			nmsg
			avatar
			cscDeporte
			cscAmericana
			cscNacionales
			cscLoteria
			modedepor
		}
	}
`;
const queryViewTopesGw = gql`
	query viewTopesG {
		viewTopesG {
			tope
			texto
			cnfD
			cnfA
			cnfN
			cnfL
		}
	}
`;
const queryViewTopesGwLot = gql`
	query viewTopesLot {
		viewTopesLot {
			cnf
			texto
			participacionPorcen
			ventasPorcentaje
			ticketanular
			maximoporlott
			topesxapuesta
		}
	}
`;
const queryViewTopesGwNac = gql`
	query viewTopesNac {
		viewTopesNac {
			cnf
			texto
			ParticipaGana
			ParticipaPierde
			PorcentajeVentas
			IDBL
			EliminarTicket
			jSONcnf
		}
	}
`;
const queryViewTopesGwAme = gql`
	query viewTopesAme {
		viewTopesAme {
			cnf
			texto
			JugadaMaximaW
			JugadaMaximaP
			JugadaMaximaS
			DividendosMaximosW
			DividendosMaximosP
			DividendosMaximosS
			PremioW
			PremioP
			PremioS
			JMExacta
			DMExacta
			JMTrifecta
			DMTrifecta
			JMSuperfecta
			DMSuperfecta
		}
	}
`;
const queryViewTopesGwDepo = gql`
	query viewTopesDepo {
		viewTopesDepo {
			cnf
			texto
			mmjpd
			mmjpp
			maxpremio
			mmdp
			mma
			cjmp
			cdpi
			Participacion1
			pVentas
			cmaxelim
			Participacion2
			pVentaspd
			Eminutos
			mxpjpd
			chp
			pdrl
		}
	}
`;
const mutationTope = gql`
	mutation registroTopeG($input: RecordTope, $eliminar: Int) {
		registroTopeG(input: $input, eliminar: $eliminar)
	}
`;
const mutationTopeDeporte = gql`
	mutation registroTopeDeporte($input: RecordTopeDeporte, $eliminar: Int) {
		registroTopeDeporte(input: $input, eliminar: $eliminar)
	}
`;
const mutationTopeAmericana = gql`
	mutation registroTopeAmericanas($input: RecordTopeAmericana, $eliminar: Int) {
		registroTopeAmericanas(input: $input, eliminar: $eliminar)
	}
`;
const mutationTopeNacionales = gql`
	mutation registroTopeNacionales(
		$input: RecordTopeNacionales
		$eliminar: Int
	) {
		registroTopeNacionales(input: $input, eliminar: $eliminar)
	}
`;
const mutationTopeLoterias = gql`
	mutation registroTopeLoteria($input: RecordTopeloteria, $eliminar: Int) {
		registroTopeLoteria(input: $input, eliminar: $eliminar)
	}
`;
const queryRecordCuentas = gql`
	query viewRecordCuentas($activo: Int, $idPRV: String) {
		viewRecordCuentas(activo: $activo, idPRV: $idPRV) {
			iu
			Usuario
			Nombre
			tipo
			Balance
			Moneda
			email
		}
	}
`;
const queryHistoryCuentas = gql`
	query viewHistoryCuentas(
		$iu: Int
		$ord: Orden
		$modo: Int
		$limited_min: Int
		$idPRV: String
	) {
		viewHistoryCuentas(
			iu: $iu
			ord: $ord
			modo: $modo
			limited_min: $limited_min
			idPRV: $idPRV
		) {
			id
			fecha
			origen
			monto
			saldo
			iu
			login
			operacion
			descripcion
			estatus
		}
	}
`;
const mutationRegistroCuentas = gql`
	mutation registroCuentas($input: RecordTranss) {
		registroCuentas(input: $input) {
			res
			text
		}
	}
`;

const queryiLoginGoogle = gql`
	query iloginGoogle($input: String) {
		iloginGoogle(input: $input) {
			res
			text
			key
		}
	}
`;
const queryiLoginAdmin = gql`
	query iloginAdmon($input: String) {
		iloginAdmon(input: $input) {
			res
			text
			key
		}
	}
`;
const mutationRegistrarApuesta = gql`
	mutation registroApuesta($input: String, $iu: Int, $tds: Int) {
		registroApuesta(input: $input, iu: $iu, tds: $tds) {
			res
			text
			keyap
			modulo
		}
	}
`;
const queryviewTickets = gql`
	query viewTicketsGn(
		$fecha: String
		$tipo1: Int
		$tipo2: Int
		$tipo3: Int
		$tipo4: Int
		$iu: Int
	) {
		sinprocesar: viewTickets(fecha: $fecha, tipo: $tipo1, iu: $iu) {
			key
			serial
			fecha
			hora
			apuesta
			isys
			isys_Desc
			cobra
			se
		}

		posibleganar: viewTickets(fecha: $fecha, tipo: $tipo2, iu: $iu) {
			key
			serial
			fecha
			hora
			apuesta
			isys
			isys_Desc
			cobra
			se
		}

		posibleganador: viewTickets(fecha: $fecha, tipo: $tipo3, iu: $iu) {
			key
			serial
			fecha
			hora
			apuesta
			isys
			isys_Desc
			cobra
			se
		}

		anulados: viewTickets(fecha: $fecha, tipo: $tipo4, iu: $iu) {
			key
			serial
			fecha
			hora
			apuesta
			isys
			isys_Desc
			cobra
			se
		}
	}
`;
const queryviewOneTicket = gql`
	query viewOneTicket($serial: Int) {
		viewOneTicket(serial: $serial) {
			serial
			fecha
			hora
			apuesta
			isys
			cobra
			se
			status
			moment
			detalle {
				partido
				hora
				equipo
				apuesta
				odd
				escrute
			}
		}
	}
`;
const queryviewOneTicketLot = gql`
	query viewOneTicketLot($serial: Int) {
		viewOneTicketLot(serial: $serial) {
			serial
			fecha
			hora
			total
			idj
			estatus
			se
			isys
			cobra
			moment
			detalle {
				ln
				numeros
				monto
				loteria
				sorteo
				escrute
			}
		}
	}
`;
const queryviewOneTicketNac = gql`
	query viewOneTicketNac($serial: Int) {
		viewOneTicketNac(serial: $serial) {
			serial
			fecha
			hora
			apuesta
			ValorR
			hipo
			carr
			IDCN
			estatus
			cobra
			se
			isys
			tipo
			moment
			detalle {
				eje
				text
				g
			}
		}
	}
`;
const queryviewOneTicketAme = gql`
	query viewOneTicketAme($serial: Int) {
		viewOneTicketAme(serial: $serial) {
			serial
			fecha
			hora
			apuesta
			ValorR
			hipo
			carr
			IDCN
			estatus
			se
			isys
			cobra
			tipo
			moment
			detalle {
				eje
				text
				g
				p
				s
			}
		}
	}
`;
const mutationanulOneTicket = gql`
	mutation anulOneTicket($serial: Int, $iu: Int, $ip: String) {
		anulOneTicket(serial: $serial, iu: $iu, ip: $ip) {
			res
			text
		}
	}
`;
const mutationanulOneTicketAme = gql`
	mutation anulOneTicketAme($serial: Int, $iu: Int, $ip: String) {
		anulOneTicketAme(serial: $serial, iu: $iu, ip: $ip) {
			res
			text
		}
	}
`;
const mutationanulOneTicketNac = gql`
	mutation anulOneTicketNac($serial: Int, $iu: Int, $ip: String) {
		anulOneTicketNac(serial: $serial, iu: $iu, ip: $ip) {
			res
			text
		}
	}
`;
const mutationanulOneTicketLot = gql`
	mutation anulOneTicketLoteria($serial: Int, $iu: Int, $ip: String) {
		anulOneTicketLoteria(serial: $serial, iu: $iu, ip: $ip) {
			res
			text
		}
	}
`;
const queryviewBancos = gql`
	query viewBancos {
		viewBancos {
			key
			Text
		}
	}
`;
const mutationanulchgStatusCuentas = gql`
	mutation chgStatusCuentas($idtss: Int, $Status: Int, $AddDesc: String) {
		chgStatusCuentas(idtss: $idtss, Status: $Status, AddDesc: $AddDesc) {
			res
			text
		}
	}
`;
const queryscoreporIPD = gql`
	query scoreporIPD($idp: Int) {
		scoreporIPD(idp: $idp) {
			Idp
			Eq1
			Eq2
			listScore {
				IDCNGE
				text
				resultado
			}
		}
	}
`;
const queryscoreporAll = gql`
	query scoreporAll($key: String, $grupo: Int) {
		scoreporAll(key: $key, grupo: $grupo) {
			Idp
			Eq1
			Eq2
			Hora
			fecha
			listScore {
				IDCNGE
				text
				resultado
			}
		}
	}
`;
// const querycallsaldo = gql`
// 	query callsaldo($iu: Int) {
// 		callsaldo(iu: $iu) {
// 			res
// 			text
// 			keyap
// 		}
// 	}
// `;
const CnfNacionales = gql`
	query CnfNacionales {
		viewCngq {
			cnfBlt {
				idblt
				texto
				bovaro
			}
			cnfHipo {
				idhipo
				texto
				siglas
				estatus
			}
		}
	}
`;

const queryviewCscAll = gql`
	query viewCscAll {
		viewCscAll {
			ListaDeporte {
				IDC
				Text
				Grupo
			}
			ListaAmericana {
				IDC
				Text
				Grupo
			}
			ListaNacionales {
				IDC
				Text
				Grupo
			}
		}
	}
`;
const queryvieWebSite = gql`
	query vieWebSite($site: Int) {
		vieWebSite(site: $site)
	}
`;
const mutationiwebsite = gql`
	mutation iwebsite($istrp: String, $site: Int) {
		iwebsite(istrp: $istrp, site: $site)
	}
`;
const queryvieWebSiteAll = gql`
	query vieWebSiteAll(
		$site1: Int
		$site2: Int
		$site3: Int
		$site4: Int
		$site5: Int
	) {
		one: vieWebSite(site: $site1)
		two: vieWebSite(site: $site2)
		tre: vieWebSite(site: $site3)
		for: vieWebSite(site: $site4)
		fiv: vieWebSite(site: $site5)
	}
`;
const queryallTicketDll = gql`
	query allTicketDll($id: Int) {
		allTicketDll(id: $id) {
			deporte {
				serial
				fecha
				hora
				apuesta
				isys
				cobra
				se
				status
				moment
				detalle {
					partido
					hora
					equipo
					apuesta
					odd
					escrute
				}
			}

			americana {
				serial
				fecha
				hora
				apuesta
				ValorR
				hipo
				carr
				IDCN
				estatus
				se
				isys
				cobra
				tipo
				moment
				detalle {
					eje
					text
					g
					p
					s
				}
			}
			nacionales {
				serial
				fecha
				hora
				apuesta
				ValorR
				hipo
				carr
				IDCN
				estatus
				cobra
				se
				isys
				tipo
				moment
				detalle {
					eje
					text
					g
				}
			}
			loterias {
				serial
				fecha
				hora
				total
				idj
				estatus
				se
				isys
				cobra
				moment
				detalle {
					ln
					numeros
					monto
					loteria
					sorteo
					escrute
				}
			}
		}
	}
`;
const queryOneTicketDll = gql`
	query queryOneTicketDll($serial: Int, $id: Int) {
		allTicketDll(serial: $serial, id: $id) {
			deporte {
				serial
				fecha
				hora
				apuesta
				isys
				cobra
				se
				status
				moment
				detalle {
					partido
					hora
					equipo
					apuesta
					odd
					escrute
				}
			}

			americana {
				serial
				fecha
				hora
				apuesta
				ValorR
				hipo
				carr
				IDCN
				estatus
				se
				isys
				cobra
				tipo
				moment
				detalle {
					eje
					text
					g
					p
					s
				}
			}
			nacionales {
				serial
				fecha
				hora
				apuesta
				ValorR
				hipo
				carr
				IDCN
				estatus
				cobra
				se
				isys
				tipo
				moment
				detalle {
					eje
					text
					g
				}
			}
			loterias {
				serial
				fecha
				hora
				total
				idj
				estatus
				se
				isys
				cobra
				moment
				detalle {
					ln
					numeros
					monto
					loteria
					sorteo
					escrute
				}
			}
		}
	}
`;
const querydataGrapRsm = gql`
	query dataGrapRsm($id: Int) {
		dataGrapRsm(id: $id) {
			ltGrap {
				name
				data
			}
			dias
		}
	}
`;
const querydatafecha = gql`
	query squatData($meses: Int, $id: Int) {
		datafecha(meses: $meses, id: $id) {
			deporte {
				idj
				fecha
				moment
			}
			americana {
				idj
				fecha
				moment
			}
			nacionales {
				idj
				fecha
				moment
			}
			loteria {
				idj
				fecha
				moment
			}
		}
	}
`;
const queryLoterialt = gql`
	query viewLtLoteriaLt {
		viewLtLoteriaLt {
			id
			title
			seleccion_apwid {
				text
				ida
			}
		}
	}
`;
const queryrsmJndLt = gql`
	query rsmJndLt($Jnd: String) {
		rsmJndLt(Jnd: $Jnd) {
			ln
			numeros
			monto
			loteria
			sorteo
			motivo
		}
	}
`;
const mutationregisToket = gql`
	mutation regisToket($iu: Int, $Toket: String) {
		regisToket(iu: $iu, Toket: $Toket)
	}
`;
const querysportLegueTODO = gql`
	query sportLegueTODO($dia: String) {
		sportLegueTODO(dia: $dia) {
			key
			texto
			sport
			Column {
				tituloDiv
				apuesta {
					title
					dataIndex
					key
				}
			}
			oddsLegue {
				idp
				partido
				key
				code
				ide
				partidos
				hora
				extra
				status
				publicado
				ap
				img
				size
			}
		}
	}
`;
///////////////////////////////
const queryviewValoresAnul = gql`
	query viewValoresAnul($id: Int!) {
		viewValoresAnul(id: $id) {
			AnulDeporte
			AnulAmericana
			AnulNacionales
			AnulLoteria
		}
	}
`;
export function queryviewValoresAnulFN(id) {
	client
		.query({
			query: queryviewValoresAnul,
			variables: { id },
		})
		.then((data) => {
			var { viewValoresAnul } = data.data;
			if (!_.isNull(viewValoresAnul)) this.setState({ viewValoresAnul });
		});
}
///////////////////////////////
export function refresListdataTODO(dia) {
	// message.loading('Espere un momento, por favor..', 100000)
	client
		.query({
			variables: { dia: dia },
			query: querysportLegueTODO,
		})
		.then((data) => {
			// message.destroy()
			this.LoadingBar.complete();
			this.chgStatus(1, { spinOnOff: false });
			var TODOdata = data.data.sportLegueTODO;
			if (_.isNull(TODOdata))
				message.error("Lamentablemente no tenemos nada para la Venta!");
			else {
				var idx = 0,
					deportes = [],
					colum = [],
					newdata = [];
				for (var rows in TODOdata) {
					var { key, Column, oddsLegue } = TODOdata[rows];
					deportes.push({
						idx,
						sport: TODOdata[rows].sport,
						texto: TODOdata[rows].texto,
						key,
						act: false,
					});
					colum.push({ key, tcolum: Column });
					var tdata = _.orderBy(oddsLegue, ["status"], ["asc"]);
					newdata.push({ key, tdata });
					idx++;
				}
				store.dispatch({ type: "IN_DATA_DEP_2", deportes });
				store.dispatch({ type: "IN_data_columnas", colum });
				store.dispatch({ type: "IN_data_data", data: newdata });
			}
		});
}
export function mutationregisToketFn(iu, Toket) {
	client.mutate({
		mutation: mutationregisToket,
		variables: {
			iu,
			Toket,
		},
		update: (proxy, data) => {
			var resp = data.data.regisToket;
			if (resp !== 1)
				message.error("Su toket para mensajes no pudo actualizarse!");
		},
	});
}
function codeSend(claims, isNotlogin = true) {
	let tk;

	if (isNotlogin) {
		const getJti = hashGet();
		if (getJti === null) tk = "f5a241ad-3dda-4ab2-bdc7-33495f152adb";
		else tk = getJti;
	} else {
		tk = "f5a241ad-3dda-4ab2-bdc7-33495f152adb";
	}

	var jwt = nJwt.create(claims, tk, "HS256");
	// if (getJti===null)
	//     tk=jwt.body.jti

	var token = jwt.compact();
	var decc = encode.encode(token, "base64");
	return decc;
}

export async function queryrsmJndLtFn(Jnd, total, pt) {
	await client
		.query({
			query: queryrsmJndLt,
			variables: { Jnd },
		})
		.then((data) => {
			if (pt) {
				var data_noti = data.data.rsmJndLt;
				if (data_noti !== null) {
					this.setState({ data_noti, ntf_ticket: true });
				} else this.setState({ data_noti: [], ntf_ticket: false });
			} else {
				var detalle = data.data.rsmJndLt,
					lineal = [],
					virtualTicket = [];
				var valuePreapro = {
					serial: "Temporal",
					estatus: 1,
					fecha: moment().format("l"),
					hora: moment().format("h:mm:ss a"),
					isys: 4,
					total,
					cobra: 0,
					detalle,
				};
				var n = 1;
				for (var rows in detalle) {
					lineal = [];
					lineal.push({
						lineA: detalle[rows].loteria,
						lineB: detalle[rows].numeros,
						lineC: "x" + detalle[rows].monto,
						colum: detalle[rows].sorteo,
						id: n,
					});
					n++;
					virtualTicket.push(lineal);
				}
				this.setState({
					valuePreapro,
					visPreapor: true,
					miTicketLoteria: virtualTicket,
				});
			}
		});
}

export function queryLoterialtFn() {
	client
		.query({
			query: queryLoterialt,
		})
		.then((data) => {
			var recordLote = data.data.viewLtLoteriaLt;
			if (recordLote !== null) this.setState({ recordLote });
			else this.setState({ recordLote: [] });
		});
}
export function querydatafechaFn(id) {
	client
		.query({
			query: querydatafecha,
			variables: { meses: 3, id: id },
		})
		.then((data) => {
			var resp = data.data.datafecha;
			if (!_.isNull(resp)) {
				this.setState({ pasadoData: resp });
			}
		});
}
export function querydataGrapRsmFn(id) {
	client
		.query({
			query: querydataGrapRsm,
			variables: { id: id },
		})
		.then((data) => {
			var resp = data.data.dataGrapRsm;
			if (!_.isNull(resp)) {
				store.dispatch({ type: "IN_DATA_TK_GRAPH", series: resp.ltGrap });
				store.dispatch({ type: "IN_DATA_TK_GRAPH_DIA", dias: resp.dias });
			}
		});
}
export async function queryaOneTicketDllFn(serial, id) {
	await client
		.query({
			query: queryOneTicketDll,
			variables: { id: id, serial: serial },
		})
		.then((data) => {
			var resp = data.data.allTicketDll;
			var delta1 = store.getState().delta1;
			var found = false;
			if (resp.deporte.length !== 0) {
				delta1 = _.concat(delta1, resp.deporte);
				found = true;
			}
			if (resp.americana.length !== 0) {
				delta1 = _.concat(delta1, resp.americana);
				found = true;
			}
			if (resp.nacionales.length !== 0) {
				delta1 = _.concat(delta1, resp.nacionales);
				found = true;
			}
			if (resp.loterias.length !== 0) {
				delta1 = _.concat(delta1, resp.loterias);
				found = true;
			}
			if (found) {
				delta1 = _.orderBy(delta1, ["moment"], ["desc"]);
				store.dispatch({ type: "IN_DATA_TK_DEP", delta1 });
				goToAnchor("s" + serial);
			} else {
				notification.error({
					message: `Serial ${serial} solicitado`,
					description:
						"Este serial no se encontro o no le pertence a este usuario",
				});
			}
		});
}
export function queryallTicketDllFn(id) {
	client
		.query({
			query: queryallTicketDll,
			variables: { id: id },
		})
		.then((data) => {
			var resp = data.data.allTicketDll;
			if (resp !== null) {
				var Tem = _.concat(
					resp.deporte,
					resp.americana,
					resp.nacionales,
					resp.loterias
				);
				var datAll = _.orderBy(Tem, ["moment"], ["desc"]);
				store.dispatch({ type: "IN_DATA_TK_DEP", delta1: datAll });
			}
		});
}
export function queryvieWebSiteAllFn() {
	client
		.query({
			query: queryvieWebSiteAll,
			variables: { site1: 1, site2: 2, site3: 3, site4: 4, site5: 5 },
		})
		.then((data) => {
			var resp = data.data;
			store.dispatch({ type: "IN_DATA_WEB1", propsSecc1: resp.one });
			store.dispatch({ type: "IN_DATA_WEB2", propsSecc2: resp.two });
			store.dispatch({ type: "IN_DATA_WEB3", propsSecc3: resp.tre });
			store.dispatch({ type: "IN_DATA_WEB4", propsSecc4: resp.for });
			store.dispatch({ type: "IN_DATA_WEB5", propsSecc5: resp.fiv });
		});
}
function replacer(key, value) {
	if (key === "style") {
		if (value === "bgcolor-rgb(255,255,255)")
			return "bgcolor-rgb(255, 255, 255,0) ";
	}
	return value;
}
export function mutationiwebsiteFn(istrp, site) {
	var data = encode.encode(JSON.stringify(istrp, replacer), "base64");
	client.mutate({
		mutation: mutationiwebsite,
		variables: { site: site, istrp: data },
		update: (proxy, data) => {
			return data.data.iwebsite;
		},
	});
}
export function queryvieWebSiteFn(site) {
	client
		.query({
			query: queryvieWebSite,
			variables: { site: site },
		})
		.then((data) => {
			var resp = data.data.vieWebSite;
			store.dispatch({ type: "IN_DATA_WEB", iwebData: resp });
		});
}
export function queryviewCscAllFn(tthis) {
	client
		.query({
			query: queryviewCscAll,
		})
		.then((data) => {
			var resp = data.data.viewCscAll;
			if (resp.length !== 0) {
				tthis.setDataIU(resp, 4);
			}
		});
}
export function CnfNacionalesFn(tthis) {
	client
		.query({
			query: CnfNacionales,
		})
		.then((data) => {
			var resp = data.data.viewCngq;
			if (resp.length !== 0) {
				tthis.setDatalist(resp);
			}
		});
}
export const subcribeSaldo = gql`
	subscription($iu: Int) {
		CallSaldoSub(iu: $iu) {
			res
			text
			keyap
			modulo
		}
	}
`;

export const  querycallsaldoFn=({ iu },useUpdateData)=>{
    client.subscribe({ query: subcribeSaldo, variables: { iu } }).subscribe({
      next({ data: { CallSaldoSub } }) {
              const {resp,keyap}=CallSaldoSub
        if (CallSaldoSub) {
          if (resp !== -1) {
            var key = JSON.parse(encode.decode(keyap, "base64"));
            useUpdateData(key)
          }
        }
      },
    });
  }
// export function querycallsaldoFn({ iu }) {
// 	console.log("VIENDO EL SALDO");
// 	client.subscribe({ query: subcribeSaldo, variables: { iu } }).subscribe({
// 		next({ data: { CallSaldoSub } }) {
// 			console.log(CallSaldoSub);
//             const {resp,keyap}=CallSaldoSub
// 			if (CallSaldoSub) {
// 				if (resp !== -1) {
// 					var key = JSON.parse(encode.decode(keyap, "base64"));
// 					this.setDatalist(key);
// 				}
// 			}
// 		},
// 	});
	// client.query({
	//         query: querycallsaldo,
	//         variables:{ iu:iu },
	// })
	// .then(data =>{
	//     var resp=data.data.callsaldo;
	//     if (!_.isNull(resp)){
	//         if (resp.res!==-1) {
	//             var key=JSON.parse(encode.decode(resp.keyap, 'base64'))
	//             this.setDatalist(key)
	//         }
	//     }
	// })
// }
export function queryscoreporIPDFn(tthis, idp) {
	client
		.query({
			query: queryscoreporIPD,
			variables: { idp: idp },
		})
		.then((data) => {
			var scoreporIPD = data.data.scoreporIPD;
			if (scoreporIPD[0].Idp !== -1) tthis.setDatalist(scoreporIPD);
		});
}
export function mutationanulchgStatusCuentasFn(
	idtss,
	Status,
	AddDesc,
	aporte,
	tthis
) {
	message.loading("Espere un momento, por favor..", 100000);
	client.mutate({
		mutation: mutationanulchgStatusCuentas,
		variables: {
			idtss: idtss,
			Status: Status,
			AddDesc: AddDesc,
		},
		update: (proxy, data) => {
			message.destroy();
			var resp = data.data.chgStatusCuentas;
			if (resp.res === -1) {
				notification.error({
					message: "Error en Anular",
					description: resp.text,
				});
			} else {
				message.success("Transaccion Realizada!");
				tthis.actualizarGrip(aporte);
			}
		},
	});
}

export function queryviewBancosFn() {
	client
		.query({
			query: queryviewBancos,
		})
		.then((data) => {
			var viewBancos = data.data.viewBancos;
			this.setDatalist(viewBancos);
		});
}
export function mutationanulOneTicketFn(serial, iu, isys) {
	message.loading("Espere un momento, por favor..", 100000);
	var mutt;
	switch (isys) {
		case 1:
			mutt = mutationanulOneTicket;
			break;
		case 2:
			mutt = mutationanulOneTicketAme;
			break;
		case 3:
			mutt = mutationanulOneTicketNac;
			break;
		default:
			mutt = mutationanulOneTicketLot;
	}
	client.mutate({
		mutation: mutt,
		variables: {
			serial: serial,
			iu: iu,
			ip: ip.address(),
		},
		update: (proxy, data) => {
			message.destroy();
			var resp;
			switch (isys) {
				case 1:
					resp = data.data.anulOneTicket;
					break;
				case 2:
					resp = data.data.anulOneTicketAme;
					break;
				case 3:
					resp = data.data.anulOneTicketNac;
					break;
				default:
					resp = data.data.anulOneTicketLoteria;
			}
			if (resp.res === -1) {
				notification.error({
					message: "Error en Anular",
					placement: "topLeft",
					description: resp.text,
				});
			} else {
				message.success("Ticket Anulado!");
				this.callRefress("-1");
			}
		},
	});
}
function addregsTk(data) {
	var delta1 = store.getState().delta1;
	delta1 = delta1.concat(data);

	delta1 = _.orderBy(delta1, ["moment"], ["desc"]);

	store.dispatch({ type: "IN_DATA_TK_DEP", delta1 });
}
export function queryviewOneTicketFn(serial, op) {
	if (op === 1) {
		client
			.query({
				query: queryviewOneTicket,
				variables: { serial: serial },
			})
			.then((data) => {
				var viewOneTickets = data.data.viewOneTicket;
				this.setDatalistTk(viewOneTickets, []);
				addregsTk(viewOneTickets);
			});
	}
	if (op === 2) {
		client
			.query({
				query: queryviewOneTicketAme,
				variables: { serial: serial },
			})
			.then((data) => {
				var viewOneTicketAme = data.data.viewOneTicketAme;
				this.setDatalistTk(viewOneTicketAme, viewOneTicketAme.detalle);
				addregsTk(viewOneTicketAme);
			});
	}
	if (op === 3) {
		client
			.query({
				query: queryviewOneTicketNac,
				variables: { serial: serial },
			})
			.then((data) => {
				var viewOneTicketNac = data.data.viewOneTicketNac;
				addregsTk(viewOneTicketNac);
				this.setDatalistTk(viewOneTicketNac, viewOneTicketNac.detalle);
			});
	}
	if (op === 4) {
		client
			.query({
				query: queryviewOneTicketLot,
				variables: { serial: serial },
			})
			.then((data) => {
				var viewOneTicketLot = data.data.viewOneTicketLot;
				/// agrupando para imprimir solo eso detalle del ticket
				var { detalle } = viewOneTicketLot;
				var cloteria = "",
					csorteo = "",
					detakk = [],
					display = [];
				for (var i in detalle) {
					var { loteria, sorteo, numeros, monto } = detalle[i];
					if (cloteria !== loteria || csorteo !== sorteo) {
						if (cloteria !== "" || csorteo !== "") {
							detakk.push({
								loteria: cloteria,
								sorteo: csorteo,
								display,
							});
						}
						cloteria = loteria;
						csorteo = sorteo;
						display = [];
					}
					display.push({
						numeros: `${numeros}x${monto}`,
					});
				}
				detakk.push({
					loteria: cloteria,
					sorteo: csorteo,
					display,
				});
				addregsTk(viewOneTicketLot);
				this.setDatalistTk(viewOneTicketLot, detakk);
			});
	}
}
export function queryviewTicketsFn(iu, fecha, tipo) {
	client
		.query({
			query: queryviewTickets,
			variables: {
				fecha: fecha,
				tipo1: 1,
				tipo2: 2,
				tipo3: 3,
				tipo4: 4,
				iu: iu,
			},
		})
		.then((data) => {
			var viewRecordTickets = data.data;

			this.setDatalist(viewRecordTickets, tipo);
			this.setState({ loading: false });
			this.LoadingBar.complete();
		});
}
//// Funciones de Apuestas post Respuesta del  backend!
function action_Apuesta_deporte(key, resp, tthis) {
	// Action de Deportes

	var datosM = _.split(key, "*");
	var Noti1 = tthis.whoisDato(datosM[0]);
	var Noti2 = tthis.whoisDato(datosM[1]);
	var textoresp;
	if (Noti1 === "-1" || Noti2 === "-1") textoresp = resp.text;
	else textoresp = resp.text + ", No puede combinar " + Noti1 + " con " + Noti2;

	notification.error({
		message: "Error de Apuesta",
		description: textoresp,
	});
}
function action_Apuesta_nacionales(key, resp, tthis) {
	// Action de Nacionales
	switch (resp.res) {
		case 1:
			break;
		default:
			notification.error({
				message: "Error de Apuesta",
				description: resp.text,
			});
			tthis.iClearDatosAme();
	}
}
function action_Apuesta_nacionales_remate(key, resp) {
	// Action de Remate (Nacionales)
	switch (resp.res) {
		case 1:
			this.setDataRemate(key);
			this.iActuaLisNacRmt(0);
			break;

		default:
			notification.error({
				message: "Error de Apuesta",
				description: resp.text,
			});
			this.iClearDatosAme();
	}
}
function action_Apuesta_loteria(key, resp) {
	// Action de Loteria
	// var activar_aprobacion=false,Jnd=[];
	switch (resp.res) {
		case 1:
			var porsorteo = JSON.parse(key[0].extraidos); // Numeros eliminados por cierre del sorteo
			var paqLot = JSON.parse(key[0].paqLot); // Informacion devuela por tope de numeros con montos parciales(saldos) o cupos copados!
			// var jugadadefi=paqLot.Jjndn                 // Jugada final en caso de solicitar las aprobacion por parte del cliente!

			var alldataOBS = [];
			let observaci;
			if (paqLot.length === 0 && porsorteo.length !== 0) {
				alldataOBS = porsorteo;
			}
			if (paqLot.length !== 0 && porsorteo.length === 0) {
				observaci = paqLot.observaciones; // objetos de los numeros afectsos
				if (observaci.length !== 0) alldataOBS = observaci;
			}
			if (paqLot.length !== 0 && porsorteo.length !== 0) {
				observaci = paqLot.observaciones; // objetos de los numeros afectsos
				alldataOBS = [...observaci, ...porsorteo];
			}
			if (alldataOBS.length !== 0) {
				this.notificacion_lot(JSON.stringify(alldataOBS));
			}
			break;
		default:
			//"{"observaciones":[{"numer":"18","cmd":-1,"hor":1,"lot":1,"tipo":1,"ida":0,"mtope":100,"mnt":90,"hay":90,"act":1,"newmont":10,"mtv":2},{"numer":"18","cmd":-1,"hor":2,"lot":1,"tipo":1,"ida":0,"mtope":100,"mnt":90,"hay":90,"act":1,"newmont":10,"mtv":2}],
			//"Jjndn":[{"numer":"13,19","cmd":-1,"tipo":1,"mnt":90,"hor":1,"lot":1,"ida":0},{"numer":"13,19","cmd":-1,"tipo":1,"mnt":90,"hor":2,"lot":1,"ida":0},{"numer":"18","cmd":-1,"hor":1,"lot":1,"tipo":1,"ida":0,"mnt":10},{"numer":"18","cmd":-1,"hor":2,"lot":1,"tipo":1,"ida":0,"mnt":10}],"callme":2}"
			// var Jjndn=JSON.parse(keyap[0].Jjndn);
			notification.error({
				message: "Error de Apuesta",
				description: resp.text,
			});
			this.aprobacion_lot(JSON.stringify(key.Jjndn), key.montonewTk);
	}
}
function action_Apuesta_americana(key, resp) {
	// Action de Americana
	switch (resp.res) {
		case 1:
			break;

		default:
			notification.error({
				message: "Error de Apuesta",
				description: resp.text,
			});
	}
}
//////////////////// Principal del endpoint Registro de Apuestas ////////////////////
var captioOn = true;
export async function mutationRegistrarApuestaFn(registo, tds, iu) {
	if (captioOn) {
		captioOn = false; // Bloquear
		message.loading("Espere un momento, por favor..", 100000);
		var input = codeSend(registo);
		await client.mutate({
			mutation: mutationRegistrarApuesta,
			variables: {
				input,
				iu,
				tds,
			},
			error: (e) => {
				console.log("ERROR", e);
			},
			update: (proxy, data) => {
				var resp = data.data.registroApuesta;
				message.destroy();
				if (resp.res === 1 || resp.res === -7) {
					if (!_.isNull(resp.keyap))
						var key = JSON.parse(encode.decode(resp.keyap, "base64"));
					if (resp.res === 1) {
						notification.success({
							message: "Apuesta Aceptada",
							description: "Tu Apuesta fue aceptada exitosamente! Gracias",
						});
					}
					const callback = {
						dep: () => action_Apuesta_deporte(key, resp, this),
						nacpolla: () => action_Apuesta_nacionales(key, resp, this),
						nac: () => action_Apuesta_nacionales(key, resp, this),
						ame: () => {
							action_Apuesta_americana(key, resp);
							if (resp.res === -7) {
								this.iClearDatosAme(true);
							}
						},
						rmtrm: () => action_Apuesta_nacionales_remate(key, resp),
						lot: () => action_Apuesta_loteria(key, resp),
					};
					const callFuntion = callback[resp.modulo];
					callFuntion();
					if (resp.res === 1) this.inciarVarlores(key[0].saldo, key[0].serial);
				} else {
					/// Caso de errores de inicializacion de login!
					if (resp.res === -9102881) this.Salir();
					else {
						notification.error({
							message: "Error de Apuesta",
							description: resp.text,
						});
						if (resp.res === -13) this.pullAll();
					}
				}
				// Opciones finales de endpoint, postconsultas
				queryallTicketDllFn(iu);
				querydataGrapRsmFn(iu);
				this.setChgBlock(false, false);
				captioOn = true; /// Liberar
				//////////////////////////////////////////////
			},
		});
	}
}
////////////////////////////////////////////////////////////////////////////////
const haldlenCallBasic = ({ keys, props }) => {
	var key = JSON.parse(encode.decode(keys, "base64"));
	store.dispatch({ type: "BLOCK" });
	store.dispatch({ type: "IN_data_displayBanner", displayBanner: false });
	var {
		hash,
		tipoprinter,
		paramterImp,
		modedepor,
		modeganadores,
		portImp,
		receptor,
		id,
		accesSYs,
		nombre,
		moneda,
		idioma,
		saldo,
		pago,
		ticket,
		activo,
		avatar,
		tipo,
	} = key;
	hashSet(hash);
	props.history.replace({
		pathname: "/Mainapp",
		state: {
			receptor,
			tipoprinter,
			paramterImp,
			portImp,
			Anular: JSON.parse(key.Anular),
			habiNacionales: modeganadores,
			tipoParlay: modedepor,
			access: accesSYs,
			id,
			iu: nombre,
			unidad: moneda,
			idioma,
			saldo,
			pago,
			impriTK: ticket,
			activo,
			avatar,
			tipo,
		},
	});
	return true;
};
export async function iloginGoogleFn(props, iu, pw, registro) {
	message.loading("Espere un momento, por favor..", 100000);
	var salt = bcrypt.genSaltSync(12);
	var hash = bcrypt.hashSync(_.toString(pw), salt);
	var claims = {
		iu: iu,
		hash: hash,
		registro: registro,
	};
	var decc = codeSend(claims, false);
	client
		.query({
			query: queryiLoginGoogle,
			variables: { input: decc },
		})
		.then((data) => {
			message.destroy();
			this.setChgBlock(false, false, false);
			var ilogin = data.data.iloginGoogle;
			if (ilogin.res === 0) {
				this.setChgAuth(true);
				haldlenCallBasic({ keys: ilogin.key, props });
			} else {
				hashSet("0");
				if (ilogin.res !== -9102881) message.error(ilogin.text);
				if (ilogin.res !== -9102882)
					message.error("Tu Fecha y Hora no estan actualizada! Verifica");
			}
		});
}
const queryiLogin = gql`
	query ilogin($input: String) {
		ilogin(input: $input) {
			res
			text
			key
		}
	}
`;
export async function iloginFn(props, iu, pw) {
	message.loading("Espere un momento, por favor..", 100000);
	this.loginNo(false);
	var salt = bcrypt.genSaltSync(12);
	var hash = bcrypt.hashSync(_.toString(pw), salt);
	var claims = {
		iu,
		hash,
	};
	var decc = codeSend(claims, false);
	client
		.query({
			query: queryiLogin,
			variables: { input: decc },
		})
		.then((data) => {
			message.destroy();
			this.setChgBlock(false, false, false);
			var ilogin = data.data.ilogin;
			if (ilogin.res === 0) {
				this.setChgAuth(true);
				haldlenCallBasic({ keys: ilogin.key, props });
			} else {
				this.loginNo(true);
				hashSet("0");
				if (ilogin.res !== -9102881) message.error(ilogin.text);
				else if (ilogin.res !== -9102882)
					message.error("Tu Fecha y Hora no estan actualizada! Verifica");
			}
		});
}

export async function iloginFnAdmin(iu, pw) {
	message.loading("Espere un momento, por favor..", 100000);
	var salt = bcrypt.genSaltSync(12);
	var hash = bcrypt.hashSync(_.toString(pw), salt);
	var claims = {
		iu: iu,
		hash: hash,
	};
	var decc = codeSend(claims, false);
	client
		.query({
			query: queryiLoginAdmin,
			variables: { input: decc },
		})
		.then((data) => {
			message.destroy();
			this.setChgBlock(false, false, false);
			var ilogin = data.data.iloginAdmon;
			if (ilogin.res === 0) {
				var key = JSON.parse(encode.decode(ilogin.key, "base64"));
				store.dispatch({ type: "BLOCK" });
				store.dispatch({ type: "IN_data_displayBanner", displayBanner: false });
				// console.log(key._JSON_PRIVATE);
				this.props.history.replace({
					pathname: "/MainAdmin",
					state: { id: key.id, iu: key.nombre, _PRIVATE: key._JSON_PRIVATE },
				});
			} else {
				hashSet("0");
				message.error(ilogin.text);
			}
		});
}
export function mutationRegistroCuentasFn(registo) {
	message.loading("Espere un momento, por favor..", 100000);
	client.mutate({
		mutation: mutationRegistroCuentas,
		variables: {
			input: registo,
		},
		update: (proxy, data) => {
			var resp = data.data.registroCuentas;
			if (resp.res === 0) {
				this.actualizaciones();
				this.clearDatos();
				message.destroy();
				message.success("Transaccion Regitrada!");
			} else {
				message.destroy();
				message.error(resp.text);
			}
		},
	});
}
export function queryHistoryCuentasFn(idPRV, iu, modo, limited_min) {
	message.loading("Espere un momento, por favor..", 100000);
	client
		.query({
			query: queryHistoryCuentas,
			variables: { iu, ord: "DESC", modo, limited_min, idPRV },
		})
		.then((data) => {
			message.destroy();
			var viewRecordCuentas = data.data.viewHistoryCuentas;
			if (modo === 4) this.setDatalist3(viewRecordCuentas);
			// Depositos
			else if (modo === 5) this.setDatalist4(viewRecordCuentas);
			// Retiros
			else this.setDatalist2(viewRecordCuentas);
		});
}
export function queryRecordCuentasFn(idPRV) {
	client
		.query({
			query: queryRecordCuentas,
			variables: { activo: 1, idPRV },
		})
		.then((data) => {
			var viewRecordCuentas = data.data.viewRecordCuentas;
			this.setDatalist(viewRecordCuentas);
		});
}
export function grabarTopeDeportes(registo, eliminar, tthis) {
	client.mutate({
		mutation: mutationTopeDeporte,
		variables: {
			input: registo,
			eliminar: eliminar,
		},
		update: (proxy, data) => {
			var resp = data.data.mutationTopeDeporte;
			if (resp !== -1) {
				tthis.setModal2VisibleCancel(false);
				if (eliminar === 0) message.success("Datos de Tope Registrado!");
				else message.success("El Tope fue Borrado!");
			} else message.error("Lamentablemente tenemos un error! Verifique!");
		},
	});
}

export function grabarTopeAmericana(registo, eliminar, tthis) {
	client.mutate({
		mutation: mutationTopeAmericana,
		variables: {
			input: registo,
			eliminar: eliminar,
		},
		update: (proxy, data) => {
			var resp = data.data.mutationTopeAmericana;
			if (resp !== -1) {
				tthis.setModal1VisibleCancel(false);
				if (eliminar === 0) message.success("Datos de Tope Registrado!");
				else message.success("El Tope fue Borrado!");
			} else message.error("Lamentablemente tenemos un error! Verifique!");
		},
	});
}
export function grabarTopeNacionales(registo, eliminar, tthis) {
	client.mutate({
		mutation: mutationTopeNacionales,
		variables: {
			input: registo,
			eliminar: eliminar,
		},
		update: (proxy, data) => {
			var resp = data.data.mutationTopeAmericana;
			if (resp !== -1) {
				tthis.setModal1VisibleCancel(false);
				if (eliminar === 0) message.success("Datos de Tope Registrado!");
				else message.success("El Tope fue Borrado!");
			} else message.error("Lamentablemente tenemos un error! Verifique!");
		},
	});
}
export function grabarTopeLoterias(registo, eliminar) {
	client.mutate({
		mutation: mutationTopeLoterias,
		variables: {
			input: registo,
			eliminar: eliminar,
		},
		update: (proxy, data) => {
			var resp = data.data.registroTopeLoteria;
			if (resp !== -1) {
				this.props.tthis.setModal1VisibleCancel(false);
				this.inicializarVLM();
				if (eliminar === 0) message.success("Datos de Tope Registrado!");
				else message.success("El Tope fue Borrado!");
			} else message.error("Lamentablemente tenemos un error! Verifique!");
		},
	});
}

export function queryAllTopesLoterias() {
	client
		.query({
			query: queryViewTopesGwLot,
		})
		.then((data) => {
			var { viewTopesLot } = data.data;
			//props.setDatalist(viewTopesG)
			this.setState({ dataLot: viewTopesLot });
		});
}
export function queryAllTopesNacionales(tthis) {
	client
		.query({
			query: queryViewTopesGwNac,
		})
		.then((data) => {
			var viewTopesNac = data.data.viewTopesNac;
			//props.setDatalist(viewTopesG)
			tthis.setDatalist4(viewTopesNac);
		});
}
export function queryAllTopesAmericana(tthis) {
	client
		.query({
			query: queryViewTopesGwAme,
		})
		.then((data) => {
			var viewTopesAme = data.data.viewTopesAme;
			//props.setDatalist(viewTopesG)
			tthis.setDatalist3(viewTopesAme);
		});
}
export function queryAllTopesDeportes(tthis) {
	client
		.query({
			query: queryViewTopesGwDepo,
		})
		.then((data) => {
			var viewTopesG = data.data.viewTopesDepo;
			//props.setDatalist(viewTopesG)
			tthis.setDatalist2(viewTopesG);
		});
}
export function grabarTope(registo, eliminar, tthis) {
	client.mutate({
		mutation: mutationTope,
		variables: {
			input: registo,
			eliminar: eliminar,
		},
		update: (proxy, data) => {
			var resp = data.data.registroTopeG;
			if (resp !== -1) {
				tthis.setModal1VisibleCancel(false);
				if (eliminar === 0) message.success("Datos de Tope Registrado!");
				else message.success("El Tope fue Borrado!");
			} else message.error("Lamentablemente tenemos un error! Verifique!");
		},
	});
}

export function queryAllTopes(tthis) {
	client
		.query({
			query: queryViewTopesGw,
		})
		.then((data) => {
			var viewTopesG = data.data.viewTopesG;
			//props.setDatalist(viewTopesG)
			tthis.setDatalist(viewTopesG);
		});
}

export function viewListIU(key, like, idPRV) {
	/// key 1: Usuario de Venta Directa o taquilla
	/// key 2: lo Super Administradores
	/// key 3: Los registrados pero no activos! se van a verificar si no hay data en
	client
		.query({
			variables: { key, like, idPRV },
			query: queryViewIU,
		})
		.then((data) => {
			var queryViewIU = data.data.viewIU;
			this.setDataIU(queryViewIU, key);
		});
}
export function viewListIUonly(iu) {
	client
		.query({
			variables: { iu: iu },
			query: queryViewIU,
		})
		.then((data) => {
			var queryViewIU = data.data.viewIU;
			this.setDataIU(queryViewIU[0], iu);
		});
}
export function generarClave(tthis) {
	client
		.query({
			variables: { iu: "-1" },
			query: queryGenerarPw,
		})
		.then((data) => {
			var nuevaClave = data.data.newpw[0].pw;
			var claveGenerada = JSON.parse(encode.decode(nuevaClave, "base64"));
			tthis.setClaveNueva(claveGenerada);
		});
}
export function grabarIUlitte(registo) {
	client.mutate({
		mutation: mutationIUlitte,
		variables: {
			input: registo,
		},
		update: (proxy, data) => {
			var resp = data.data.registroUIlitte;
			if (resp !== 0) message.success("Usuario Registrado!");
			else message.error("Lamentablemente tenemos un error! Verifique!");
			this.actualizarMain();
		},
	});
}

export function grabarIUlitte_Main(registo, tthis) {
	client.mutate({
		mutation: mutationIUlitte_Main,
		variables: {
			input: registo,
		},
		update: (proxy, data) => {
			var resp = data.data.registroUIlitte;
			if (resp !== 0) message.success("Usuario Registrado!");
			else message.error("Lamentablemente tenemos un error! Verifique!");
			tthis.actualizarMain();
		},
	});
}
export function grabarIU(registo, nivel) {
	client.mutate({
		mutation: mutationIU,
		variables: {
			input: registo,
		},
		update: (proxy, data) => {
			var resp = data.data.registroUI;
			if (resp !== "-1") {
				switch (nivel) {
					case 1:
						this.setModal1VisibleCancel(false);
						this.actualizarLista();
						message.success("Usuario Registrado!");
						break;
					case 2:
						this.initFromApp();
						notification.success({
							message: "Registro Aceptado",
							description:
								"Su registro fue aceptado, ingrese al sistema con su nuevo usuario! Gracias",
						});
						break;
					default:
				}
			} else message.error("Lamentablemente tenemos un error! Verifique!");
		},
	});
}
export function searchIU() {
	client
		.query({
			query: querySearchIU,
		})
		.then((data) => {
			var searchIU = JSON.parse(encode.decode(data.data.searchIU, "base64"));
			this.setStatusIU(searchIU);
		});
}
export function refresListdata(dia) {
	client
		.query({
			variables: { dia: dia },
			query: querysportLegue,
		})
		.then((data) => {
			var deportes = data.data.sportLegue;
			if (_.isNull(deportes))
				message.error("Lamentablemente no tenemos nada para la Venta!");
			else {
				var openSubItm = [];
				for (var rows in deportes) openSubItm.push(rows);

				var iddeportes = [],
					idx = 0;
				deportes.forEach((value) => {
					var { sub } = value;
					sub.forEach((value1) => {
						iddeportes.push({
							idx,
							text: value1.texto,
							key: value1.key,
							act: false,
						});
						idx++;
					});
				});
				store.dispatch({ type: "IN_data_iddeportes", iddeportes });

				store.dispatch({ type: "IN_DATA_DEP_1", openSubItm });
				store.dispatch({ type: "IN_DATA_DEP_2", deportes });
			}
		});
}
export function funiBodyScore(dia, id, tthis) {
	client
		.query({
			variables: { key: dia, grupo: id },
			query: queryscoreporAll,
		})
		.then((respons) => {
			var data = respons.data.scoreporAll;
			var columna = [];
			var fecha = "";
			if (data.length !== 0) {
				fecha = data[0].fecha;
				columna = data[0].listScore;
				var colmData = [];
				for (var rows in columna) {
					if (columna[rows].IDCNGE === 6 || columna[rows].IDCNGE === 1)
						colmData.push({ title: "FINAL" });
					else colmData.push({ title: columna[rows].text });
				}
				tthis.actualizarValores(2, colmData); // Actuliazo Columnas
				tthis.actualizarValores(3, data); // Actualizo Datos
				tthis.actualizarValores(5, fecha); // Actualizo Fecha
				tthis.actualizarValores(4, data); // Coloco los resultados solicitados
			}
		});
}
// query scoreporAll($key:String,grupo:Int){
//     scoreporAll(key:$key,grupo:$grupo){
//         Idp
//         Eq1
//         Eq2
//         listScore {
//             IDCNGE
//             text
//             resultado
//         }
export function refresListdataScore(dia, tthis) {
	client
		.query({
			variables: { dia: dia },
			query: querysportLegueScore,
		})
		.then((data) => {
			var deportes = data.data.sportLegueScore;
			if (_.isNull(deportes))
				message.error("Lamentablemente no resultados no estan publicados!");
			else tthis.actualizarValores(1, deportes);
		});
}

export function funiBodyOddsAll(dia, id) {
	client
		.query({
			variables: { key: id },
			query: querysportColum,
		})
		.then((data) => {
			var colum = data.data.sportColum;
			//// Dispatch de Columnas de Jugadas por el Deporte Solicitado!
			store.dispatch({ type: "IN_data_columnas", colum });

			//////////////////////////////////////////////////////////////
			var ssmenu = [],
				smenu = [],
				ssx = 5,
				ssc = 0,
				indicado = "";
			colum.forEach((value) => {
				var { apuesta, tituloDiv } = value;
				var inicial = 1;
				// if (indicado==='') indicado=tituloDiv;
				apuesta.forEach((value1) => {
					var { title, key, dataIndex } = value1;
					if (indicado !== tituloDiv) {
						indicado = tituloDiv;
						inicial = 1;
					} else inicial = 0;
					if (ssc <= ssx) {
						// if (indicado!==tituloDiv){
						//     smenu.push(ssmenu);
						//     ssmenu=[];
						//     ssc=0;
						//     indicado=tituloDiv;
						// }
						ssmenu.push({ tituloDiv, title, key, dataIndex, inicial });
						ssc++;
					} else {
						ssc = 0;
						inicial = 1;
						smenu.push(ssmenu);
						ssmenu = [];
						ssmenu.push({ tituloDiv, title, key, dataIndex, inicial });
					}
				});
			});
			smenu.push(ssmenu);
			console.log("smenu", smenu);
			////////////////////////////////////////////////////////////
			client
				.query({
					variables: { grupo: id, dia: dia },
					query: queryoddsLegue,
				})
				.then((respons) => {
					var ddata = respons.data.oddsLegue;
					var data = _.orderBy(ddata, ["status"], ["asc"]);

					/// Dispatch de Data (Odds) de Deporte Solicitado!
					store.dispatch({ type: "IN_data_data", data });
					store.dispatch({ type: "IN_data_smenu", smenu });
				});
		});
}
export function funiBodyOdds(dia, id, tthis) {
	client
		.query({
			variables: { key: id },
			query: querysportColum,
		})
		.then((data) => {
			var columnas = data.data.sportColum;
			tthis.actualizarValores(2, columnas, id);
			client
				.query({
					variables: { grupo: id, dia: dia },
					query: queryoddsLegue,
				})
				.then((data) => {
					var ddata = data.data.oddsLegue;
					var ordda = _.orderBy(ddata, ["status"], ["asc"]);
					tthis.actualizarValores(3, ordda, id);
					tthis.actualizarValores(4, id, id);
				});
		});
}

export function calculosdePool(l1, l2, l3, l4, verpool) {
	var valorm = 0;
	var orr = [];
	const haldlenLitteral = {
		0: l1.length,
		1: l2.length,
		2: l3.length,
		3: l4.length,
	};
	switch (verpool) {
		case "exacta":
			for (let i = 0; i <= 1; i++) orr.push(haldlenLitteral[i]);
			for (let a = 0; a <= l1.length - 1; a++)
				for (let b = 0; b <= l2.length - 1; b++) if (l1[a] !== l2[b]) valorm++;

			break;
		case "trifecta":
			for (let i = 0; i <= 2; i++) orr.push(haldlenLitteral[i]);
			for (let a = 0; a <= l1.length - 1; a++)
				for (let b = 0; b <= l2.length - 1; b++)
					for (let c = 0; c <= l3.length - 1; c++)
						if (l1[a] !== l2[b] && l1[a] !== l3[c])
							if (l2[b] !== l3[c]) valorm++;
			break;
		case "superfecta":
			for (let i = 0; i <= 3; i++) orr.push(haldlenLitteral[i]);
			for (let a = 0; a <= l1.length - 1; a++)
				for (let b = 0; b <= l2.length - 1; b++)
					for (let c = 0; c <= l3.length - 1; c++)
						for (let d = 0; d <= l4.length - 1; d++)
							if (l1[a] !== l2[b] && l1[a] !== l3[c] && l1[a] !== l4[d])
								if (l2[b] !== l3[c] && l2[b] !== l4[d])
									if (l3[c] !== l4[d]) valorm++;

			break;
		default:
	}

	return { mul: valorm, subtt: orr };
}

export function calculosdePool2(
	l1,
	l2,
	l3,
	l4,
	l5,
	l6,
	l7,
	l8,
	l9,
	verpool,
	cantloto
) {
	const haldlenLitteral = {
		0: l1.length,
		1: l2.length,
		2: l3.length,
		3: l4.length,
		4: l5.length,
		5: l6.length,
		6: l7.length,
		7: l8.length,
		8: l9.length,
	};
	var valorm = 1;
	var orr = [];
	var cant;
	switch (verpool) {
		case "pool4":
			cant = 3;
			break;
		case "lotto":
			cant = cantloto - 1;
			break;
		default:
	}
	for (var i = 0; i <= cant; i++) {
		orr.push(haldlenLitteral[i]);
		valorm *= haldlenLitteral[i];
	}
	return { mul: valorm, subtt: orr };
}



export const statusColletion = async () => {
    const URL ="https://verfacil.com:2124/status/casagrande"
    // eslint-disable-next-line no-undef
    return await fetch(URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(async (res) => res.json())
        .catch(() => {
            return null;
        })
        .then(async (response) => {
            const { status, suspend } = response;
            return status?suspend:null;
        });
};