import {SECRETO,graphqlTEST,graphqlUrl,subcripUrl,subcripTEST} from './globals.js';
import {ApolloClient,HttpLink,ApolloLink,InMemoryCache, concat,split} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import fetch from 'unfetch'
import {hashGet} from './services/general'

const ENV=process.env.REACT_APP_MODE
const enviroment = ENV ==='TEST'

const uri = enviroment?graphqlTEST:graphqlUrl;
const wsUri = enviroment?subcripTEST:subcripUrl;

const httpLink = new HttpLink({ uri,fetch});
const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true
  }
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return ( definition.kind === 'OperationDefinition' && definition.operation === 'subscription' )
  },
  wsLink,
  httpLink,
)

// adding auth headers
const authMiddleware = new ApolloLink((operation, forward) => {
    const tokent = hashGet()
    operation.setContext({
      headers: {
        tokent,
        authorization:SECRETO
      }
    });
  return forward(operation);

});


// Creating a client instance
const Client = new ApolloClient({
  link: concat(authMiddleware,splitLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions:  {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});

//module.exports = { client:Client,customFetch:customFetch} ;
//module.exports.client=Client
//module.exports.customFetch=customFetch
export default Client;