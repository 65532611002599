import React, { Component } from "react";
import notification from "antd/es/notification";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import * as func from "./func";
import ReactToPrint from "react-to-print";
import store from "./store";
import "./isPrintTk.css";


var PrintTemplate = require("react-print");
var _ = require("lodash");
var socketPrint;
class Ticket extends Component {
	constructor(props) {
		super(props);
		this.valorTK = React.createRef();
	}
	render() {
		return (
			<PrintTemplate>
				{this.props.serial !== 0 &&
					!_.isUndefined(this.props.tthis.state.dellTk.detalle) && (
						<div ref={(el) => (this.valorTK = el)}>
							<div>:::::Sport Online::::</div>
							<div>Serial:{this.props.serial}</div>
							<div>
								Hora:{this.props.tthis.state.dellTk.hora} - Fecha:
								{this.props.tthis.state.dellTk.fecha}
							</div>
							<div>{this.props.tthis.state.dellTk.se}</div>
							<div>Apuesta</div>
							<div>==============================</div>
							{this.props.tthis.state.dellTk.detalle.map((valor, index) => (
								<div
									key={`idm01-${index}`}
								>{`${valor.hora} ${valor.equipo} ${valor.apuesta} Odd:${valor.odd}`}</div>
							))}
							<div>==============================</div>
							<div>
								Tu Apuesta:
								{new Intl.NumberFormat("es", {
									style: "currency",
									currency: this.props.currency,
								}).format(this.props.tthis.state.dellTk.apuesta)}
							</div>
							<div>
								Premio:
								{new Intl.NumberFormat("es", {
									style: "currency",
									currency: this.props.currency,
								}).format(
									this.props.tthis.state.dellTk.cobra -
										this.props.tthis.state.dellTk.apuesta
								)}
							</div>
							<div>
								Ganacia:
								{new Intl.NumberFormat("es", {
									style: "currency",
									currency: this.props.currency,
								}).format(this.props.tthis.state.dellTk.cobra)}
							</div>
							<div>------------------------------</div>
							<div>x</div>
							<div>x</div>
						</div>
					)}
			</PrintTemplate>
		);
	}
}
class TicketAmericana extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exoticas: ["Exacta", "Trifecta", "Superfecta"],
		};
		this.valorTK = React.createRef();
	}
	render() {
		return (
			<PrintTemplate>
				{this.props.serial !== 0 &&
					!_.isUndefined(this.props.tthis.state.detalle) && (
						<div ref={(el) => (this.valorTK = el)}>
							<div>:::::Sport Online::::</div>
							<div>Serial:{this.props.serial}</div>
							<div>
								Hora:{this.props.tthis.state.dellTk.hora} - Fecha:
								{this.props.tthis.state.dellTk.fecha}
							</div>
							<div>Electornico:{this.props.tthis.state.dellTk.se}</div>
							<div>Hipodromo:{this.props.tthis.state.dellTk.hipo}</div>
							{this.props.tthis.state.dellTk.tipo !== 0 ? (
								<div>
									Exotica:{" "}
									{this.state.exoticas[this.props.tthis.state.dellTk.tipo - 1]}
								</div>
							) : (
								""
							)}
							<div>Carrera:{this.props.tthis.state.dellTk.carr}</div>
							<div>================================</div>
							{this.props.tthis.state.detalle.map((valor, index) => (
								<div key={`idm02-${index}`}>
									{this.props.tthis.state.dellTk.tipo !== 0 ? (
										<div>
											{valor.eje + ") " + valor.text + " "}
											{"x" + valor.g}
										</div>
									) : (
										<div>
											{valor.eje + ".-" + valor.text + " "}
											{_.isNull(valor.g) ? "" : "Win:" + valor.g + " "}
											{_.isNull(valor.p) ? "" : "Plc:" + valor.p + " "}
											{_.isNull(valor.s) ? "" : "Shw:" + valor.s + " "}
										</div>
									)}
								</div>
							))}
							<div>================================</div>
							{this.props.tthis.state.dellTk.tipo !== 0 ? (
								<div>
									<div>
										Valor del Ticket:
										{new Intl.NumberFormat("es", {
											style: "currency",
											currency: this.props.currency,
										}).format(this.props.tthis.state.dellTk.ValorR)}
									</div>
									<div>
										Monto Total del Ticket:
										{new Intl.NumberFormat("es", {
											style: "currency",
											currency: this.props.currency,
										}).format(this.props.tthis.state.dellTk.apuesta)}
									</div>
								</div>
							) : (
								<div>
									Total:
									{new Intl.NumberFormat("es", {
										style: "currency",
										currency: this.props.currency,
									}).format(this.props.tthis.state.dellTk.apuesta)}
								</div>
							)}

							<div>--------------------------------</div>
							<div>x</div>
							<div>x</div>
						</div>
					)}
			</PrintTemplate>
		);
	}
}
class TicketNacionales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exoticas: [
				"Exacta",
				"Trifecta",
				"Superfecta",
				"",
				"Pool de 4",
				"Loto Hipico",
			],
		};
		this.valorTK = React.createRef();
	}
	render() {
		return (
			<PrintTemplate>
				{this.props.serial !== 0 &&
					!_.isUndefined(this.props.tthis.state.detalle) && (
						<div ref={(el) => (this.valorTK = el)}>
							<div>:::::Sport Online::::</div>
							<div>Serial:{this.props.serial}</div>
							<div>
								Hora:{this.props.tthis.state.dellTk.hora} - Fecha:
								{this.props.tthis.state.dellTk.fecha}
							</div>
							<div>Electornico:{this.props.tthis.state.dellTk.se}</div>
							<div>Hipodromo:{this.props.tthis.state.dellTk.hipo}</div>
							{this.props.tthis.state.dellTk.tipo !== 0 ? (
								<div>
									Exotica:{" "}
									{this.state.exoticas[this.props.tthis.state.dellTk.tipo - 1]}
								</div>
							) : (
								""
							)}
							<div>Carrera/Tanda:{this.props.tthis.state.dellTk.carr}</div>
							<div>================================</div>
							{this.props.tthis.state.detalle.map((valor, index) => (
								<div key={`idm03-${index}`}>
									{this.props.tthis.state.dellTk.tipo !== 0 ? (
										<div>
											{valor.eje + ") " + valor.text + " "}
											{"x" + valor.g}
										</div>
									) : (
										<div>
											{valor.eje + ".-" + valor.text + " "}
											{_.isNull(valor.g) ? "" : "Ganadores:" + valor.g + " "}
										</div>
									)}
								</div>
							))}
							<div>================================</div>
							{this.props.tthis.state.dellTk.tipo !== 0 ? (
								<div>
									<div>
										Valor del Ticket:
										{new Intl.NumberFormat("es", {
											style: "currency",
											currency: this.props.currency,
										}).format(this.props.tthis.state.dellTk.ValorR)}
									</div>
									<div>
										Monto Total del Ticket:
										{new Intl.NumberFormat("es", {
											style: "currency",
											currency: this.props.currency,
										}).format(this.props.tthis.state.dellTk.apuesta)}
									</div>
								</div>
							) : (
								<div>
									Total:
									{new Intl.NumberFormat("es", {
										style: "currency",
										currency: this.props.currency,
									}).format(this.props.tthis.state.dellTk.apuesta)}
								</div>
							)}

							<div>--------------------------------</div>
							<div>x</div>
							<div>x</div>
						</div>
					)}
			</PrintTemplate>
		);
	}
}



class TicketNacionalesPolla extends Component {
	constructor(props) {
		super(props);
		this.valorTK = React.createRef();
	}
	render() {
		return (
			<PrintTemplate>
				{this.props.serial !== 0 &&
					!_.isUndefined(this.props.tthis.state.detalle) && (
						<div ref={(el) => (this.valorTK = el)} style={{ fontSize: "12px" }}>
							<div>:::::Sport Online::::</div>
							<div>Serial:{this.props.serial}</div>
							<div>
								Hora:{this.props.tthis.state.dellTk.hora}-Fecha:
								{this.props.tthis.state.dellTk.fecha}
							</div>
							<div>{this.props.tthis.state.dellTk.se}</div>
							<div>Hipodromo:{this.props.tthis.state.dellTk.hipo}</div>
							<div aling-1="true">Polla Hipica</div>
							<div>==============================</div>
							{this.props.tthis.state.detalle.map((valor, index) => (
								<div key={`idm03-${index}`}>
									{" "}
									{`${_.truncate(
										index + 1 + ")" + valor.eje + "-" + valor.text,
										{ length: 20 }
									)}`}{" "}
								</div>
							))}
							<div>==============================</div>
							<div>
								Valor del Ticket:
								{new Intl.NumberFormat("es", {
									style: "currency",
									currency: this.props.currency,
								}).format(this.props.tthis.state.dellTk.apuesta)}
							</div>
							<div aling-2="true">x</div>
							<div>x</div>
						</div>
					)}
			</PrintTemplate>
		);
	}
}
class TicketLoterias extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loteria: 0,
		};
		this.valorTK = React.createRef();
	}
	render() {
		return (
			<PrintTemplate>
				{this.props.serial !== 0 &&
					this.props.tthis.state.detalle.length !== 0 && (
						<div ref={(el) => (this.valorTK = el)}>
							<div>:::::Sport Online::::</div>
							<div>Serial:{this.props.serial}</div>
							<div>
								Hora:{this.props.tthis.state.dellTk.hora} - Fecha:
								{this.props.tthis.state.dellTk.fecha}
							</div>
							<div>Electornico:{this.props.tthis.state.dellTk.se}</div>
							<div>================================</div>
							{this.props.tthis.state.detalle.map((valor, index) => (
								<div key={`idm04-${index}`}>
									<div>
										{valor.loteria}-{valor.sorteo}
									</div>
									{valor.display.map((valor1, index) => (
										<div key={"2" + index}>{valor1.numeros}</div>
									))}
								</div>
							))}
							<div>================================</div>
							<div>
								Monto Ticket:
								{new Intl.NumberFormat("es", {
									style: "currency",
									currency: this.props.currency,
								}).format(this.props.tthis.state.dellTk.total)}
							</div>
							<div>--------------------------------</div>
							<div>x</div>
							<div>x</div>
						</div>
					)}
			</PrintTemplate>
		);
	}
}
class PrintTicket extends Component {
	constructor(props) {
		super(props);
		this.state = {
			xoffs: [],
			bodyTk: {},
			dellTk: [],
			detalle: [],
			op: 0,
			ticketMensaje:''
		};
		this.componentRef = React.createRef();
		this.queryviewOneTicketFn = func.queryviewOneTicketFn.bind(this);
	}
	TicketNacionalesPollaMessenger = ({serial,detalle,dellTk,currency})=> {
		return serial !== 0 
				&& !_.isUndefined(detalle) 
				&& (`*:::::Sport Online::::* \n *Serial:* ${serial}\n *Hora:* ${dellTk.hora}- *Fecha:* ${dellTk.fecha} \n${dellTk.se}\n *Hipodromo:* ${dellTk.hipo} \n *==Polla Hipica==* \n=========================\n${ detalle.map((valor, index) => 
						_.truncate( index + 1 + ")" + valor.eje + "-" + valor.text,{ length: 20 } ) ).join(`\n`)
				 }\n=========================\n *Valor del Ticket:* ${new Intl.NumberFormat("es", {
					style: "currency",
					currency: currency,
				}).format(dellTk.apuesta)}\n`
			)
		
	}
	callWhatsappBox=()=>{
			const {serial,currency}=this.props
			const {detalle,dellTk}=this.state
			const ticketMensaje=this.TicketNacionalesPollaMessenger({
				serial,
				currency,
				detalle,
				dellTk
			})
			this.props.activateWhatsapp(true,ticketMensaje);
			this.offprint_back();
	}

	setDatalistTk = (dellTk, detalle) => {
		if (_.isNull(detalle)) detalle = [];
		this.setState({ dellTk, detalle });
	};
	componentWillMount = () => {
		var { portImp, paramterImp, tipoprinter } = this.props.state;
		this.queryviewOneTicketFn(
			this.props.serial,
			this.props.tthis.state.opviewTK
		);
		document.addEventListener("keydown", this.escFunction2, false);
		if (tipoprinter === 2) socketPrint = new WebSocket(paramterImp + portImp);
	};
	escFunction2 = (event) => {
		var vsbprt = store.getState().vsbprt;
		// console.log('esc',vsbprt)
		if (vsbprt) {
			if (event.keyCode === 27) {
				if (!_.isUndefined(this.refs.buttomImp))
					this.refs.buttomImp.handlePrint();
			}
		}
	};
	componentDidUpdate = (prevProps) => {
		if (this.props.serial !== prevProps.serial) {
			if (this.props.serial !== 0) {
				this.queryviewOneTicketFn(
					this.props.serial,
					this.props.tthis.state.opviewTK
				);
				var { portImp, paramterImp, tipoprinter } = this.props.state;
				if (tipoprinter === 2)
					socketPrint = new WebSocket(paramterImp + portImp);
			}
		}
	};
	offprint_back = () => {
		this.props.tthis.offprint();
		store.dispatch({ type: "ACTV_PRN", vsbprt: false });
	};
	impScoketSend = () => {
		if (socketPrint.readyState === 3) {
			notification.error({
				message: `Error, en Servicio Printer Stay`,
				description:
					"Tenemos problemas para poder enviar el ticket a la Impresora, el servicio Printer Stay, no esta disponible, Inicielo o verifique su estatus!",
			});
		} else socketPrint.send(this.componentRef.valorTK.innerHTML);

		this.offprint_back();
	};

	render() {
		var { tipoprinter } = this.props.state;
		var { dellTk } = this.state;
		var op = this.props.tthis.state.opviewTK;
		var { subopview } = this.props.tthis.state;
		const project = () => {
			switch (op) {
				case 1:
					return (
						<Ticket
							ref={(el) => (this.componentRef = el)}
							serial={this.props.serial}
							currency={this.props.currency}
							tthis={this}
						/>
					);
				case 2:
					return (
						<TicketAmericana
							ref={(el) => (this.componentRef = el)}
							serial={this.props.serial}
							currency={this.props.currency}
							tthis={this}
						/>
					);
				case 3:
					if (subopview === 8){
					
						return (
							<TicketNacionalesPolla
								ref={(el) => (this.componentRef = el)}
								serial={this.props.serial}
								currency={this.props.currency}
								tthis={this}
							/>
						);
					}else
						return (
							<TicketNacionales
								ref={(el) => (this.componentRef = el)}
								serial={this.props.serial}
								currency={this.props.currency}
								tthis={this}
							/>
						);
				default:
					return (
						<TicketLoterias
							ref={(el) => (this.componentRef = el)}
							serial={this.props.serial}
							currency={this.props.currency}
							tthis={this}
						/>
					);
			}
		};
		return (
			<div>
				<Row gutter={30}>
					<Col span={9}>
						{tipoprinter === 2 ? (
							<Button
								onClick={() => this.impScoketSend()}
								loading={dellTk.length !== 0 ? false : true}
								disabled={dellTk.length !== 0 ? false : true}
								type="primary"
							>
								Imprimir (ESC)
							</Button>
						) : (
							<ReactToPrint
								trigger={() => (
									<Button
										loading={dellTk.length !== 0 ? false : true}
										disabled={dellTk.length !== 0 ? false : true}
										type="primary"
									>
										Imprimir (ESC)
									</Button>
								)}
								content={() => this.componentRef}
								onBeforePrint={() => this.offprint_back()}
								ref="buttomImp"
							/>
						)}
					</Col>
					<Col span={7}>
						<Button
							onClick={()=>this.callWhatsappBox()}
							className="ant-btn1 ant-btn-background-ghost1"
							loading={dellTk.length !== 0 ? false : true}
							disabled={dellTk.length !== 0 ? false : true}
						>
							WhatSapp
						</Button>
					</Col>
					<Col span={6}>
						<Button type="danger" onClick={() => this.offprint_back()}>
							No Imprimir
						</Button>
					</Col>
				</Row>
				{project()}
			</div>
		);
	}
}
export default PrintTicket;
